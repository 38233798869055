/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import $ from 'jquery';
import studentIcon from '../../../images/studenticon.svg';
import {
  addClassToTeacher, addClassToStudent, mapClassToRedux, getUserInfo, getClass, getRandomLesson, unselectClass,
} from '../../actions/index';
import blackx from '../../../images/black_x.svg';
import rocket from '../svg/rocket2.svg';
import green from '../svg/green.svg';
import yellow from '../svg/yellow.svg';
import blue from '../svg/blue.svg';
import headToHead from '../../../images/headtohead.svg';
import NewNavBar from '../cs98_components/NewNavBar';
import birdSinging from '../../../images/bird_singing.svg';
import SpecialTile from './SpecialTile';
// import settingsGear from '../../../images/settingsGear.png';
// import profilePic from '../../../images/profilePic.png';

class AllClasses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classCode: '',
      joinModal: false,
      addModal: false,
      codeModal: false,
      activeCodeForModal: '',
      newClassName: '',
      onGear: false,
      activeNameForModal: '',
      activeStudentCountForModal: 0,
    };
  }

  componentDidMount() {
    if (this.props.currentUser.username === null || this.props.currentUser.students === undefined) {
      this.props.getUserInfo();
    }
    this.props.unselectClass();
  }

  saveClassName = (e) => {
    this.setState({
      newClassName: e.target.value,
    });
  }

  createNewClass = (e) => {
    this.props.addClassToTeacher(this.props.currentUser.id, this.state.newClassName);
    this.closeAddModal();
    console.log(this.state.newClassName);
    this.setState({
      newClassName: '',
    });
    $('#add-modal-input').value = '';
    toast.success('Created your new class!', {
      position: 'bottom-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  grabJoinInput = (e) => {
    this.setState({
      classCode: e.target.value,
    });
  }

  openJoinModal = () => {
    this.setState({
      joinModal: true,
    });
  }

  closeJoinModal = () => {
    this.setState({
      joinModal: false,
    });
  }

  openAddModal = () => {
    this.setState({
      addModal: true,
    });
  }

  closeAddModal = () => {
    this.setState({
      addModal: false,
    });
  }

  openCodeModal = (c) => {
    this.setState({
      codeModal: true,
      activeCodeForModal: c.code,
      activeNameForModal: c.name,
      activeStudentCountForModal: c.students.length,
    });
  }

  closeCodeModal = () => {
    this.setState({
      codeModal: false,
      activeCodeForModal: '',
    });
  }

  renderYellowButton = () => {
    console.log(this.props.currentUser.type);

    // student render
    if (this.props.currentUser.type === 'student') {
      return (
        <div>
          <button type="submit" className="add-classes-button" onClick={() => this.openJoinModal()}>
            Join classes
          </button>
        </div>
      );

      // teacher render
    } else {
      return (
        <div>
          <button type="submit" className="add-classes-button" onClick={() => this.openAddModal()}>
            Add classes
          </button>
        </div>
      );
    }
  }

  goToClassPage = (id) => {
    if (!this.state.onGear) {
      console.log('user stuff classes are: ', this.props.currentUser.classes);
      console.log('id was: ', id);
      console.log('going to class page with class code: ', this.props.currentUser.classes[id].code);
      const pushTo = `/class/${this.props.currentUser.classes[id].code}`;
      this.props.getClass(this.props.currentUser.classes[Number(id)].code);
      this.props.history.push(pushTo);
    }
  }

  renderStar = () => {
    if (this.state.starClicked) {
      return (
        <i className="fas fa-star" onClick={() => { this.setState({ starClicked: false }); }} />
      );
    } else {
      return (
        <i className="far fa-star" onClick={() => { this.setState({ starClicked: true }); }} />
      );
    }
  }

  renderCommunityTile = () => {
    return (
      <div className="class-view" id="infinity-view" onClick={() => { this.props.history.push('/community'); }} role="link" tabIndex={0}>
        <div className="class-view-top-section">
          <div className="star-container">
            <i className="fas fa-star" id="star" />
          </div>

          <div to="/class" className="tile-view-top">Community Lessons</div>

        </div>
        <div className="ac-special-tile-div">
          {this.renderCommunityBirds()}
        </div>

        <div id="infinity-view-bottom" />
      </div>
    );
  }

  renderCommunityBirds = () => {
    return (
      <div className="birds-div">
        <img src={green} alt="green" className="green-bird" />
        <img src={yellow} alt="yellow" className="yellow-bird" />
        <img src={blue} alt="blue" className="blue-bird" />
      </div>
    );
  }

  renderStudentsInClass = (c) => {
    if (c.students === undefined) {
      // get class info
      return (
        <div>Loading...</div>
      );
    }
    if (c.students !== undefined && c.students.length < 1) {
      return (
        <div className="class-view-invite">Invite students to join your class!</div>
      );
    } else {
      console.log('c:', c);
      return (
        <div
          className="class-view-students-icon-flex"
        >{c.students.map((student) => {
          return (
            <img className="class-view-student-icon" src={studentIcon} alt="bird icon" key={student.id} />
          );
        })}
        </div>
      );
    }
  }

  renderPlusTile = () => {
    return (
      <div className="flex-center-tile" onClick={this.props.currentUser.type === 'student' ? this.openJoinModal : this.openAddModal}>
        <FontAwesomeIcon icon={faPlusCircle} className="plus-icon" />
      </div>
    );
  }

  renderICTileSpecial = () => {
    console.log(this.props.currentUser);
    return (
      <SpecialTile
        id="in-class-game-view"
        toPushTo="/InClassGame"
        title="In Class Activity"
        imageSrc={birdSinging}
        imageAlt="Bird singing"
        renderBottom={false}
        renderStar
        renderImage
        imageId="allclasses-in-class"
        history={this.props.history}
      />
    );
  }

  renderCommunityTileSpecial = () => {
    return (
      <SpecialTile
        id="community-view"
        toPushTo="/community"
        title="Community Lessons"
        renderStar
        renderBottom={false}
        renderImage={false}
        renderImageCallback={this.renderCommunityBirds}
        imageId="allclasses-in-class"
        history={this.props.history}
      />
    );
  }

  renderInfinityGameTileSpecial = () => {
    return (
      <SpecialTile
        id="infinity-view"
        toPushTo="/lessons/random"
        title="Infinity Game"
        imageSrc={rocket}
        imageAlt="rocket"
        renderBottom={false}
        renderStar
        renderImage
        imageId="allclasses-rocket"
        history={this.props.history}
      />
    );
  }

  /* renderHTHTitle = () => {
    return (
      <NavLink to="/HeadToHeadGame">
        <div id="hth-view" className="class-view">
          <div className="class-view-top-section">
            <div className="star-container">
              <i className="fas fa-star" id="star" />
            </div>
          </div>
          <div id="infinity-view-top">Head to Head</div>
          <div className="head-div">
            <img src={headToHead} alt="headtohead" id="allclasses-headtohead" />
          </div>
        </div>
      </NavLink>
    );
  } */

  renderHeadToHeadGameTileSpecial = () => {
    return (
      <SpecialTile
        id="head-to-head-tile"
        toPushTo="/HeadToHeadGame"
        title="Head To Head Game"
        imageSrc={headToHead}
        imageAlt="Head To Head Game Tile"
        renderBottom={false}
        renderStar
        renderImage
        imageId="allclasses-in-class"
        history={this.props.history}
      />
    );
  }

  render() {
    // let classes = <div>Getting class information</div>;
    // if classes have loaded in, render all classes
    // if (this.props.currentUser.classes !== undefined) {
    // let count = -1;
    // classes = this.props.currentUser.classes.map((c) => {
    //   count += 1;
    //   console.log(c, 'classobj');

    //   // individual class view
    //   return (
    //     <div className="class-view" key={c.code} id={count} onClick={(event) => { this.goToClassPage(this.props.currentUser.classes.indexOf(c)); console.log(event); }} role="link" tabIndex={0}>
    //       <div className="class-view-top-section">
    //         <div className="gear-container">
    //           <div id="gear-icon" className="tooltip-new" data-tooltip="Classroom Settings">
    //             <button type="button"
    //               className="gear-button"
    //               onClick={() => this.openCodeModal(c)}
    //               onMouseEnter={() => { this.setState({ onGear: true }); }}
    //               onMouseLeave={() => { this.setState({ onGear: false }); }}
    //             >
    //               <FontAwesomeIcon icon={faCog} className="cog" />
    //             </button>
    //           </div>
    //         </div>

    //         <div to="/class" id="class-view-top">{c.name}</div>

    //       </div>

    //       {/* student rendering in classes */}
    //       <div className="class-view-students-flex">
    //         {this.renderStudentsInClass(c)}
    //       </div>

    //       {c.students !== undefined && <div id="class-view-bottom">{c.students.length} students</div>}
    //     </div>
    //   );
    // });
    // }

    // main return for page
    return (
      <div className="all-classes">
        <NewNavBar
          renderLogo
          items={[
            {
              text: 'Profile',
              link: '/profile',
              icon: 'fas fa-user',
            },
            {
              text: 'Sign Out',
              link: '/home',
              icon: 'fas fa-sign-out-alt',
            },
          ]}
        />
        <div className="welcome-message-container">
          Welcome back, { this.props.currentUser.first_name }
        </div>
        <div className="all-classes-bottom-container">
          <div className="all-classes-bottom">
            {this.renderInfinityGameTileSpecial()}
            {this.renderICTileSpecial()}
            {this.renderHeadToHeadGameTileSpecial()}
          </div>
        </div>
        <div>
          <Modal
            isOpen={this.state.joinModal}
            onRequestClose={this.closeJoinModal}
            className="Modal modal join-class"
            overlay="overlay"
            contentLabel="join-class"
            ariaHideApp={false}
          >
            <div className="add-modal-rows">
              <div className="code-modal-x">
                <img alt="x-out" className="code-modal-render-x" src={blackx} onClick={this.closeJoinModal} />
              </div>
              <div className="add-modal-title">Enter the classroom code:</div>
              <div className="add-modal-input-flex">
                <input className="add-modal-input" id="add-modal-input" onChange={this.grabJoinInput} />
                <button
                  className="join-modal-join-button"
                  type="button"
                  onClick={() => {
                    toast.success('Class joined!', {
                      position: 'bottom-right',
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    this.props.addClassToStudent(this.state.classCode, this.props.currentUser.id);
                    this.closeJoinModal();
                  }}
                >Join
                </button>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={this.state.addModal}
            onRequestClose={this.closeAddModal}
            className="Modal modal add-class"
            overlay="overlay"
            contentLabel="add-class"
            ariaHideApp={false}
          >
            <div className="add-modal-rows">
              <div className="code-modal-x">
                <img alt="x-out" className="code-modal-render-x" src={blackx} onClick={this.closeAddModal} />
              </div>
              <div className="add-modal-title">Enter your classroom name:</div>
              <div className="add-modal-input-flex">
                <input className="add-modal-input" id="add-modal-input" onChange={this.saveClassName} />
                <button
                  className="add-modal-create-button"
                  type="button"
                  onClick={() => {
                    this.createNewClass();
                  }}
                >Create
                </button>
              </div>
            </div>
          </Modal>
        </div>
        <div className="code-modal-flex">
          <Modal
            isOpen={this.state.codeModal}
            onRequestClose={this.closeCodeModal}
            className="Modal smallmodal code-modal"
            overlay="overlay"
            contentLabel="view-code"
            ariaHideApp={false}
          >
            <div className="code-modal-rows">
              <div className="code-modal-x">
                <img alt="x-out" className="code-modal-render-x" src={blackx} onClick={this.closeCodeModal} />
              </div>
              <div className="modal-flexbox-code">
                <div className="code-modal-title">Classroom:</div>
                <div className="code-modal-code">{this.state.activeNameForModal}</div>
              </div>
              <div className="modal-flexbox-code">
                <div className="code-modal-title">Classroom code:</div>
                <div className="code-modal-code">{this.state.activeCodeForModal}</div>
              </div>
              <div className="modal-flexbox-code">
                <div className="code-modal-title">Classroom teacher:</div>
                <div className="code-modal-code">{this.state.activeCodeForModal}</div>
              </div>
              <div className="modal-flexbox-code">
                <div className="code-modal-title">Students in class:</div>
                <div className="code-modal-code">{this.state.activeStudentCountForModal}</div>
              </div>

            </div>
          </Modal>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user,
    authentication: state.auth.authentication,
  };
}

export default withRouter(connect(mapStateToProps, {
  addClassToTeacher, addClassToStudent, mapClassToRedux, getUserInfo, getClass, getRandomLesson, unselectClass,
})(AllClasses));
