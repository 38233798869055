/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import Lottie from 'react-lottie';
import itemsList from './constants';
import Bird from './Bird';
import ColorSwatches from './ColorSwatches';
import StoreItem from './StoreItem';
import WarblerCoin from '../../../../images/profile/woin.svg';
import confetti from '../../../../animations/confetti.json';
import egg from '../../../../animations/egg.json';
import { changeIconAppearance, buyItemAction } from '../../../actions';

const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const confettiStyle = {
  width: '100vw',
  height: '100vh',
  position: 'absolute',
};

const eggStyle = {
  width: '750px',
  height: 'auto',
  position: 'absolute',
  left: '-90px',
  top: '-240px',
  zIndex: 10,
};

const Store = ({ currentUser }) => {
  const textOptions = ['Top', 'Accessory', 'Platform'];
  const { id } = currentUser;
  const { coins } = currentUser.stats;
  console.log(id);
  console.log(currentUser);
  const items = itemsList;

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(textOptions[0]);
  const [playConfetti, setPlayConfetti] = useState(false);
  const [playChangeColor, setPlayChangeColor] = useState(false);
  const wearItem = (item, itemType) => {
    if (id === undefined || id === null) {
      toast.error('Please log in first!', {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    let newProf = {};
    console.log('wearItem: ', item, itemType);
    if (itemType === 'Top') {
      newProf = {
        color: currentUser.profile.color,
        top: item.name,
        accessory: currentUser.profile.accessory,
        platform: currentUser.profile.platform,
        fullBody: currentUser.profile.fullBody,
      };
    } else if (itemType === 'Accessory') {
      newProf = {
        color: currentUser.profile.color,
        top: currentUser.profile.top,
        accessory: item.name,
        platform: currentUser.profile.platform,
        fullBody: currentUser.profile.fullBody,
      };
    } else if (itemType === 'Platform') {
      newProf = {
        color: currentUser.profile.color,
        top: currentUser.profile.top,
        accessory: currentUser.profile.accessory,
        platform: item.name,
        fullBody: currentUser.profile.fullBody,
      };
    } else if (itemType === 'FullBody') {
      newProf = {
        color: currentUser.profile.color,
        top: currentUser.profile.top,
        accessory: currentUser.profile.accessory,
        platform: currentUser.profile.platform,
        fullBody: item.name,
      };
    }
    dispatch(changeIconAppearance(id, newProf));
  };

  const playSuccessToast = (item) => {
    toast.success(`${item.name} bought successfully!`, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const buyItem = (item) => {
    if (item.price <= coins) {
      setPlayConfetti(true);
      setTimeout(() => {
        dispatch(buyItemAction(id, item, playSuccessToast));
        setPlayConfetti(false);
      }, 1500);
    } else {
      toast.error('You don\'t have enough coins for that item!', {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const changeColor = (color) => {
    if (id === undefined || id == null) {
      toast.error('Please log in first!', {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setPlayChangeColor(true);
    setTimeout(() => {
      dispatch(changeIconAppearance(id,
        {
          color,
          accessory: currentUser?.profile?.accessory,
          top: currentUser?.profile?.top,
          platform: currentUser.profile.platform,
          fullBody: currentUser.profile.fullBody,
        }));
      setPlayChangeColor(false);
    }, 1500);
  };
  return (
    <div id="store-main">
      { playConfetti ? (
        <Lottie
          isStopped={playConfetti}
          className="confetti-anim"
          style={confettiStyle}
          options={{ ...defaultOptions, animationData: confetti }}
        />
      ) : null }
      <div id="store-upper">
        <div id="store-upper-upper">
          <div id="store-coins-background">
            <img id="coin-image" alt="coin" src={WarblerCoin} />
            <p id="store-coin-amount">{coins}</p>
          </div>
        </div>
        <div id="store-upper-middle">
          { playChangeColor ? (
            <Lottie
              isStopped={playChangeColor}
              className="change-color-anim"
              style={eggStyle}
              options={{ ...defaultOptions, animationData: egg }}
            />
          ) : null }
          <Bird color={currentUser?.profile?.color}
            accessory={currentUser?.profile?.accessory}
            fullBody={currentUser?.profile?.fullBody}
            top={currentUser?.profile?.top}
            platform={currentUser?.profile?.platform}
          />
        </div>
        <div id="store-upper-lower">
          <ColorSwatches changeColor={changeColor} currentColor={currentUser?.profile?.color} />
        </div>
      </div>

      <div id="store-lower">
        <div id="store-lower-upper">
          {textOptions.map((text) => {
            return (
              <div className="store-text-outer" onClick={() => { setCurrentPage(text); }}>
                <p id={`store-${text}`}
                  className={`store-text ${currentPage === text ? 'active-store-page' : ''}`}
                >{text}
                </p>
              </div>
            );
          })}
        </div>
        <div id="store-middle">
          {items[currentPage].map((item) => {
            console.log(items);
            console.log(items[currentPage]);
            return (
              <StoreItem itemType={currentPage}
                wearItem={wearItem}
                buyItem={buyItem}
                item={item}
                key={item.name}
                locked={!currentUser.items[currentPage].includes(item.name)}
              />
            );
          })}
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
export default Store;
