import React, { Component } from 'react';

class RhythmNoteTypeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thing: this.props.dur,
    };
    this.workplz = this.workplz.bind(this);
  }

  workplz = (e) => {
    this.setState({ thing: e.target.value });
  }

  render() {
    const id = `note-pick-id-${this.props.num}`;
    console.log(this.state);

    return (
      <div className="answer-input-box-container note-pick-container">
        <div className="note-title">note {this.props.num + 1}: </div>
        <input name="hey fix it" type="text" className="note-pick-input sr-note-input-box" id={id} value={this.state.thing} onChange={this.workplz} onBlur={this.props.handleChange} readOnly={false} />
      </div>
    );
  }
}

export default RhythmNoteTypeInput;
