import React from 'react';
import BarGraph from './BarGraph';
import NewNavBar from '../../../cs98_components/NewNavBar';
// import smallBadge from './smallBadge.svg';

const TeacherResults = ({
  answerRatios, numberOfQuestions, currentQuestionNumber, goToNextQuestionCallback, endGameCallback, correctAnswer, title, firstPlace, firstPlacePoints,
}) => {
  console.log(numberOfQuestions, 'numberOfQuestions');
  console.log(currentQuestionNumber, 'currentQuesitonNumber');
  return (
    <div className="teacher-results-ic">
      <NewNavBar title="In-class"
        items={[
          {
            text: '',
            link: '/home',
          },
          {
            text: '',
            link: '/home',
          },

        ]}
      />
      <div className="teacher-results-ic-inner">
        <div className="teacher-results-title">{title}</div>
        <div className="bar-graphs">
          <BarGraph
            count={answerRatios[0]}
            type="correct"
            bigger={answerRatios[0] >= answerRatios[1]}
            correctAnswer={correctAnswer}
          />
          <BarGraph
            count={answerRatios[1]}
            type="incorrect"
            bigger={answerRatios[1] >= answerRatios[0]}
            correctAnswer={correctAnswer}
          />
        </div>
        <div className="results-lead-tracker">
          {/* <img className="ribbon-class" alt="ribbon" src={smallBadge} /> */}
          <div className="results-lead-text">{firstPlace} is in lead right now with {firstPlacePoints} points!</div>
        </div>
        <div className="ic-button-flex-results">
          {numberOfQuestions <= currentQuestionNumber
            ? <button className="green-button" type="button" onClick={() => { endGameCallback(); }}>End Questions</button>
            : <button className="green-button" type="button" onClick={() => { goToNextQuestionCallback(); }}>Next Question!</button>}
        </div>
      </div>
    </div>
  );
};

export default TeacherResults;
