/*
    Fuction to calculate xp gained
*/
export function calculateXP(mode, attempts, completedLessons, currentLesson) {
  if (completedLessons.includes(currentLesson)) {
    return 0;
  }
  console.log('calculating xp with mode', mode, 'attempts', attempts);
  let xp = 0;
  if (mode === 'activity') { // assign for completed activity
    /*
    param = attempts (Integer)
    */
    if (attempts === 1) {
      xp = 75;
    } else {
      xp = 10;
    }
  } else if (mode === 'completed-lesson') {
    /*
    param = null
    */
    xp = 250;
  } else if (mode === 'random_activity') {
    /*
    param = depth (Integer)
    */
    xp = Math.ceil(20 * (1.5 ** attempts));
  }
  return xp;
}

export function calculateAvailableXP(lesson) {
  let baseline = 250;

  lesson.pages.forEach((page) => {
    if (page.page_type !== 'content') {
      baseline += 75;
    }
  });

  return baseline;
}
