/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserInfo, signOutUser } from '../../../actions/index';
import fire from '../../../../images/profile/profStreak.svg';
import badge from '../../../../images/profile/profBadge.svg';
import infinity from '../../../../images/profile/rocketProf.svg';
import Store from './Store';
import NewNavBar from '../../cs98_components/NewNavBar';
import { chooseLevelIcon, chooseLevelName } from './Utility';

class NewProfilePage extends Component {
  constructor(props) {
    super(props);
    this.props.getUserInfo();
  }

  signout = () => {
    this.props.signOutUser(this.props.history);
  }

  render() {
    console.log(Math.min(Math.floor(this.props.currentUser.stats.xp / 20)), 1);
    const level = Math.floor(this.props.currentUser.stats.xp);
    console.log(Math.max(level, 1));
    console.log(this.props.currentUser);
    const itemCount = this.props.currentUser.items.Top.length + this.props.currentUser.items.Accessory.length + this.props.currentUser.items.Platform.length;
    return (
      <div className="profile-page-main">
        <NewNavBar
          renderLogo
          back_button
          items={[
            {
              text: 'Sign Out',
              link: '/home',
              icon: 'fas fa-sign-out-alt',
            },
          ]}
        />
        <div className="profile-page-container">
          {console.log(this.props.currentUser)}
          <Store coins={this.props.currentUser.stats.coins}
            currentUser={this.props.currentUser}
          />
          <div className="profile-right">
            <div className="profile-right-top">
              <div className="profile-right-top-right">
                <div className="profile-name">Hello {this.props.currentUser.username}!</div>
                <div className="profile-xp">{this.props.currentUser.stats.xp} XP</div>
              </div>
              <div className="profile-levels">
                <img className="level-icon" src={chooseLevelIcon(Math.max(level, 1))} alt="level icon" />
                <div className="profile-level">{chooseLevelName(Math.max(level, 1))}</div>
                <div className="profile-levelnum">Level {Math.max(level, 1)}</div>
              </div>
            </div>
            <div className="profile-right-bottom-section">
              <div className="profile-stats">Your Statistics</div>
              <div className="profile-right-bottom">
                <div className="profile-stats-streak">
                  <img className="stats-image" src={fire} alt="fire" />
                  <div className="profile-stats-text">
                    <div className="profile-stats-title">{this.props.currentUser.streak?.length ? this.props.currentUser.streak.length : 1} Day</div>
                    <div className="profile-stats-subtitle">Keep up your daily streak!</div>
                  </div>
                </div>
                <div className="profile-stats-badges">
                  <img className="stats-image" src={badge} alt="badge" />
                  <div className="profile-stats-text">
                    <div className="profile-stats-title">{this.props.currentUser.badges?.length ? this.props.currentUser.badges.length : 0} Badges</div>
                    <div className="profile-stats-subtitle">Play more to earn more badges!</div>
                  </div>
                </div>
                <div className="profile-stats-infinity">
                  <img className="stats-image-2" src={infinity} alt="infinity" />
                  <div className="profile-stats-text">
                    <div className="profile-stats-title">{itemCount || 0} Item{itemCount > 1 ? 's' : ''}</div>
                    <div className="profile-stats-subtitle">The number of items you&apos;ve collected!</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

function mapStateToProps(reduxState) {
  return {
    currentUser: reduxState.user,
    authentication: reduxState.auth.authentication,
  };
}

export default connect(mapStateToProps, { getUserInfo, signOutUser })(NewProfilePage);
