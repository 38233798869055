import React/* , { useState, useEffect } */ from 'react';
// import Lottie from 'react-lottie';
import yellow from '../assets/yellowResults.svg';
import sadYellow from '../assets/sad_yellow.svg';
import suffixOrdinal from '../utility';
// import flames from '../../../../../animations/flames.json';

/* const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const flameStyle = {
  width: '50vw',
  height: 'auto',
  position: 'absolute',
}; */

const StudentResults = ({
  isCorrect, score, type, streak, added, place, behindName, behindPoints, name,
}) => {
  // const [playStreak, setPlayStreak] = useState(false);

  /* useEffect(() => {
    if (isCorrect) {
      setPlayStreak(true);
      setTimeout(() => {
        setPlayStreak(false);
      }, 1500);
    }
  }, [isCorrect]); */

  // in class game screens
  return (
    <div className={`result-background ${isCorrect ? 'green-background' : 'red-background'}`}>

      <div className="result-inner-holder">
        <h1 className="result-screen-title">{isCorrect ? 'Correct!' : 'Incorrect.'}</h1>
        {isCorrect
          ? <img src={yellow} id="orange-bird-results" className="orange-bird" alt="orange bird" />
          : <img className="orange-bird" alt="orange bird with a tear" src={sadYellow} />}
        {isCorrect
          ? <div id="answer-streak-ic-space" className="answer-streak-ic">answer streak: <span className="bold"> {streak}</span></div>
          : <div className="answer-streak-ic">answer streak lost :( </div>}
        {/* playStreak ? (
          <Lottie
            isStopped={playStreak}
            className="flames-anim"
            style={flameStyle}
            options={{ ...defaultOptions, animationData: flames }}
          />
        ) : null */}
        {isCorrect
          ? <div className="added-points-box">+ {added} POINTS </div>
          : null}
        {/* <div className="total-score">total score: {score} points</div> */}
        <div className="current-place">{behindPoints === -1 ? `${name}, you're tied for ${suffixOrdinal(place)} place` : `${name}, you're in ${suffixOrdinal(place)} place`}</div>
        {place === 1 && behindPoints !== -1 ? null : <div className="points-behind">{behindPoints === -1 ? `tied with ${behindName}` : `${behindPoints} points behind ${behindName}`}</div>}
      </div>
    </div>
  );
};

export default StudentResults;
