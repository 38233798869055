/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import ExerciseNav from './Exercises/ExerciseNav';
import NextButton from './Exercises/NextButton';

class ViewContent extends Component {
  render() {
    if (this.props.page.info !== null) {
      return (
        <div className="content-exercise">
          <ExerciseNav
            lives={this.props.lives}
            percentage={this.props.percentage}
            xp={this.props.xp}
            page={this.props.page}
            infinity={this.props.infinity}
            level={this.props.level}
            changePage={this.props.changePage}
            pageCount={this.props.pageCount}
            currentPage={this.props.currentPage}
            type={this.props.type}
          />
          <div className="content-main-body">
            <div className="content-title">{this.props.page.info.title}</div>
            <div className="content-description">{this.props.page.info.description} </div>
            <NextButton goToNext={this.props.goToNext} />
          </div>

        </div>
      );
    } else {
      return (
        <div />
      );
    }
  }
}

export default ViewContent;
