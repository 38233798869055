/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import rocket from '../svg/rocket.svg';
import BlueClefSelect from './lesson_creation_components/question_creation/BlueClefSelect';
import NewNavBar from '../cs98_components/NewNavBar';

class InfinityIntro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderVoicePart: true,
      clef: 'treble',
      error: false,
    };
  }

  updateClef = (clef) => {
    this.setState({ clef });
  }

  renderVoicePart = () => {
    if (this.state.renderVoicePart) {
      return (
        <div>
          <div>What clef would you like to practice?</div>
          <BlueClefSelect clef={this.state.clef} func={this.updateClef} />
        </div>
      );
    } else {
      return (
        <div />
      );
    }
  }

  begin = () => {
    // ['rhythm', 'sightreading', 'listening', 'intervals', 'singing'];
    const validTypes = [];

    if (document.getElementById('rhythm-box').checked) {
      validTypes.push('rhythm');
    }

    if (document.getElementById('intervals-box').checked) {
      validTypes.push('intervals');
    }

    if (document.getElementById('sight-box').checked) {
      validTypes.push('sightreading');
    }
    /* if (document.getElementById('pitch-box').checked) {
      validTypes.push('singing');
    } */
    if (document.getElementById('listening-box').checked) {
      validTypes.push('listening');
    }

    console.log('validTypes', validTypes);
    if (validTypes.length > 0) {
      this.props.begin(validTypes, this.state.clef);
    } else {
      this.setState({ error: true });
    }
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <div style={{ color: 'red' }}>You must select one or more activity types</div>
      );
    } else {
      return (
        <div />
      );
    }
  }

  render() {
    console.log('inf props', this.props);
    return (
      <div className="infinity">
        <NewNavBar items={[
          {
            text: 'Sign Out',
            link: '/home',
            icon: 'fas fa-sign-out-alt',
          },
        ]}
          back_button
          title="Infinity Game"
        />
        <div className="infinity-body">
          <div className="infinity-title infinity-title-top">Are you ready for a challenge?</div>
          <div className="infinity-rules-flex">
            <div className="infinity-rules">
              Test your music skills through the <span className="boldface"> infinity game!</span>
            </div>
            <div className="infinity-rules">
              See how many activities you can complete without losing all 3 of your lives — the farther you get, the more XP you get for each question correct.
            </div>
          </div>
          <div className="infinity-details">
            <div className="infinity-what-skills">What skills would you like to practice?</div>
            <div className="infinity-activity-types">
              <div className="infinity-activity-type">
                <label className="container-checkbox">
                  <input type="checkbox" value="Rhythm" defaultChecked id="rhythm-box" />
                  <span className="checkmark-checkbox" />
                </label>
                <div className="infinity-activity-type-word">
                  Rhythm
                </div>
              </div>
              <div className="infinity-activity-type">
                <label className="container-checkbox">
                  <input type="checkbox" value="Sightreading" defaultChecked id="sight-box" />
                  <span className="checkmark-checkbox" />
                </label>
                <div className="infinity-activity-type-word">
                  Sightreading
                </div>
              </div>
              <div className="infinity-activity-type">
                <label className="container-checkbox">
                  <input type="checkbox" value="Intervals" defaultChecked id="intervals-box" />
                  <span className="checkmark-checkbox" />
                </label>
                <div className="infinity-activity-type-word">
                  Intervals
                </div>
              </div>
              {/* <div className="infinity-activity-type">
                <label className="container-checkbox">
                  <input type="checkbox" value="Pitchmatching" defaultChecked id="pitch-box" />
                  <span className="checkmark-checkbox" />
                </label>
                <div className="infinity-activity-type-word">
                  Pitchmatching
                </div>
              </div> */}
              <div className="infinity-activity-type">
                <label className="container-checkbox">
                  <input type="checkbox" value="Intervals" defaultChecked id="listening-box" />
                  <span className="checkmark-checkbox" />
                </label>
                <div className="infinity-activity-type-word">
                  Listening (Multiple Choice)
                </div>
              </div>
            </div>
            <div className="inf-clef-selection-flex">
              <div className="inf-clef-text">What clef would you like to practice?</div>
              <BlueClefSelect clef={this.state.clef} func={this.updateClef} />
            </div>
          </div>
          <div className="inf-play-holder" onClick={this.begin}>
            <div className="inf-play green">
              Play
            </div>
          </div>
          {this.renderError()}
        </div>
        <img className="rocket" src={rocket} alt="rocket" />
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  if (window.location.pathname.split('/')[1] === 'createlesson') {
    console.log('in lesson making');
    return {
      lesson: reduxState.lessonMaking,
    };
  } else {
    return {
      user: reduxState.user,
      lesson: reduxState.lesson.currentLesson,
      lessonMaking: reduxState.lessonMaking,
    };
  }
}

export default withRouter(connect(mapStateToProps, {})(InfinityIntro));
