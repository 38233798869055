import React, { Component } from 'react';
import Select from 'react-select';
import WhiteDropdownIndicator from './whiteDropdownIndicator';

const DropdownIndicator = WhiteDropdownIndicator;

const customStyles = {

  control: (provided, state) => ({
    ...provided,
    width: '351px',
    height: '65px',
    background: '#1CB0F6',
    borderRadius: '19px 19px 19px 19px',
    zIndex: '2',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#FFFFFF',
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '27px',
    lineHeight: '27px',
    letterSpacing: '0.03em',
    textAlign: 'center',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '27px',
    lineHeight: '27px',
    letterSpacing: '0.03em',
    textAlign: 'center',
    color: '#FFFFFF',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '351px',
    background: '#77D0FA',
    borderRadius: '0px 0px 19px 19px',
    marginTop: '0px',
    top: '48px',
    zIndex: '1',
  }),
  menuList: (provided, state) => ({
    ...provided,
    width: '351px',
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    background: '#77D0FA',
    fontSize: '27px',
    lineHeight: '27px',
    letterSpacing: '0.03em',
    textAlign: 'center',
    color: '#FFFFFF',
    paddingTop: '0px',
    paddingBottom: '0px',
    boxSizing: 'border-box',
    top: '0',
    borderRadius: '0px 0px 19px 19px',
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
  indicatorValue: (provided, state) => ({
    ...provided,
    color: 'white',
  }),
  option: (provided, state) => ({
    ...provided,
    height: '75px',
    paddingTop: '35px',
    borderBottom: '3px solid #1CB0F6',
    borderLeft: '3px solid #1CB0F6',
    borderRight: '3px solid #1CB0F6',
    boxSizing: 'border-box',
    background: '#77D0FA',
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '27px',
    lineHeight: '27px',
    letterSpacing: '0.03em',
    textAlign: 'center',
    color: '#FFFFFF',
    borderRadius: '0px 0px 19px 19px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: 'visible',
    zIndex: '2',
    justifyContent: 'center',
    marginLeft: '35px',
  }),
};

class CommunitySelect extends Component {
    setSelected = (selectedOption) => {
      this.props.func(this.props.classes[selectedOption.value], selectedOption.label);
    }

    makeOptions = () => {
      const toReturn = [];
      for (let i = 0; i < this.props.classes.length; i += 1) {
        toReturn.push({ value: i, label: this.props.classes[i].name });
      }
      return toReturn;
    }

    render() {
      const options = this.makeOptions();
      console.log('options', options[0]);

      return (
        <div className="community-select">
          <Select
            className="react-select-container"
            key={false}
            components={{ DropdownIndicator }}
            styles={customStyles}
            options={options}
            value={options.filter((option) => option.label === this.props.community)}
            onChange={this.setSelected}
            isSearchable={false}
            overflow="scroll"
          />
        </div>
      );
    }
}
export default CommunitySelect;
