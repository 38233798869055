import React, { Component } from 'react';
import Select from 'react-select';
import DropdownIndicator from './dropdownIndicator';

const BPMoptions = ['90 (Slow)', '120 (Medium)', '150 (Fast)'];

const customStyles = {

  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#DEDCDC',
    width: '227px',
    height: '37px',

  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '100%',
  }),

  singleValue: (provided, state) => ({
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '21px',
    lineHeight: '21px',
    letterSpacing: '0.03em',
    color: '#757575',
  }),
  menuList: (provided, state) => ({
    ...provided,
    height: '110px',
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '21px',
    lineHeight: '21px',
    letterSpacing: '0.03em',
    color: '#757575',
    paddingTop: '0px',
    paddingBottom: '0px',
    boxSizing: 'border-box',
  }),
  option: (provided, state) => ({
    ...provided,
    borderTop: '1px solid #DEDCDC',
    borderBottom: '1px solid #DEDCDC',
    boxSizing: 'border-box',
    backgroundColor: '#F3F3F3',
    color: '#757575',
    height: '37px',
  }),
};

class BPMSelect extends Component {
    setSelected = (selectedOption) => {
      // eslint-disable-next-line react/no-unused-state
      this.props.func(selectedOption.label);
      this.props.handleChange();
    }

    makeOptions = () => {
      const toReturn = [];
      for (let i = 0; i < BPMoptions.length; i += 1) {
        toReturn.push({ value: i, label: BPMoptions[i] });
      }
      return toReturn;
    }

    render() {
      const options = this.makeOptions();
      return (
        <div className="bpm-select">
          <Select
            className="react-select-container-bpm"
            classNamePrefix="react-select"
            key={false}
            styles={customStyles}
            options={this.makeOptions()}
            components={{ DropdownIndicator }}
            value={options.filter((option) => option.label === this.props.bpm)}
            onChange={this.setSelected}
            isSearchable={false}
          />
        </div>
      );
    }
}

export default BPMSelect;
