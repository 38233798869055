// const HOST = 'ws://localhost:3000/';
const HOST = 'wss://warbler-head-2-head.herokuapp.com/';

const H2HSocket = (ws) => {
  if (ws && ws.readyState === 1) {
    console.log('web socket was already in working state, returning it.');
    return ws;
  } else {
    console.log('making new socket.');
    return new WebSocket(HOST);
  }
};

export default H2HSocket;
