import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { signOutUser } from '../../actions';
import NavBarDropdown from './NavBarDropdown';

class NewNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // nav_bar_items: this.props.items ? this.props.items : {},
      back_button: this.props.back_button || false,
      title: this.props.title || null,
      isDropdown: !!this.props.dropdownItems,
      renderLogo: this.props.renderLogo,
    };
  }

  renderBackButton(towhere) {
    if (this.state.back_button === true) {
      return (
        <NavLink className="nav-back-button nav-bar-item" to={towhere}>
          <i className="fas fa-arrow-circle-left" />
          Back
        </NavLink>
      );
    } else {
      return null;
    }
  }

  renderTitle() {
    if (this.state.title !== null) {
      return (
        <div className="nav-bar-title">
          {this.state.title}
        </div>
      );
    } else {
      return null;
    }
  }

  renderDropdown() {
    if (this.state.isDropdown) {
      return (
        <NavBarDropdown dropdownItems={this.props.dropdownItems} dropdownIcon={this.props.dropdownIcon || undefined} />
      );
    } else {
      return null;
    }
  }

  renderLogo() {
    if (this.state.renderLogo) {
      const homePage = (this.props.authed === true) ? '/userhome' : '/home';
      return (
        <NavLink className="new-nav-bar-logo" to={homePage}>
          Warbler
        </NavLink>
      );
    } else {
      return null;
    }
  }

  render() {
    const items = this.props.items ? this.props.items : {};
    const itemsToRender = items.map((item) => {
      if (item.text === 'My Profile' || item.text === 'Sign Out') {
        return (
          <NavLink
            className={item.hover || item.hover === undefined ? 'nav-bar-item' : 'nav-bar-item-no-hover'}
            key={item.text}
            onClick={this.props.signOutUser}
            to={item.link}
          >
            <i className={item.icon} />
            <div className={`nav-bar-item-text ${item.className ? item.className : ''}`}>
              {item.text}
            </div>
          </NavLink>
        );
      } else if (item.text === 'Join Class' || item.text === 'Create Class' || item.text === 'Save' || item.text === 'Publish') {
        return (
          <div className={item.hover || item.hover === undefined ? 'nav-bar-item' : 'nav-bar-item-no-hover'} key={item.text} onClick={item.func} role="button" tabIndex={0}>
            <i className={item.icon} />
            <div className={`nav-bar-item-text ${item.className ? item.className : ''}`}>
              {item.text}
            </div>
          </div>
        );
      } else if (item.stacked) {
        return (
          <div className={`${item.hover || item.hover === undefined ? 'nav-bar-item' : 'nav-bar-item-no-hover'} flex-col-center`} key={item.text} onClick={item.func} role="button" tabIndex={0}>
            <div className={`nav-bar-item-text ${item.className?.className1 ? item.className?.className1 : ''}`}>
              {item.text.text1}
            </div>
            <div className={`nav-bar-item-text ${item.className?.className2 ? item.className?.className2 : ''}`}>
              {item.text.text2}
            </div>
          </div>
        );
      } else if (item.icon !== undefined && item.iconToRight === true) {
        return (
          <NavLink
            className={item.hover || item.hover === undefined ? 'nav-bar-item-row' : 'nav-bar-item-row-no-hover'}
            to={item.link}
            key={item.text}
          >
            <div className={`nav-bar-item-text ${item.className ? item.className : ''}`}>
              {item.text}
            </div>

            {item.iconType === 'img'
              ? <img src={item.icon} alt={item.alt} className="nav-bar-item-woin" />
              : <i className={item.icon} />}
          </NavLink>
        );
      } else if (item.icon !== undefined && item.iconToRight === undefined) {
        return (
          <NavLink
            className={item.hover || item.hover === undefined ? 'nav-bar-item' : 'nav-bar-item-no-hover'}
            to={item.link}
            key={item.text}
          >
            {item.iconType === 'img'
              ? <img src={item.icon} alt={item.alt} />
              : <i className={item.icon} />}

            <div className={`nav-bar-item-text ${item.className ? item.className : ''}`}>
              {item.text}
            </div>
          </NavLink>
        );
      } else if (item.link === undefined) {
        return (
          <div className={item.hover || item.hover === undefined ? 'nav-bar-item' : 'nav-bar-item-no-hover'} key={item.text} role="button" tabIndex={0}>
            <div className={`nav-bar-item-text ${item.className ? item.className : ''}`}>
              {item.text}
            </div>
          </div>
        );
      } else {
        return (
          <NavLink
            className={item.hover || item.hover === undefined ? 'nav-bar-item' : 'nav-bar-item-no-hover'}
            to={item.link}
            key={item.text}
          >
            <div className={`nav-bar-item-text ${item.className ? item.className : ''}`}>
              {item.text}
            </div>
          </NavLink>
        );
      }
    });

    return (
      <div className="new-nav-bar">
        <div className="new-nav-bar-items-container">
          <div className="new-nav-bar-left">
            {this.renderBackButton('/userhome')}
            {this.renderLogo()}
          </div>
          {this.renderTitle()}
          <div className="new-nav-bar-items">
            {this.renderDropdown()}
            {itemsToRender}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    authed: reduxState.auth.authenticated,
  };
}

export default connect(mapStateToProps, { signOutUser })(NewNavBar);
