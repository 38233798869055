import React from 'react';
import { findItemImage } from './Utility';
import WarblerCoinItem from '../../../../images/profile/items/woinItem.svg';

const StoreItem = ({
  locked, item, itemType, wearItem, buyItem,
}) => {
  const { price, name } = item;
  const CoinHolder = () => {
    return (
      <div className="store-item-coins">
        <img src={WarblerCoinItem} alt="warbler-coin" className="store-item-warbler-coin" />
        <p className="store-item-coin-amount">{price || 0}</p>
      </div>
    );
  };
  return (
    <div className="store-item-background" id={`store-item-${name}`}>
      <div className="store-item-inner">
        {locked ? <CoinHolder /> : null}
        <img className="store-item-image" src={findItemImage(itemType, item.name)} alt="item-to-buy" />
        <button onClick={locked ? () => { buyItem(item, itemType); } : () => { wearItem(item, itemType); }}
          className={`store-item-button ${locked ? 'item-locked' : 'item-unlocked'}`}
          type="button"
        >{locked ? 'Buy' : 'Wear' }
        </button>
      </div>
    </div>
  );
};
export default StoreItem;
