/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserInfo } from '../../actions/index';
import ClassroomPageTeacher from './ClassroomPageTeacher/ClassroomPageTeacher';
import ClassroomPageStudent from './ClassroomPageStudent/ClassroomPageStudent';

class AuthedHomePage extends Component {
  componentDidMount = () => {
    if (this.props.user.username === null) {
      // get user
      this.props.getUserInfo();
    }
  }

  render() {
    let toRender;
    if (this.props.user.type === 'student') {
      toRender = <ClassroomPageStudent />;
    } else if (this.props.user.type === 'teacher') {
      toRender = <ClassroomPageTeacher />;
    }
    return (
      <div className="authed-home-page-container">
        {toRender}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    user: reduxState.user,
  };
}

export default connect(mapStateToProps, { getUserInfo })(AuthedHomePage);
