/* eslint-disable no-return-assign */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeContentPage } from '../../../actions';

class ContentMaking extends Component {
  handleChange = () => {
    this.props.saveLessonFunc(false, true, null, false);
  }

  render() {
    console.log(this.props);
    const defaultValues = {
      title: 'Type your title here',
      description: 'Content will go here. Type as how you would want it to display.',
    };
    if (this.props.pageInfo !== undefined && this.props.pageInfo !== null && this.props.pageInfo.info !== null) {
      defaultValues.title = this.props.pageInfo.info.title;
      defaultValues.description = this.props.pageInfo.info.description;
    }
    const key = Math.random();
    console.log('contentmaking rendering with id', this.props.pageInfo._id);
    return (
      <div className="content-question-main" key={key}>
        <textarea className="content-description-input"
          defaultValue={defaultValues.description}
          onFocus={(e) => e.target.placeholder = defaultValues.description}
          onBlur={this.handleChange}
        />
        <input type="hidden" className="id-hidden-input" value={this.props.pageInfo._id} />
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    lessonid: reduxState.lessonMaking.lessonid,
  };
}

export default connect(mapStateToProps, { makeContentPage })(ContentMaking);
