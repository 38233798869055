/* eslint-disable max-len */
import { ActionTypes } from '../actions/index';

const initialState = {
  username: localStorage.getItem('user'),
  email: '',
  completedLessons: [],
  classReferences: [],
  stats: {
    coins: 0,
    xp: 0,
    mc: {
      attempts: 0,
      completed: 0,
    },
    i: {
      attempts: 0,
      completed: 0,
    },
    p: {
      attempts: 0,
      completed: 0,
    },
    sr: {
      attempts: 0,
      completed: 0,
    },
    r: {
      attempts: 0,
      completed: 0,
    },
  },
  level: 0,
  profile: {
    color: '',
    top: '',
    accessory: '',
    fullBody: '',
    platform: '',
  },
  items: {
    Accessory: [],
    Fullbody: [],
    Top: [],
    Platform: [],
  },
};

const userReducer = (state = initialState, action) => {
  console.log('user reduced called with', action.type, action.payload);
  switch (action.type) {
    case ActionTypes.GET_USER_INFO:
    case ActionTypes.AUTH_USER:
      return {
        ...state,
        username: action.payload?.username,
        first_name: action.payload?.first_name,
        last_name: action.payload?.last_name,
        type: action.payload?.type,
        email: action.payload?.email,
        classes: action.payload?.classReferences,
        id: action.payload?.id,
        stats: action.payload?.stats,
        level: 0, // action.payload.stats.level
        completedLessons: action.payload?.completed_lessons,
        attemptedLessons: action.payload?.attempted_lessons,
        badges: action.payload?.badges,
        profile: action.payload?.profile,
        createdAt: action.payload?.createdAt,
        updatedAt: action.payload?.updatedAt,
        items: action.payload?.items,
      };
    case ActionTypes.ADDED_NEW_CLASS:
      return {
        ...state,
        classes: [...state.classes, action.payload.newClass],
      };
    case ActionTypes.UPDATE_XP:
      console.log('XP user reducer', state.xp);
      return {
        ...state,
        stats: {
          ...state.stats,
          xp: state.stats.xp + action.payload.xp,
        },
      };
    case ActionTypes.UPDATE_COINS:
      console.log('coins user reducer', state.coins);
      return {
        ...state,
        stats: {
          ...state.stats,
          coins: state.stats.coins + action.payload.coins,
        },
      };
    case ActionTypes.DECREASE_COINS:
      return {
        ...state,
        stats: {
          ...state.stats,
          coins: action.payload.stats.coins,
        },
      };
    case ActionTypes.UPDATE_LEVELS:
      return {
        ...state,
        level: action.payload.levels,
      };
    case ActionTypes.UPDATE_COMPLETED_LESSONS:
      return {
        ...state,
        completedLessons: action.payload.completed_lessons.userCompleted,
      };
    case ActionTypes.CHANGE_PROFILE_ICON:
      return {
        ...state,
        profile: action.payload,
      };
    case ActionTypes.UPDATE_ITEMS:
      return {
        ...state,
        items: action.payload.items,
      };
    case ActionTypes.DEAUTH_USER:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
