/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import VexFlow from 'vexflow';
import { connect } from 'react-redux';
import Letter from './Letter';
import ExerciseNav from '../ExerciseNav';
import Vex from '../../Vex';
import { toUppercaseNoSlash, notesAndColors } from '../../LetterType';
import { registerActivityCompletion } from '../../../../actions';

class Sightreading extends Component {
  constructor(props) {
    super(props);
    console.log('sightreading props are:', this.props);
    this.state = {
      found: false,
      notes: this.props.page.info.sr.correct_answers,
      capNotes: toUppercaseNoSlash(this.props.page.info.sr.correct_answers),
      letters: notesAndColors(this.props.page.info.sr.correct_answers),
      // eslint-disable-next-line react/no-unused-state
      originalLetterLocations: null,
      letterLocations: null,
      divLocations: null,
      relativeDivLocations: null,
      collisionMap: new Map(),
      correct: false,
      keySignature: this.props.page.info.sr.key_signature,
      clef: this.props.page.info.sr.cleftype,
      correctnessMap: new Map(),
      resetLetters: new Map(),
      xOffset: 0,
      yOffset: null,
      attempts: 1,
    };
    window.addEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    console.log('updating dimensions');
    const divLocs = document.getElementById('sightreading-note-divs');
    if (this.state.xOffset === 0) {
      const element = document.getElementById('sightreading-letters-box');
      // console.log('letters box', element);
      if (element !== undefined && element !== null) {
        const rect = element.getBoundingClientRect();
        // console.log('x-offset for letters box', rect.x);
        this.setState({ xOffset: rect.x });
      }
    }
    if (this.state.yOffset === null && divLocs !== null) {
      // get distance
      const divRect = divLocs.getBoundingClientRect();
      //  console.log('divRect:', divRect);
      const difference = divRect.y;
      // console.log('difference:', difference);
      this.setState({ yOffset: difference });
    }
  }

    checkPos = (x, y) => {
      if (x > 50 && y > 50) {
        this.setState({
          found: true,
        });
      } else {
        this.setState({
          found: false,
        });
      }
    }

    foundIt = () => {
      if (this.state.found) {
        return <div className="found">I found it!</div>;
      } else {
        return <div className="not">Did not find</div>;
      }
    }

    setMeasureInfo = (measures, keySignature) => {
      // console.log('setMeasureInfo called in sightreading with info', measures);
      const widthArray = [];
      for (let i = 0; i < measures.length; i += 1) {
        let width = this.getMeasureWidth(measures[i]);
        if (i === 0) {
          width = this.getFirstMeasureWidth(measures[i], keySignature);
        }
        widthArray.push(width);
      }
      // console.log('widthArray: ', widthArray);
      this.getDivLocations(widthArray, measures, keySignature);
      this.getInitialLetterLocations();
    }

    getInitialLetterLocations = () => {
    //  console.log('getInitialLetterLocations called');
      const element = document.getElementById('sightreading-letters-box');
      const node = element;
      const letterLocationMap = new Map();
      const originalLetterLocations = new Map();
      node.childNodes.forEach((n) => {
        const location = n.getBoundingClientRect();
        //   console.log('node:', n.getAttribute('id'), ':', location.x);
        const json = {
          bottom: location.bottom,
          height: location.height,
          left: location.left,
          right: location.right,
          top: location.top,
          width: location.width,
          x: location.x,
          y: location.y,
        };
        const { id } = n;
        letterLocationMap.set(id, json);
        originalLetterLocations.set(id, json);
      });
      //  console.log('letterLocations', letterLocationMap);
      this.setState({ letterLocations: letterLocationMap, originalLetterLocations });
      this.updateDimensions();
    }

    changeLetterLocation = (letter, deltaX, deltaY) => {
      if (this.state.yOffset === null) {
        this.updateDimensions();
      }
      // console.log('letterLocations', this.state.letterLocations);
      // console.log('changing letter location');
      const location = this.state.letterLocations.get(letter);
      // console.log('object for letter', letter, 'pre-change', location);
      const newX = location.x + deltaX;
      const newY = location.y + deltaY;
      // console.log('newX', newX);
      const newLocation = {
        bottom: location.bottom + deltaY,
        height: location.height + deltaY,
        left: location.left + deltaX,
        right: location.right + deltaX,
        top: location.top + deltaY,
        width: location.width,
        x: newX,
        y: newY,
      };
      const { letterLocations } = this.state;
      letterLocations.set(letter, newLocation);
      // console.log('changed object for letter', letter, ' post-change: ', newLocation);
      this.setState({ letterLocations });
      this.checkLetterCollisions(letter);
    }

    checkLetterCollisions = (letter) => {
      // console.log('letterLocations', this.state.letterLocations);
      const letterLocation = this.state.letterLocations.get(letter);
      // console.log('checking collision for letter', letter, 'letterLocation:', letterLocation);
      const { divLocations } = this.state;
      // console.log('map', divLocations);
      if (this.state.divLocations !== null) {
        // console.log('divLocations', this.state.divLocations);
        for (let i = 0; i < this.state.divLocations.length; i += 1) {
          const divX = divLocations[i] + this.state.xOffset;
          // initial left of C -- 185
          // 185 = position 0
          // 64 + offset = 185
          // console.log('element', i, 'has xlocation', divX);
          // nconsole.log('letter left', letterLocation.left, 'letter right', letterLocation.right);
          const { collisionMap } = this.state;
          //   console.log('yOFfset', this.state.yOffset);
          if (letterLocation.left > divX && letterLocation.right < divX + 280 && letterLocation.top > this.state.yOffset && letterLocation.bottom < this.state.yOffset + 400) {
            console.log('Letter ', letter, 'COLLLIDES WITH ELEMENT', i);
            collisionMap.set(letter, i);
            this.setState({ collisionMap });
            return true;
          } else {
            collisionMap.delete(letter);
            this.setState({ collisionMap });
          }
        }
      }
      // console.log('letter does not collide with anything');
      return false;
    }

    getYOffSet = (rects) => {
      // rects i deal with are 640, 780
      const yOffSet = rects[0].top - 640;
      console.log('yOffset:', yOffSet);
      // return yOffSet;
      return 0;
    }

    getXOffset = (i) => {
      // make a product of screen width
      return 300;
    }

    getFirstMeasureWidth = (measureNotes, keySignature) => {
      const width = this.getMeasureWidth(measureNotes) + this.getKeySignatureWidth(keySignature);
      return width;
    }

    getKeySignatureWidth = (keySignature) => {
      const manager = new VexFlow.Flow.KeyManager(keySignature);
      manager.setKey(keySignature);
      const arr = [];
      arr.push(manager.getAccidental('a'));
      arr.push(manager.getAccidental('b'));
      arr.push(manager.getAccidental('c'));
      arr.push(manager.getAccidental('d'));
      arr.push(manager.getAccidental('e'));
      arr.push(manager.getAccidental('f'));
      arr.push(manager.getAccidental('g'));
      let accidentalCount = 0;
      for (let i = 0; i < arr.length; i += 1) {
        if (arr[i].accidental !== undefined) {
          accidentalCount += 1;
        }
      }
      return accidentalCount * 16 + 24;
    }

    getMeasureWidth = (measure) => {
      let width = 160;
      width += measure.length * 20;
      return width;
    }

    getDivLocations = (widthArray, measures, keySignature) => {
      const divLocations = [];

      let cumulativePixels = 0;
      for (let i = 0; i < measures.length; i += 1) {
        let width = widthArray[i];
        const measure = measures[i];
        if (i === 0) {
          const keySignatureWidth = this.getKeySignatureWidth(keySignature);
          width -= keySignatureWidth;
          cumulativePixels += keySignatureWidth;
        }

        if (measure.length === 1) {
          divLocations.push(cumulativePixels + 40);
          cumulativePixels += width;
        } else {
          for (let j = 0; j < measure.length; j += 1) {
            const noteLocation = cumulativePixels + parseInt(width / (measure.length + 1), 10);
            cumulativePixels += parseInt(width / (measure.length + 1), 10);
            divLocations.push(noteLocation);
          }
          cumulativePixels += parseInt(width / (measure.length + 1), 10);
        }
      }
      // console.log('divLocations', divLocations);
      this.getRelativeDivLocations(divLocations);
      this.setState({ divLocations });
    }

    getRelativeDivLocations = (divLocations) => {
      const relativeDivLocations = [];
      relativeDivLocations.push(divLocations[0]);
      for (let i = 1; i < divLocations.length; i += 1) {
        if (i < divLocations.length) {
          relativeDivLocations.push(divLocations[i] - divLocations[i - 1]);
        }
      }
      this.setState({ relativeDivLocations });
      // console.log('relativeDivLocations', relativeDivLocations);
    }

    getNoteDivID = (i) => {
      // console.log('i', i);
      const location = this.state.divLocations[i];
      const id = i.toString().concat('-', location);
      return id;
    }

    renderNoteDivs = () => {
      if (this.state.relativeDivLocations !== null) {
        let i = -1;
        return (
          <div className="sightreading-note-divs" id="sightreading-note-divs">
            {this.state.relativeDivLocations.map((loc) => {
              i += 1;
              return (
                <span id={this.getNoteDivID(i)} style={{ marginLeft: loc }} className="sightreading-div-location" />
              );
            })}
            <span className="sightreading-div-location" style={{ marginLeft: '180px' }} />
          </div>
        );
      } else {
        return (
          <div>loading divlocations</div>
        );
      }
    }

    /*
    if 1 note, 2 sections
    if 2 notes, 3 sections
    if 3 notes, 4 sections
    if 4 notes, 5
    */
   getLetterID = (letter) => {
     const letterID = 'sightreading-letter'.concat(letter);
     console.log('letterID', letterID);
     return letterID;
   }

   returnLetterToOriginalPosition = (letter) => {
     const currentPositions = this.state.letterLocations;
     // const originalPosition = this.state.originalLetterLocations.get(letter);
     // console.log('original position for letter', letter, ' ', originalPosition);
     currentPositions.set(letter, this.state.originalLetterLocations.get(letter));
     this.setState({ letterLocations: currentPositions });
   }

   checkAnswers = () => {
     // console.log('checkAnswers called');
     // compare notes to collisionMap

     // check both
     console.log('notes', this.state.notes);
     const correctArr = [];
     let correctCount = 0;
     const { correctnessMap } = this.state;
     const { resetLetters } = this.state;
     const { collisionMap } = this.state;
     this.state.letters.map((letterObj) => {
       if (this.state.collisionMap.has(letterObj.letter)) {
         const collisionIndex = this.state.collisionMap.get(letterObj.letter);
         const note = this.state.notes[collisionIndex];
         if (this.compareLetters(letterObj.letter, note)) {
           correctCount += 1;
           correctArr.push(true);
           correctnessMap.set(letterObj.letter, true);
           resetLetters.set(letterObj.letter, false);
         } else {
           correctArr.push(false);
           correctnessMap.set(letterObj.letter, false);
           resetLetters.set(letterObj.letter, true);
           collisionMap.delete(letterObj);
           this.returnLetterToOriginalPosition(letterObj.letter);
         }
       } else {
         correctArr.push(false);
         correctnessMap.set(letterObj.letter, false);
         resetLetters.set(letterObj.letter, true);
         collisionMap.delete(letterObj);
         this.returnLetterToOriginalPosition(letterObj.letter);
       }
     });

     this.setState({ correctnessMap, resetLetters, collisionMap });

     if (correctCount === this.state.notes.length) {
       console.log('all correct!');
       this.setState({ correct: true });
       this.props.registerActivityCompletion(this.props.page._id, this.state.attempts, this.props.user.id, 'SR');
     } else {
       this.setState((prevState) => { return ({ attempts: prevState.attempts + 1 }); });
       console.log('not all correct', correctArr);
       this.props.registerFailure();
     }
     // console.log('resetLetters in checkAnswer', resetLetters);
   }

   compareLetters = (letter, note) => {
     const noteString = note.keys[0].replace('n', '').split('/')[0];
     const letterString = letter.toLowerCase();
     // console.log('noteString', noteString, 'vs letter', letterString);
     if (noteString.localeCompare(letterString) === 0) {
       return true;
     } else {
       // console.log('different letters');
       return false;
     }
   }

   goToNext = () => {
     this.props.goToNext(this.state.attempts, 'Sightreading');
   }

   renderNextOrDoneButton = () => {
     if (this.state.correct) {
       return (
         <button className="button yellow next-button" type="button" onClick={this.goToNext}>Next</button>
       );
     } else {
       return (
         <button className="button yellow next-button" type="button" onClick={this.checkAnswers} onSubmit={() => { this.checkAnswers(); console.log('clicked'); }}>Done</button>
       );
     }
   }

   resetComplete = (letter) => {
     const { resetLetters } = this.state;
     resetLetters.set(letter, false);
     this.setState({ resetLetters });
   }

   render() {
     // console.log('rendering with letterLocationmap', this.state.letterLocations);
     // console.log(this.state);
     console.log('sr', this.props.page);
     return (
       <div className="sightreading-main-page">
         <ExerciseNav
           lives={this.props.lives}
           percentage={this.props.percentage}
           xp={this.props.xp}
           page={this.props.page}
           infinity={this.props.infinity}
           level={this.props.level}
           type={this.props.type}
           changePage={this.props.changePage}
           pageCount={this.props.pageCount}
           currentPage={this.props.currentPage}
         />
         <div className="sightreading-exercise-body">
           <div className="instructions">
             {this.props.page.info.sr.instructions}
             <br />
             <div style={{ fontSize: '0.7em' }}>The letters will fill in when they&apos;re underneath a note!</div>

           </div>
           <div className="sightreading-exercise-pieces">
             <div className="sightreading-letters-box" id="sightreading-letters-box" style={{ width: '1200px' }}>
               {this.state.letters.map((letterObj) => {
                 return (
                   <Letter
                     letter={letterObj.letter}
                     cb={this.foundIt}
                     color={letterObj.color}
                     changeLetterLocation={this.changeLetterLocation}
                     checkLetterCollisions={this.checkLetterCollisions}
                     collisionStatus={this.state.collisionMap.get(letterObj.letter)}
                     correct={this.state.correctnessMap.get(letterObj.letter)}
                     resetPosition={this.state.resetLetters.get(letterObj.letter)}
                     resetLetterComplete={this.resetComplete}
                   />
                 );
               })}
             </div>
             <div className="vexflow-box">
               <Vex
                 timeSignature="4/4"
                 clef={this.state.clef}
                 keySignature={this.state.keySignature}
                 notes={this.state.notes}
                 divId="sightreading-vex"
                 color="black"
                 mode="sightreading"
                 setMeasureInfo={this.setMeasureInfo}
                 correctnessArray={[]}
                 rerender={false}
                 setMeasureCount={() => { console.log('setMeasureCount called in sightreading'); }}
               />
               <div>{this.renderNoteDivs()}</div>
             </div>
           </div>
         </div>
         <div className="next-button-holder">
           {this.renderNextOrDoneButton()}
         </div>
       </div>
     );
   }
}

function mapStateToProps(reduxState) {
  return {
    user: reduxState.user,
  };
}

export default connect(mapStateToProps, { registerActivityCompletion })(Sightreading);
