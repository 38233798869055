import React from 'react';
import { useTimer } from 'react-timer-hook';
import ListeningMCDisplay from './ActivityDisplays/ListeningMCDisplay';
import RhythmDisplay from './ActivityDisplays/RhythmDisplay';

const TeacherQuestion = ({
  currentQuestion, playersAnswered, endQuestionFromTime, questionType,
}) => {
  console.log(currentQuestion, 'currentQuestion');
  const time = new Date();
  const addedTime = questionType === 'Listening-Multiple-Choice' ? 29 : 59;
  time.setSeconds(time.getSeconds() + addedTime); // make timer
  const {
    seconds,
  } = useTimer({ expiryTimestamp: time, onExpire: () => { endQuestionFromTime(questionType); } });
  console.log(seconds, 'seconds');
  if (currentQuestion === undefined) {
    return (
      <div>Loading...</div>
    );
  }

  if (questionType === 'Listening-Multiple-Choice') {
    return (
      <div>
        <ListeningMCDisplay seconds={seconds} playersAnswered={playersAnswered} page={currentQuestion} />
      </div>
    );
  }
  return (
    <div className="rhythm-ic-wrapper">
      <RhythmDisplay
        seconds={seconds}
        playersAnswered={playersAnswered}
        page={currentQuestion}
        instructions={currentQuestion.info.r.instructions}
        activityID={currentQuestion._id}
        notes={currentQuestion.info.r.notes}
        timeSignature={currentQuestion.info.r.time_signature}
        bpm={currentQuestion.info.r.bpm}
      />
    </div>
  );
};

export default TeacherQuestion;
