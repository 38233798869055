/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserInfo } from '../actions';
import ScrollToTop from '../utilities/scrollToTop';
import LandingPage from './published_components/Landing_Page/LandingPage';
import NewUserPage from './published_components/NewUserPage';
import AboutUsPage from './published_components/AboutUsPage';
import AllClasses from './published_components/AllClasses';
import Login from './published_components/Login';
import Lesson from './published_components/Lesson';
import LCTopLevel from './published_components/NewLessonCreation/LessonCreationTopLevel';
import AuthedHomePage from './published_components/AuthedHomePage';
import PrivateRoute from './published_components/PrivateRoute';
import LandingPageStudents from './published_components/Landing_Page/LandingPageStudents';
import LandingPageTeachers from './published_components/Landing_Page/LandingPageTeachers';
import Community from './published_components/Community';
import FallBack from './published_components/FallBack';
import InClassGameMain from './published_components/InClassGameMain';
import InClassGameWrapper from './published_components/InClassGame/InClassGameWrapper';
import HeadToHead from './published_components/HeadtoHead';
import NewProfilePage from './published_components/ProfilePage/NewProfilePage';

const App = (props) => {
  const token = localStorage.getItem('token');
  if (token && props.user.username === null) {
    console.log(localStorage);
    props.getUserInfo();
  }
  console.log('rendering app');
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/home" component={LandingPage} />
        <Route path="/home/students" component={LandingPageStudents} />
        <Route path="/home/teachers" component={LandingPageTeachers} />
        <Route path="/about" component={AboutUsPage} />
        <Route path="/inClassGame" component={InClassGameMain} />
        <Route path="/game" component={InClassGameWrapper} />
        <Route path="/HeadToHeadGame" component={HeadToHead} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/newuser" component={NewUserPage} />
        <PrivateRoute exact path="/userhome" component={AllClasses} />
        <PrivateRoute exact path="/community" component={Community} />
        <PrivateRoute exact path="/lessons/:id" component={Lesson} />
        <PrivateRoute exact path="/class/:code" component={AuthedHomePage} />
        <PrivateRoute exact path="/profile" component={NewProfilePage} />
        <PrivateRoute exact path="/createlesson/:code" component={LCTopLevel} />
        <Route component={FallBack} />
      </Switch>
    </Router>
  );
};

function mapStateToProps(reduxState) {
  return {
    user: reduxState.user,
  };
}

export default connect(mapStateToProps, { getUserInfo })(App);
