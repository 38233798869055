/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import blackx from '../../../../images/black_x.svg';

class ClassroomPageTeacherStudentsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
    console.log('props', props);
  }

  openModal = () => {
    this.setState({
      modalOpen: true,
    });
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    console.log('got students list: ', this.props.studentsList);
    return (
      <div className="teacher-students-view-container">
        <div className="top-stuff">
          <div className="left-text-cp">
            <span className="page-title">Students</span>
            <span className="number-of-students">{this.props.studentsList.length} students</span>
          </div>
          <div className="right-buttons">
            <button type="button" className="invite-students-button" onClick={this.openModal}>Invite Students</button>
          </div>
        </div>
        <div className="student-flexbox">
          {this.props.studentsList}
        </div>
        <div className="code-modal-flex">
          <Modal
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal}
            className="Modal smallmodal code-modal"
            overlay="overlay"
            contentLabel="view-code"
            ariaHideApp={false}
          >
            <div className="code-modal-rows">
              <div className="code-modal-x">
                <img alt="x-out" className="code-modal-render-x" src={blackx} onClick={this.closeModal} />
              </div>
              <div className="code-modal-title">Your classroom code:</div>
              <div className="code-modal-code">{this.props.code}</div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    code: state.class.code,
  };
}

export default connect(mapStateToProps, null)(ClassroomPageTeacherStudentsView);
