/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import KeySignatureSelect from './KeySignatureSelect';
import ClefSelect from './ClefSelect';

// This is used for making the listening question options depending on how many options the user wants.
const InputBox = (props) => {
  const id = `lmc-answer-input-box-${props.num}`;
  // let isThisOne = false;
  // if (props.correctAnswer === props.notes) {
  //   isThisOne = true;
  // }
  let title = '';
  let extraClassName = '';
  if (props.num === 0) {
    title = 'correct answer choice: ';
    extraClassName = 'sky-blue-text';
  } else {
    title = `answer choice ${props.num + 1}: `;
  }
  return (
    <div className="listening-mc-answer-input-box-container">
      <div className={`note-title ${extraClassName} note-title-bottom-margin`}>{title}</div>
      {/* <input type="radio" id={`${id}-input-radio`} className="input-radio-box" name={`correct-answer-${props.num}`} value={props.num} defaultChecked={isThisOne} /> */}
      <label htmlFor={id}>
        <div className="answer-input-box-container">
          <div className={`note-title ${extraClassName}`}>note 1: </div>
          <input type="text" className="lmc-input-box sr-note-input-box" id={`input-lmc-${props.num + 1}-1`} defaultValue={props.notes[0]} onBlur={props.handleChange} />
        </div>
        <div className="answer-input-box-container">
          <div className={`note-title ${extraClassName}`}>note 2: </div>
          <input type="text" className="lmc-input-box sr-note-input-box" id={`input-lmc-${props.num + 1}-2`} defaultValue={props.notes[1]} onBlur={props.handleChange} />
        </div>
        <div className="answer-input-box-container">
          <div className={`note-title ${extraClassName}`}>note 3: </div>
          <input type="text" className="lmc-input-box sr-note-input-box" id={`input-lmc-${props.num + 1}-3`} defaultValue={props.notes[2]} onBlur={props.handleChange} />
        </div>
        <div className="answer-input-box-container">
          <div className={`note-title ${extraClassName}`}>note 4: </div>
          <input type="text" className="lmc-input-box sr-note-input-box" id={`input-lmc-${props.num + 1}-4`} defaultValue={props.notes[3]} onBlur={props.handleChange} />
        </div>
      </label>
    </div>
  );
};

class ListeningMCQuestionCreate extends Component {
  constructor(props) {
    super(props);
    if (this.props.pageInfo !== undefined && this.props.pageInfo !== null && this.props.pageInfo.info !== null && this.props.pageInfo.info !== undefined) {
      this.state = {
        keySig: this.props.pageInfo.info.mc.key_signature,
        cleftype: this.props.pageInfo.info.mc.cleftype,
      };
    } else {
      this.state = {
        keySig: 'C',
        cleftype: 'treble',
      };
    }
  }

  setOptions = () => {
    const options = [];
    for (let i = 0; i < 4; i++) {
      options.push(<InputBox
        key={i}
        num={i}
        notes={(this.props.pageInfo.info) ? this.props.pageInfo.info.mc.options[i] : ['', '', '', '']}
        correctAnswer={(this.props.pageInfo.info) ? this.props.pageInfo.info.mc.correct_answer : 0}
        handleChange={this.handleChange}
      />);
    }
    return options;
  }

  updateKeySig = (ks) => {
    this.setState({ keySig: ks });
  }

  updateClef = (clef) => {
    this.setState({ cleftype: clef });
  }

  handleChange = () => {
    this.props.saveLessonFunc(false, true, null, false);
  };

  render() {
    return (
      <div className="nlc-question-main-width">
        <div className="nlc-question-selects-width">
          <div className="select-container">
            <div className="select-title">Key signature: </div>
            <KeySignatureSelect ks={this.state.keySig} func={this.updateKeySig} handleChange={this.handleChange} />
          </div>
          <div className="select-container">
            <div className="select-title">Clef type: </div>
            <ClefSelect clef={this.state.cleftype} func={this.updateClef} handleChange={this.handleChange} />
          </div>
        </div>
        <div className="nlc-entry-inputs-flex-listeningmc">
          {this.setOptions()}
        </div>
        <input type="hidden" className="id-hidden-input" value={this.props.pageInfo._id} />
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    lessonid: reduxState.lessonMaking.lessonid,
    pageNumber: reduxState.lessonMaking.pageNumber,
  };
}
export default connect(mapStateToProps)(ListeningMCQuestionCreate);
