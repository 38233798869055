/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import ProgressBar from '../ProgressBar';

class ExerciseNav extends Component {
  renderLives = () => {
    const num = this.props.lives;
    console.log('num: ', num);
    if (num === -1) {
      return (
        <div />
      );
    }
    const lives = [true, true, true];
    for (let i = 0; i < 3 - num; i += 1) {
      lives[2 - i] = false;
    }

    return (
      <div className="lives" id="no-top-3">
        {lives.map((alive) => {
          return (
            <div>{this.renderHeart(alive)}</div>
          );
        })}
      </div>
    );
  }

  renderHeart = (alive) => {
    if (alive) {
      return (
        <i id="heart" className="fas fa-heart" />
      );
    } else {
      return (
        <i id="heart" className="fas fa-heart-broken" />
      );
    }
  }

  renderProgress = () => {
    if (this.props.infinity) {
      return (
        <div className="right-exercise-nav" id="no-top-5">
          <div className="exercise-nav-xp" id="no-top-6">+ {this.props.xp}XP</div>
          <div className="exercise-nav-level"> Round {this.props.level}</div>
        </div>
      );
    } else {
      return (
        <div className="right-exercise-nav-col" id="no-top-4">
          <div className="right-exercise-nav">
            <ProgressBar percentage={this.props.percentage} />
          </div>
        </div>
      );
    }
  }

  renderLeftArrow = () => {
    if (this.props.currentPage - 1 > 0) {
      return (
        <i className="fas fa-chevron-left" id="tln-arrow" onClick={() => { this.props.changePage(-1); }} />
      );
    } else {
      return (
        <span />
      );
    }
  }

  renderRightArrow = () => {
    if (this.props.currentPage - 1 < this.props.pageCount) {
      return (
        <i className="fas fa-chevron-right" id="tln-arrow" onClick={() => { this.props.changePage(1); }} />
      );
    } else {
      return (
        <span />
      );
    }
  }

  renderNavigationButtons = () => {
    if (((this.props.user !== undefined && this.props.user.type === 'teacher') || this.props.type === 'preview') && !this.props.infinity) {
      return (
        <div className="teacher-lesson-navigation">
          {/* {this.state.currentPage > 1 && <button type="button" onClick={() => { this.setState((prevState) => ({ currentPage: prevState.currentPage - 1 })); }}>Page Last</button>} */}
          {this.renderLeftArrow()}
          &nbsp; Page {this.props.currentPage} &nbsp;
          {/* {this.state.currentPage < length && <button type="button" onClick={() => { this.goToNext(1, null); }}>Page next</button>} */}
          {this.renderRightArrow()}
        </div>
      );
    } else {
      return (
        <div className="blank" />
      );
    }
  }

  renderBackButton = () => {
    const url = window.location.href;
    console.log('url', url);
    const arr = url.split('/');
    if ((arr.length > 2 && arr[arr.length - 2] === 'createlesson') || this.props.type === 'preview') {
      return (
        <div className="left-exercise-nav" />
      );
    } else {
      return (
        <div className="left-exercise-nav" id={this.props.infinity ? 'no-top-3' : ''}>
          <NavLink to={(this.props.currLessonTitle === 'random') ? '/userhome' : `/class/${this.props.classcode}`}>
            {/* <a href={(this.props.currLessonTitle === 'random') ? '/userhome' : `/class/${this.props.classcode}`}> */}
            <div className="arrow-back-button blue-arrow">
              <FontAwesomeIcon className="back-arrow" icon={faChevronLeft} />
            </div>
          </NavLink>
          {/* </a> */}
          <div className="back-word blue-word">Back</div>
        </div>
      );
    }
  }

  render() {
    console.log('the props', this.props);
    return (
      <div className={`main-exercise-nav ${this.props.infinity ? 'yellow-background-nav' : ''}`}>
        {this.renderBackButton()}
        {this.renderNavigationButtons()}
        {this.renderLives(3)}
        <div className="exercise-nav-xp" id={this.props.infinity ? 'no-top-3' : ''}>{this.props.user.stats.xp}XP</div>
        {this.renderProgress()}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  console.log(reduxState.lessonMaking);
  return {
    classcode: reduxState.class.code,
    currLessonTitle: (Object.keys(reduxState.lesson).includes('currentLesson')) ? reduxState.lesson.currentLesson.title : reduxState.lessonMaking.title,
    user: reduxState.user,
  };
}

export default withRouter(connect(mapStateToProps)(ExerciseNav));
