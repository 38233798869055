import React, { Component } from 'react';
import Select from 'react-select';
import DropdownIndicator from './dropdownIndicator';

const MajorKeyOptions = ['C', 'F', 'Bb', 'Eb', 'Ab', 'Db', 'Gb', 'Cb', 'G', 'D', 'A', 'E', 'B', 'F#', 'C#'];
const MinorKeyOptions = ['Am', 'Dm', 'Gm', 'Cm', 'Fm', 'Bbm', 'Ebm', 'Abm', 'Em', 'Bm', 'F#m', 'C#m', 'G#m', 'D#m', 'A#m'];

const AllKeyOptions = [...MajorKeyOptions, ...MinorKeyOptions];

const customStyles = {

  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#DEDCDC',
    width: '101px',
    height: '37px',
    borderRadius: '5px',

  }),
  menuList: (provided, state) => ({
    ...provided,
    height: '110px',
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.59vw',
    lineHeight: '1.59vw',
    letterSpacing: '0.03em',
    color: '#757575',
    paddingTop: '0px',
    paddingBottom: '0px',
    boxSizing: 'border-box',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '21px',
    lineHeight: '1.59vw',
    letterSpacing: '0.03em',
    color: '#757575',
    height: 'fit-content',
  }),
  option: (provided, state) => ({
    ...provided,
    borderTop: '1px solid #DEDCDC',
    borderBottom: '1px solid #DEDCDC',
    boxSizing: 'border-box',
    backgroundColor: '#F3F3F3',
    color: '#757575',
    height: '37px',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '5px 8px',
  }),

  singleValueConatiner: (provided, state) => ({
    ...provided,
    flexDirection: 'column',
    justifyContent: 'center',
    transition: '200 ms ease all',
    height: 'fit-content',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
};

class KeySignatureSelect extends Component {
    setSelected = (selectedOption) => {
      this.props.func(selectedOption.label);
      this.props.handleChange();
    }

    makeOptions = () => {
      const toReturn = [];
      for (let i = 0; i < AllKeyOptions.length; i += 1) {
        toReturn.push({ value: i, label: AllKeyOptions[i] });
      }
      return toReturn;
    }

    render() {
      const options = this.makeOptions();
      return (
        <div className="key-signature-select">
          <Select
            className="react-select-container-ks"
            classNamePrefix="react-select"
            key={false}
            styles={customStyles}
            components={{ DropdownIndicator }}
            options={options}
            value={options.filter((option) => option.label === this.props.ks)}
            onChange={this.setSelected}
            isSearchable={false}
          />
        </div>
      );
    }
}

export default KeySignatureSelect;
