/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

const ColorSwatches = ({ changeColor, currentColor }) => {
  const colors = ['red', 'yellow', 'green', 'blue', 'purple'];
  return (
    <div className="store-color-swatches">
      {colors.map((color) => {
        return (
          <div onClick={() => { changeColor(color); }}
            id={`store-color-swatch-${color}`}
            key={`store-color-swatch-${color}`}
            className={`store-color-swatch ${currentColor === color ? 'active-swatch' : null}`}
          />
        );
      })}
    </div>
  );
};

export default ColorSwatches;
