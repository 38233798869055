import { io } from 'socket.io-client';
import {
  socketRoutes, MESSAGE, PROD_SERVER,
} from '../../lib/constants';

export const connectSocket = (id, type) => {
  // const socketserver = LOCAL_SERVER;
  const socketserver = PROD_SERVER;

  // create socket
  const socket = io(socketserver, { autoConnect: false });

  // error listener
  socket.on('connect_error', (err) => {
    console.log(err);
  });

  // any event happens listener
  socket.onAny((event, ...args) => {
    console.log(event, args);
  });

  // connects socket
  socket.connect();
  console.log('connect called');
  return socket;
};

export const disconnectSocket = (socket) => {
  socket.emit(socketRoutes.DISCONNECT);
};

export const attemptRejoinGameStudent = (socket, pin, id) => {
  socket.emit(socketRoutes.REJOIN_GAME_STUDENT, pin, { id });
};

export const setupNewGameSocket = (socket) => {
  console.log(socket);
  console.log('start new game socket');
  socket.emit(socketRoutes.INIT_GAME_TEACHER);
};

export const changeDifficulty = (socket, pin, difficulty) => {
  console.log('change difficulty called here');
  socket.emit(MESSAGE, socketRoutes.CHANGE_DIFFICULTY, pin, { difficulty });
};

export const joinGameSocket = (socket, pin, userInfo) => {
  console.log('join game socket called');
  socket.emit(socketRoutes.INIT_GAME_STUDENT, pin, userInfo);
};

export const startGameSocket = (socket, pin) => {
  console.log('start game socket called');
  socket.emit(MESSAGE, socketRoutes.START_GAME, pin, {});
};

export const guessAnswerSocket = (socket, pin, questionNumber, answer, id) => {
  console.log('guess answer socket called');
  console.log({
    socket, pin, questionNumber, answer, id,
  });
  socket.emit(MESSAGE, socketRoutes.GUESS_ANSWER, pin, { questionNumber, answer, id });
};

export const guessAnswerSocketRhythm = (socket, pin, questionNumber, answer, id) => {
  console.log('guess answer rhythm socket called: ');
  console.log({
    socket, pin, questionNumber, answer, id,
  });
  socket.emit(MESSAGE, socketRoutes.GUESS_ANSWER_RHYTHM, pin, { questionNumber, answer, id });
};

export const nextQuestionSocket = (socket, pin, currentQuestionNumber) => {
  console.log('next question socket called', currentQuestionNumber);
  socket.emit(MESSAGE, socketRoutes.NEXT_QUESTION, pin, { currentQuestionNumber });
};

export const endGameSocket = (socket, pin) => {
  console.log('end game socket called');
  socket.emit(MESSAGE, socketRoutes.END_GAME, pin, {});
};

export const endQuestionFromTime = (socket, pin, questionNumber, correctAnswer, type) => {
  console.log('end question from time socket called: ', { pin, questionNumber, correctAnswer });
  socket.emit(MESSAGE, socketRoutes.END_QUESTION_FROM_TIME, pin, { questionNumber, correctAnswer, type });
};
