import React, { Component } from 'react';
// import Lottie from 'react-lottie';
import headToHeadLost from '../../../images/headToHeadLost.svg';
import headToHeadWon from '../../../images/headToHeadWon.svg';
import WarblerCoin from '../../../images/profile/woin.svg';
// import lightning from '../../../animations/lightning.json';

/* const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const lightningStyle = {
  width: '50vw',
  height: '50vh',
  position: 'absolute',
}; */

class GameOver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wonGame: this.props.wonGame,
      // playAnimation: true,
    };
  }

  goBackHome = () => {
    console.log('going home');
    const { history } = this.props;
    history.push('/userhome');
  }

  renderResultScreen = () => {
    if (this.state.wonGame) {
      /* setTimeout(() => {
        this.setState({ playAnimation: false });
      }, 1500); */
      return (
        <div className="head-to-head-game-won">
          <div className="head-to-head-game-result">Woohoo! You won!</div>
          <img className="head-to-head-game-result-bird" id="h2h-adjust-image" src={headToHeadWon} alt="headWonBird" />
          <div className="head-to-head-game-stats">You beat {this.props.partner} by {this.props.difference} point{this.props.difference === 1 ? null : 's'}!</div>
          <div className="head-to-head-game-xp-won">+{this.props.xpGained} XP</div>
          <div className="head-to-head-game-coins-won">
            +{this.props.coinsGained}
            <img className="head-to-head-game-woin-image" src={WarblerCoin} alt="woin" />
          </div>
          {/* this.state.playAnimation ? (
            <Lottie
              isStopped={this.state.playAnimation}
              className="lightning-anim"
              style={lightningStyle}
              options={{ ...defaultOptions, animationData: lightning }}
            />
          ) : null */}
          <div className="head-to-head-buttons-wrapper">
            <div className="head-to-head-return" onClick={this.props.goBackHome} role="button" tabIndex={0}>
              Return to Home
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="head-to-head-game-lost">
          <div className="head-to-head-game-result">Oh no, you lost :(</div>
          <img className="head-to-head-game-result-bird" src={headToHeadLost} alt="headLostBird" />
          <div className="head-to-head-game-stats">You lost to {this.props.partner} by {this.props.difference} point{this.props.difference === 1 ? null : 's'}!</div>
          <div className="head-to-head-game-xp-lost">+{50} XP</div>
          <div className="head-to-head-game-coins-lost">
            +{5}
            <img className="head-to-head-game-woin-image" src={WarblerCoin} alt="woin" />
          </div>
          <div className="head-to-head-buttons-wrapper">
            <div className="head-to-head-return" onClick={this.props.goBackHome} role="button" tabIndex={0}>
              Return to Home
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <>{ this.renderResultScreen() }</>
    );
  }
}

export default GameOver;
