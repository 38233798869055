/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classroomFunctionaltiy from '../../../../images/landing/classroom_functionality.svg';
import community from '../../../../images/landing/community.svg';
import byJoining from '../../../../images/landing/by_joining.svg';
import NewNavBar from '../../cs98_components/NewNavBar';

class LandingPageTeachers extends Component {
  render() {
    return (
      <div className="landing-page-teachers-main">
        <NewNavBar
          renderLogo
          items={[
            {
              text: 'Sign up',
              link: '/newuser',
              icon: 'fas fa-user-plus',
            },
            {
              text: 'Log in',
              link: '/login',
              icon: 'fa fa-sign-in',
            },
          ]}
          dropdownItems={[
            {
              text: 'Teacher Info',
              link: '/home/teachers',
            },
            {
              text: 'Student Info',
              link: '/home/students',
            },
            {
              text: 'About the Team',
              link: '/about',
            },
          ]}
          dropdownIcon="fas fa-book-open"
        />
        <div className="landing-page-teachers-section">
          <div className="landing-page-teachers-title-text-flex" id="lptttf-1">
            <div className="landing-page-teachers-title">No <span className="yellow-text">treble</span> teachers, </div>
          </div>
          <div className="landing-page-teachers-title-text-flex" id="lptttf-2">
            <div className="landing-page-teachers-title">Warbler is as easy to use as <span className="orange-text">1, </span><span className="green-text">2...</span></div>
          </div>
          <div className="landing-page-teachers-title-text-flex" id="lptttf-3">
            <div className="landing-page-students-mini-text">Not a teacher? Students click <NavLink to="/home/students"><span className="blue-underline">here.</span></NavLink></div>
          </div>
        </div>

        <div className="landing-page-teachers-section" id="lpts-2">
          <div className="lpts-left" id="lpts-2-left">
            <img className="teacher-number-image" id="classroom-functionality" src={classroomFunctionaltiy} alt="classroom functionality words, rounded" />
            <div className="large-number orange-text" id="number-1-lpts">1</div>
          </div>
          <div className="lpts-right" id="lpts-2-right">
            <div className="lpts-mini-title orange-text">As always, the teaching comes from you—</div>
            <div className="lpts-mini-title orange-text">we just give you tools to help you do what you do best.</div>
            <div className="lpts-text lpts-text-margin-top"><span className="orange-text">Create</span> your own
              lessons harnessing our 5 core activity types. <span className="orange-text">Track </span>
              your students&apos; progress. Innovate, get creative, and put your own spin on it.
            </div>
          </div>
        </div>

        <div className="landing-page-teachers-section" id="lpts-3">
          <div className="lpts-left" id="lpts-3-left">
            <img className="teacher-number-image" id="community-text" src={community} alt="community text, rounded" />
            <div className="large-number green-text" id="number-2-lpts">2</div>
          </div>
          <div className="lpts-right" id="lpts-3-right">
            <div className="lpts-mini-title green-text">Community is at the heart of what we do.</div>
            <div className="lpts-text">At Warbler, we seek to cultivate two main communities:</div>
            <div className="lpts-list lpts-text-margin-top">
              <div className="lpts-list-item">
                <div className="lpts-list-item-main green-text lpts-text">1. A community within your class</div>
                <div className="lpts-list-item-second lpts-text">As students complete activities,
                  they gain experience points, badges, streaks, and the opportunity
                  to level up and get on their class leaderboard. It’s fun, light-hearted,
                  ultimately drives students to learn more, and helps build your class community!
                </div>
              </div>
              <div className="lpts-list-item">
                <div className="lpts-list-item-main green-text lpts-text">2. Building a teaching community through a sharing of resources and knowledge</div>
              </div>
            </div>
          </div>
        </div>
        <div className="lpts-3-bottom">
          <div className="lpts-mini-title-b"><span className="green-text">Every lesson </span> ever created on Warbler becomes available for use</div>
          <div className="lpts-mini-title-b">for <span className="green-text"> every single teacher </span> on Warbler.</div>
        </div>
        <div className="landing-page-teachers-section" id="lpts-4">
          <img id="large-curved-text" src={byJoining} alt="By Joining Warbler, curved text" />
          <div className="lpss-6-subtitle" id="lpts-subtitle-text">You gain access to our growing repository of
            lessons, and by creating lessons yourself, you gain the opportunity not only to help your
            students but to influence countless other students&apos; and teachers&apos; lives in doing so.
          </div>
        </div>
        <div className="landing-students-footer">
          <div className="landing-footer-text">We’re giving out Warbler for free for Beta testing!</div>
          <div className="landing-footer-text"><a href="/newuser" className="dark-blue-underline-footer">Sign up here</a> to check it out for yourself 🤘</div>
        </div>
      </div>
    );
  }
}

export default LandingPageTeachers;
