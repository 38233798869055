import React, { Component } from 'react';
import { connect } from 'react-redux';

class ClassroomPageTeacherLeaderBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaderboard: null,
    };
  }

  componentDidMount() {
    this.createLeaderboard();
  }

    createLeaderboard = () => {
      if (this.props.class.students !== null) {
        console.log('leaderboard:', this.props.class.students);
        const students = [];
        // replace user's xp with most up-to-date count
        this.props.class.students.forEach((s) => {
          students.push(s);
        });
        const leaderboard = this.sortLeaders(this.props.class.students, 0, this.props.class.students.length - 1);
        console.log('leaderboard is: ', leaderboard);
        this.setState({ leaderboard });
      }
    }

        sortLeaders = (leaderboard, l, h) => {
        /* modified quickSort */
          if (l < h) {
            // eslint-disable-next-line prefer-const
            let [p, leaders] = this.partition(leaderboard, l, h);
            leaders = this.sortLeaders(leaders, l, p - 1);
            leaders = this.sortLeaders(leaders, p + 1, h);

            return leaders;
          }
          return leaderboard;
        }

        partition = (leaderboard, l, h) => {
          const pi = l + parseInt(Math.random() * (h - l), 10);
          const p = leaderboard[pi].xp;
          let leaders = this.swap(leaderboard, pi, h);

          let i = l;
          for (let j = l; j < h; j += 1) {
            if (leaders[j].xp > p) {
              leaders = this.swap(leaders, i, j);
              i += 1;
            }
          }

          leaders = this.swap(leaders, i, h);
          return [i, leaders];
        }

          swap = (leaderboard, i1, i2) => {
            const leaders = leaderboard;
            const temp = leaders[i1];
            leaders[i1] = leaders[i2];
            leaders[i2] = temp;
            return leaders;
          }

          renderLeaderboard() {
            if (this.state.leaderboard !== null) {
              return (
                this.state.leaderboard.map((student) => {
                  return (
                    <div className="student-in-leaderboard" key={student.username}>
                      <div className="teacher-leaderboard-student-rank">{this.state.leaderboard.indexOf(student) + 1}.</div>
                      <div className="teacher-leaderboard-student-username">{student.username}</div>
                      <div className="teacher-leaderboard-student-xp">{student.xp} XP</div>
                    </div>
                  );
                })
              );
            } else {
              return (<div />);
            }
          }

          render() {
            console.log(this.state.leaderboard);
            return (
              <div className="teacher-leaderboard">
                <div className="teacher-leaderboard-headers">
                  <div className="teacher-leaderboard-header-students">Students</div>
                  <div className="teacher-leaderboard-header-xp">XP</div>
                </div>

                {this.renderLeaderboard()}
              </div>
            );
          }
}

function mapStateToProps(reduxState) {
  return {
    class: reduxState.class,
  };
}

export default connect(mapStateToProps, null)(ClassroomPageTeacherLeaderBoard);
