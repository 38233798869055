import React from 'react';

const BarFinalResult = ({ place, name, mobileView }) => {
  let color = '';
  let size = '';

  switch (place) {
    case -1:
      color = 'placeholder-bar';
      size = 'placeholder-bar-size';
      break;
    case 1:
      color = 'orange-bar';
      size = 'orange-bar-size';
      break;
    case 2:
      color = 'seafoam-bar';
      size = 'seafoam-bar-size';
      break;
    case 3:
      color = 'sky-blue-bar';
      size = 'sky-blue-bar-size';
      break;
    default:
      color = 'orange-bar';
      size = 'orange-bar-size';
      break;
  }

  if (!mobileView) {
    return (
      <div className="final-result-bar">
        <div className={`final-result-bar-num ${color}`}>{place}</div>
        <div className={`final-result-bar-rect ${size}`} />
        <div className={`final-result-bar-user ${place === 1 ? 'bold-name' : ''}`}>{name === undefined || name.length <= 14 ? name : `${name.slice(0, 12)}...`}</div>
      </div>
    );
  } else {
    return (
      <div className="final-result-bar-mobile">
        <div className={`final-result-bar-num mobile-num ${color}`}>{place}</div>
        <div className={`final-result-bar-rect mobile-rect ${size}`} />
        <div className={`final-result-bar-user mobile-user ${place === 1 ? 'bold-name' : ''}`}>{name === undefined || name.length <= 14 ? name : `${name.slice(0, 12)}...`}</div>
      </div>
    );
  }
};
export default BarFinalResult;
