/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Tone from 'tone';
import Vex from '../../../Vex';
import PlayButton from '../../../Exercises/PlayButton';
import { vexToToneFormat } from '../../../LetterType';
import { registerActivityCompletion } from '../../../../../actions';
import NewNavBar from '../../../../cs98_components/NewNavBar';
import clockSvg from '../../assets/clock-icon.svg';

class ListeningMC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerChoices: this.props.page.info.mc.options,
      correctAnswer: this.props.page.info.mc.correct_answer,
      correctAnswerClicked: false,
      timeSignature: this.props.page.info.mc.time_signature,
      clef: this.props.page.info.mc.clef,
      divId: ['1', '2', '3', '4'],
      vexNotes: null,
      // completed: false,
      blueShadow: '',
      orangeShadow: '',
      pinkShadow: '',
      optionClicked: 0,
      attempts: 0,
    };
  }

  processVexNotes = () => {
    const vexNotes = [];
    this.props.page.info.mc.options.map((notes) => {
      const notesObj = [];
      notes.map((note) => {
        notesObj.push({ keys: [note], duration: 'q' });
      });
      vexNotes.push(notesObj);
    });
    console.log('vexNotes', vexNotes);
    this.setState({ vexNotes });
  }

  playMusic = () => {
    const sampler = new Tone.Sampler({
      urls: {
        A0: 'A0.mp3',
        C1: 'C1.mp3',
        'D#1': 'Ds1.mp3',
        'F#1': 'Fs1.mp3',
        A1: 'A1.mp3',
        C2: 'C2.mp3',
        'D#2': 'Ds2.mp3',
        'F#2': 'Fs2.mp3',
        A2: 'A2.mp3',
        C3: 'C3.mp3',
        'D#3': 'Ds3.mp3',
        'F#3': 'Fs3.mp3',
        A3: 'A3.mp3',
        C4: 'C4.mp3',
        'D#4': 'Ds4.mp3',
        'F#4': 'Fs4.mp3',
        A4: 'A4.mp3',
        C5: 'C5.mp3',
        'D#5': 'Ds5.mp3',
        'F#5': 'Fs5.mp3',
        A5: 'A5.mp3',
        C6: 'C6.mp3',
        'D#6': 'Ds6.mp3',
        'F#6': 'Fs6.mp3',
        A6: 'A6.mp3',
        C7: 'C7.mp3',
        'D#7': 'Ds7.mp3',
        'F#7': 'Fs7.mp3',
        A7: 'A7.mp3',
        C8: 'C8.mp3',
      },
      release: 1,
      baseUrl: 'https://tonejs.github.io/audio/salamander/',
      onload: () => {
        const now = Tone.now();
        const notes = vexToToneFormat(this.state.answerChoices[this.state.correctAnswer]);
        console.log('playMusic called', notes);
        for (let i = 0; i < notes.length; i += 1) {
          const diff = 0.5 * i;
          const note = notes[i];
          console.log(note);
          console.log(diff);
          sampler.triggerAttackRelease(note, '4n', now + diff);
        }
      },
    }).toDestination();
  }

  render() {
    if (this.state.vexNotes === null) {
      this.processVexNotes();
    }
    if (this.state.vexNotes !== null) {
      return (
        <div className="listening-mc-page">
          <div id="ic-listening-mc-main" className="listening-mc-main">
            <NewNavBar items={[
              {
                text: `${this.props.seconds} seconds`,
                link: '/home',
                icon: clockSvg,
                className: 'red-text-ic seconds-text-nav',
                iconType: 'img',
                alt: 'clock',
                hover: false,
              },
              {
                text: { text1: `${this.props.playersAnswered}`, text2: 'answers' },
                link: '/home',
                stacked: true,
                hover: false,
                className: { className1: 'red-text-ic answered-text-nav-num', className2: 'red-text-ic answered-text-nav' },
              },
            ]}
              title="In-class"
            />
            <div className="white-background-in-class">
              <div className="instructions">{this.props.page.info.mc.instructions}</div>
              <div id="listening-play-bar-ic" className="listening-play-bar">
                <PlayButton playMusic={this.playMusic} />
                <div className="listening-bar" />
              </div>
            </div>

            <div className="mc-options-in-class">
              <div className="mc-toprow">
                <div
                  id="red-answer-ic"
                  style={{ boxShadow: this.state.orangeShadow }}
                  className="mc-answer-button-in-class"
                >
                  <div className="in-class-answer-choice-num">1</div>
                  <div className="in-class-vex-wrapper">
                    <Vex
                      notes={this.state.vexNotes[0]}
                      timeSignature="4/4"
                      clef={this.props.page.info.mc.cleftype}
                      keySignature="C"
                      divId="listening-vex-1"
                      color="black"
                      mode="listening"
                      setMeasureInfo={() => {}}
                      correctnessArray={[]}
                      rerender={false}
                      setMeasureCount={() => { console.log('setMeasureCount called in sightreading'); }}
                    />
                  </div>
                </div>
                <div
                  style={{ boxShadow: this.state.pinkShadow }}
                  id="yellow-answer-ic"
                  className="mc-answer-button-in-class"
                >
                  <div className="in-class-answer-choice-num">2</div>
                  <div className="in-class-vex-wrapper">
                    <Vex
                      notes={this.state.vexNotes[1]}
                      timeSignature="4/4"
                      clef={this.props.page.info.mc.cleftype}
                      keySignature="C"
                      divId="listening-vex-2"
                      color="black"
                      mode="listening"
                      setMeasureInfo={() => {}}
                      correctnessArray={[]}
                      rerender={false}
                      setMeasureCount={() => { console.log('setMeasureCount called in sightreading'); }}
                    />
                  </div>
                </div>
              </div>
              <div className="mc-bottomrow">
                <div
                  id="blue-answer-ic"
                  style={{ boxShadow: this.state.blueShadow }}
                  className="mc-answer-button-in-class"
                >
                  <div className="in-class-answer-choice-num">3</div>
                  <div className="in-class-vex-wrapper">
                    <Vex
                      notes={this.state.vexNotes[2]}
                      timeSignature="4/4"
                      clef={this.props.page.info.mc.cleftype}
                      keySignature="C"
                      divId="listening-vex-3"
                      color="black"
                      mode="listening"
                      setMeasureInfo={() => {}}
                      correctnessArray={[]}
                      rerender={false}
                      setMeasureCount={() => { console.log('setMeasureCount called in sightreading'); }}
                    />
                  </div>
                </div>
                <div
                  className="mc-answer-button-in-class"
                  id="purple-answer-ic"
                  style={{ boxShadow: this.state.purpleShadow }}
                >
                  <div className="in-class-answer-choice-num">4</div>
                  <div className="in-class-vex-wrapper">
                    <Vex
                      notes={this.state.vexNotes[3]}
                      timeSignature="4/4"
                      clef={this.props.page.info.mc.cleftype}
                      keySignature="C"
                      divId="listening-vex-4"
                      color="black"
                      mode="listening"
                      setMeasureInfo={() => {}}
                      correctnessArray={[]}
                      rerender={false}
                      setMeasureCount={() => { console.log('setMeasureCount called in sightreading'); }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>Loading...</div>
      );
    }
  }
}

function mapStateToProps(reduxState) {
  return {
    user: reduxState.user,
  };
}

export default connect(mapStateToProps, { registerActivityCompletion })(ListeningMC);
