import React from 'react';
import newLogo from '../assets/logoo.svg';

const StudentWaitingScreen = ({ join, name }) => {
  return (
    <div className="ic-waiting-screen">
      <div id="hey-name" className="ic-waiting-text">Hey, {name}!</div>
      <img className="logo-bird-waiting" src={newLogo} alt="bird with logo" />
      <div className="ic-waiting-text">...waiting on your friends to {join ? 'join' : 'answer'}!</div>
    </div>
  );
};

export default StudentWaitingScreen;
