/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Draggable from 'react-draggable';

class Letter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deltaPosition: {
        x: 0, y: 0,
      },
    };
  }

  componentDidMount = () => {
    // console.log('letter mounted');
    // const element = document.getElementsByClassName('letter-holder');
    // console.log('element:', element);
  }

      handleDrag = (e, ui) => {
        if (!this.props.correct) {
          const { x, y } = this.state.deltaPosition;
          this.setState({
            deltaPosition: {
              x: x + ui.deltaX,
              y: y + ui.deltaY,
            },
          });
          this.props.cb(ui.x, y + ui.y);
          console.log('changeLetterLocation called in letter component');
          this.props.changeLetterLocation(this.props.letter, ui.deltaX, ui.deltaY);
        }
      };

      checkLetterCollisions = () => {
        this.props.checkLetterCollisions(this.props.letter);
      }

      renderLetter = () => {
        let { color } = this.props;
        let opacity = '0.6';

        if (this.props.collisionStatus !== undefined) {
          opacity = '1';
        }

        if (this.props.correct !== undefined && this.props.correct) {
          color = 'green';
        }

        if (this.props.letter.includes('b')) {
          const string = this.props.letter.replace('b', '');
          return (
            <div className={`letter ${this.props.color}-letter`} style={{ backgroundColor: color, opacity }}>{string}&#9837;</div>
          );
        } else if (this.props.letter.includes('#')) {
          const string = this.props.letter.replace('#', '');
          return (
            <div className={`letter ${this.props.color}-letter`} style={{ backgroundColor: color, opacity }}>{string}&#9839;</div>
          );
        } else {
          return (
            <div className={`letter ${this.props.color}-letter`} style={{ backgroundColor: color, opacity }}>{this.props.letter}</div>
          );
        }
      }

      resetPosition = () => {
        const deltaPosition = {
          x: 0,
          y: 0,
        };
        this.setState({ deltaPosition });
        this.props.resetLetterComplete(this.props.letter);
      }

      render() {
        if (this.props.resetPosition) {
          this.resetPosition();
        }
        // console.log('rendering letter with collisionStatus', this.props.collisionStatus);
        return (
          <div className="letter-holder" id={this.props.letter}>
            <Draggable
              handle=".letter"
              onDrag={this.handleDrag}
              onStop={this.checkLetterCollisions}
              position={this.state.deltaPosition}
            >
              {this.renderLetter()}
            </Draggable>
          </div>
        );
      }
}

export default Letter;
