/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import Modal from 'react-modal';
import birds from '../../../images/birds.svg';
import worm from '../../../images/worm.svg';
import whiteX from '../../../images/whiteX.svg';

class HeadtoHeadIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingModalOpen: false,
    };

    this.ws = this.props.ws;
  }

  openModal = (e) => {
    console.log(this.state.loadingModalOpen);
    this.setState({
      loadingModalOpen: true,
    });
  }

  closeModal = () => { // close modal when connected to server
    this.setState({
      loadingModalOpen: false,
    });
  }

  render() {
    return (
      <div className="headToHead">
        <Modal isOpen={this.state.loadingModalOpen}
          onRequestClose={this.closeModal}
          className="Modal loading"
          overlay="overlay"
          contentLabel="Loading"
          riaHideApp={false}
        >
          <div className="loading-modal-top">
            <img className="x" src={whiteX} alt="x to exit out of modal" onClick={this.closeModal} />
          </div>
          <div className="loading-modal-title">Loading...</div>
          <div className="loading-modal-sub-title">Connecting to server...</div>
        </Modal>
        <div className="game-info-flex">
          <div className="head-title">Early bird gets the worm...</div>
          <div className="head-rules">Play against a player and test your musical skills!</div>
          <div className="head-rules">Correct answers move you closer, wrong answers will move you back.</div>
          <div className="head-rules">Answer the questions correctly to beat the other player and get the worm!</div>
          <div className="head-subtitle">These are the <span className="boldface">5 skills</span> you will be tested on (in treble clef):</div>
          <div className="head-activity-types">
            <div className="head-activity">
              <div className="head-activity-type-word-1">1</div>
              <div className="head-activity-type-word">Rhythm</div>
            </div>
            <div className="head-activity">
              <div className="head-activity-type-word-2">2</div>
              <div className="head-activity-type-word">Sightreading</div>
            </div>
            <div className="head-activity">
              <div className="head-activity-type-word-3">3</div>
              <div className="head-activity-type-word">Intervals</div>
            </div>
            <div className="head-activity">
              <div className="head-activity-type-word-4">4</div>
              <div className="head-activity-type-word">Pitchmatching</div>
            </div>
            <div className="head-activity">
              <div className="head-activity-type-word-5">5</div>
              <div className="head-activity-type-word">Listening (Multiple Choice)</div>
            </div>
          </div>
          <div className="head-play-holder" onClick={this.props.clickedStart}>
            <div className="button-green">
              Start
            </div>
          </div>
          <img className="worm" src={worm} alt="worm" />
          <img className="birds" src={birds} alt="birds" />
        </div>
      </div>
    );
  }
}

export default HeadtoHeadIntro;
