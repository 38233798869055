import React, { Component } from 'react';
import Select from 'react-select';

const customStyles = {

  control: (provided, state) => ({
    ...provided,
    width: '237px',
    height: '55.94px',
    borderRadius: '8px',
    border: '2px solid #BABABA',

  }),
  valueContainer: (provided, state) => ({
    ...provided,
  }),
  menuList: (provided, state) => ({
    ...provided,
    height: '15vh',
    width: '16.5vw',
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '23px',
    lineHeight: '23px',
    letterSpacing: '0.03em',
    color: '#757575',
    backgroundColor: 'white',
    border: '2px solid #BABABA',
    borderRadius: '8px',

  }),
  menu: (provided, state) => ({
    width: '16.5vw',
  }),
  singleValue: (provided, state) => ({
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '23px',
    lineHeight: '23px',
    letterSpacing: '0.03em',
    color: '#757575',
  }),
  singleValueConatiner: (provided, state) => ({
    ...provided,
    flexDirection: 'column',
    justifyContent: 'center',
    transition: '200 ms ease all',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
};

class SelectDropdown extends Component {
    setSelected = (selectedOption) => {
      console.log('se', selectedOption);
      this.props.func(selectedOption.value);
    }

    render() {
      const listOfOptions = [];
      for (let i = 0; i < this.props.optionsList.length; i += 1) {
        listOfOptions.push({ value: i, label: this.props.optionsList[i] });
      }
      console.log('init page type is: ', this.props.init_page_type);
      return (
        <div className="custom-select">
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            value={listOfOptions.filter((option) => option.label === this.props.init_page_type)[0]}
            key={false}
            options={listOfOptions}
            onChange={this.setSelected}
            styles={customStyles}
            isSearchable={false}
          />
        </div>
      );
    }
}

export default SelectDropdown;
