import React from 'react';
import {
  chooseBirdImage, chooseHatImage, choosePlatformImage, chooseAccessoryImage,
} from './Utility';

const Bird = ({
  color, top, fullBody, platform, accessory,
}) => {
  return (
    <div id="store-profile-bird-outer">
      {color !== '' ? <img src={chooseBirdImage(color)} alt="bird-profile" id="bird-profile-image" /> : null}
      {top !== '' ? <img src={chooseHatImage(top)} alt="top" id={`bird-profile-hat-${top}`} className="bird-profile-hat" /> : null}
      {platform !== '' ? <img src={choosePlatformImage(platform)} alt="platform" className="bird-profile-platform" id={`bird-profile-platform-${platform}`} /> : null}
      {accessory !== '' ? <img src={chooseAccessoryImage(accessory)} alt="accessory" className="bird-profile-accessory" id={`bird-profile-platform-${accessory}`} /> : null}
    </div>
  );
};

export default Bird;
