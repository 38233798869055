import React from 'react';
import NewNavBar from '../../../cs98_components/NewNavBar';
import WarblerCoin from '../../../../../images/profile/woin.svg';

const StudentJoinGame = ({
  currentGamePin, error, msg, setTypingGamePin, joinGame, quitGame, xp, coins,
}) => {
  if (xp !== undefined && coins !== undefined) {
    return (
      <div className="ic-game-flex-col">
        <NewNavBar
          title="In-class"
          items={[
            {
              text: `${xp} XP`,
            },
            {
              text: `${coins}`,
              icon: WarblerCoin,
              iconType: 'img',
              link: '/InClassGame',
              iconToRight: true,
              alt: 'woin',
            },
          ]}
          back_button
        />
        <div className="ic-game-flex-col-bottom">
          <h1 id="ic-teacher-title-student">Get ready to play against your friends!</h1>
          <h3 id="ic-student-subtitle">Ask your teacher for a code and enter it here!</h3>
          <div className="ic-student-enter-code">
            <div id="ic-code-title-student">Enter code:</div>
            <div>{error}</div>
            <div>{currentGamePin ? `joined game: ${currentGamePin}` : msg}</div>
            <input id="ic-student-enter-code-input" placeholder="type pin" onInput={(e) => { setTypingGamePin(e.target.value); }} />
            <button className="blue button" id="blue-button-new-width-student" type="button" onClick={() => { joinGame(); }}>Join game</button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="ic-game-flex-col">
        <NewNavBar
          title="In-class"
          items={[]}
          back_button
        />
        <div className="ic-game-flex-col-bottom">
          <h1 id="ic-teacher-title-student">Get ready to play against your friends!</h1>
          <h3 id="ic-student-subtitle">Ask your teacher for a code and enter it here!</h3>
          <div className="ic-student-enter-code">
            <div id="ic-code-title-student">Enter code:</div>
            <div>{error}</div>
            <div>{currentGamePin ? `joined game: ${currentGamePin}` : msg}</div>
            <input id="ic-student-enter-code-input" placeholder="type pin" onInput={(e) => { setTypingGamePin(e.target.value); }} />
            <button className="blue button" id="blue-button-new-width-student" type="button" onClick={() => { joinGame(); }}>Join game</button>
          </div>
        </div>
      </div>
    );
  }
};

export default StudentJoinGame;
