/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import Select from 'react-select';
import DropdownIndicator from './dropdownIndicator';

const validClefs = ['treble', 'bass', 'alto', 'soprano', 'mezzo-soprano'];

const customStyles = {

  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#DEDCDC',
    width: '139px',
    height: '37px',
  }),

  singleValue: (provided, state) => ({
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '23px',
    lineHeight: '23px',
    letterSpacing: '0.03em',
    color: '#757575',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '187px',
    height: '66px',
  }),
  menuList: (provided, state) => ({
    ...provided,
    // height: '110px',
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '23px',
    lineHeight: '23px',
    letterSpacing: '0.03em',
    color: '#757575',
    paddingTop: '0px',
    paddingBottom: '0px',
    boxSizing: 'border-box',
    width: '187px',
    height: '66px',
  }),
  option: (provided, state) => ({
    ...provided,
    borderTop: '1px solid #DEDCDC',
    borderBottom: '1px solid #DEDCDC',
    boxSizing: 'border-box',
    backgroundColor: '#F3F3F3',
    color: '#757575',
  }),
};

class ClefSelect extends Component {
    setSelected = (selectedOption) => {
      this.props.func(selectedOption.label);
      this.props.handleChange();
    }

    makeOptions = () => {
      const toReturn = [];
      for (let i = 0; i < validClefs.length; i += 1) {
        toReturn.push({ value: i, label: validClefs[i] });
      }
      return toReturn;
    }

    render() {
      const options = this.makeOptions();
      return (
        <div className="clef-select">
          <Select
            className="react-select-container-clef"
            classNamePrefix="react-select"
            key={false}
            styles={customStyles}
            components={{ DropdownIndicator }}
            options={options}
            value={options.filter((option) => option.label === this.props.clef)}
            onChange={this.setSelected}
            isSearchable={false}
          />
        </div>
      );
    }
}

export default ClefSelect;
