/* eslint-disable eqeqeq */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import KeySignatureSelect from './KeySignatureSelect';
import ClefSelect from './ClefSelect';

class SingingQuestion extends Component {
  constructor(props) {
    super(props);
    if (this.props.pageInfo !== undefined && this.props.pageInfo !== null && this.props.pageInfo.info !== null && this.props.pageInfo.info !== undefined) {
      this.state = {
        keySig: this.props.pageInfo.info.p.key_signature,
        cleftype: this.props.pageInfo.info.p.cleftype,
      };
    } else {
      this.state = {
        keySig: 'C',
        cleftype: 'treble',
      };
    }
  }

  handleChange = () => {
    this.props.saveLessonFunc(false, true, null, false);
  };

  toRenderInputs = () => {
    const toRender = [];
    if (this.props.pageInfo !== undefined && this.props.pageInfo !== null && this.props.pageInfo.info !== null && this.props.pageInfo.info !== undefined) {
      for (let i = 0; i < 2; i += 1) {
        const id = `pm-note-input-${i}`;
        toRender.push(
          <div className="answer-input-box-container" key={i}>
            <div className="note-title">Note {i + 1}: </div>
            <input className="sr-note-input-box pm-note-input-boxes" key={i} id={id} defaultValue={this.props.pageInfo.info.p.correct_answers[i]} onBlur={this.handleChange} />
          </div>,
        );
      }
    } else {
      for (let i = 0; i < 2; i += 1) {
        const id = `pm-note-input-${i}`;
        toRender.push(
          <div className="answer-input-box-container" key={i}>
            <div className="note-title">Note {i + 1}: </div>
            <input className="sr-note-input-box pm-note-input-boxes" key={i} id={id} onBlur={this.handleChange} />
          </div>,
        );
      }
    }
    return toRender;
  }

  updateKeySig = (ks) => {
    this.setState({ keySig: ks });
  }

  updateClef = (clef) => {
    this.setState({ cleftype: clef });
  }

  render() {
    return (
      <div className="nlc-question-main">
        <div className="nlc-question-selects">
          <div className="select-container">
            <div className="select-title">Key signature: </div>
            <KeySignatureSelect ks={this.state.keySig} func={this.updateKeySig} handleChange={this.handleChange} />
          </div>
          <div className="select-container">
            <div className="select-title">Clef type: </div>
            <ClefSelect clef={this.state.cleftype} func={this.updateClef} handleChange={this.handleChange} />
          </div>
        </div>
        <div className="nlc-entry-inputs-flex">
          {this.toRenderInputs()}
        </div>
        <input type="hidden" className="id-hidden-input" value={this.props.pageInfo._id} />
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    lessonid: reduxState.lessonMaking.lessonid,
    pageNumber: reduxState.lessonMaking.pageNumber,
  };
}
export default connect(mapStateToProps)(SingingQuestion);
