/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CommunitySelect from './CommunitySelect';
import {
  getLessons, getLesson, getRandomLesson, registerLessonAttempt, addPublishedLessonToMyClass, getUserInfo,
} from '../../actions';
import insertChart from '../svg/insert_chart.svg';
import libraryAdd from '../svg/library_add.svg';
import Lesson from './Lesson';
import blackX from '../../../images/classroom/black-x-modal.svg';
import NewNavBar from '../cs98_components/NewNavBar';

function mapStateToProps(reduxState) {
  return {
    lesson: reduxState.lesson,
    user: reduxState.user,
  };
}

class Community extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewing: false,
      adding: false,
      lesson: null,
      preview: false,
      selectedClass: null,
      successAdding: false,
      label: null,
    };
  }

  setSelectedClass = (selectedClass, label) => {
    this.setState({ selectedClass, label });
  }

  componentDidMount = () => {
    this.props.getLessons();
    if (this.props.lesson === undefined || this.props.lesson === null || this.props.lesson.lessonids.length === 0) {
      this.props.getLessons();
    }

    if (this.props.user.username === null) {
      this.props.getUserInfo();
    }
  }

  goBack = () => {
    console.log('go back called');
    const { history } = this.props;
    history.push('/userhome');
  }

  viewStats = (lesson) => {
    console.log('view stats');
    this.setState({ viewing: true, lesson });
  }

  addLesson = (lesson) => {
    // if (this.state.selectedClass !== null) {
    console.log('add lesson called');
    this.setState({ adding: true, lesson });
    // }
  }

  confirmAddLesson = (lesson) => {
    console.log('adding lesson');
    this.setState({ adding: false, viewing: false, successAdding: true });
    this.props.addPublishedLessonToMyClass(this.state.selectedClass._id, lesson._id);
  }

  preview = () => {
    this.props.getLesson(this.state.lesson._id, this.props.history, false);
    this.setState({ viewing: false, preview: true });
  }

  exitPreview = () => {
    this.setState({ viewing: true, preview: false });
  }

  toggleDropDown = () => {
    this.setState((prevState) => {
      return ({
        ddopen: !prevState.ddopen,
      });
    });
  }

  renderCommunityDropDown = () => {
    const validClasses = [];
    this.props.user.classes.forEach((c) => {
      if (!c.lessons.includes(this.state.lesson._id)) {
        validClasses.push(c);
      }
    });

    let { selectedClass } = this.state;
    if (selectedClass === null && validClasses.length > 0) {
      this.setState({ selectedClass: validClasses[0] });
      selectedClass = validClasses[0];
    }

    console.log('valid classes', validClasses);
    if (validClasses.length === 0) {
      return (
        <div className="community-modal-adding">
          <div className="none-available">
            <div className="community-dropdown-selected" style={{ width: '100%' }}>
              No classes available
            </div>
            <br />
            <div>
              Looks like you&apos;re already using this lesson in all of your classes!
            </div>
          </div>
        </div>
      );
    // } else if (this.state.ddopen) {
    } else {
      return (
        <div className="community-modal-adding">
          {/* <div className="community-dropdown">
            <div className="community-dropdown-selected" onClick={this.toggleDropDown}>
              {selectedClass.name} <i className="fas fa-chevron-up" />
            </div>
            <div className="community-dropdown-bottom">
              {validClasses.map((classInfo) => {
                return (
                  <div className="community-dropdown-el" onClick={() => { this.toggleDropDown(); this.setState({ selectedClass: classInfo }); }}>
                    {classInfo.name}
                  </div>
                );
              })}
            </div>
          </div> */}
          <CommunitySelect community={this.state.label === null ? validClasses[0] : this.state.label} classes={validClasses} func={this.setSelectedClass} />
          <div className="lesson-complete-try"
            style={{ background: '#FFC300', width: '240px' }}
            onClick={() => { this.confirmAddLesson(this.state.lesson); }}
          >
            Add
          </div>
        </div>
      );
    }
    // } else {
    //   return (
    //     <div className="community-modal-adding">
    //       <div className="community-dropdown">
    //         <div className="community-dropdown-selected" onClick={this.toggleDropDown}>
    //           {selectedClass.name} <i className="fas fa-chevron-down" />
    //         </div>
    //       </div>
    //       <div className="lesson-complete-try"
    //         style={{ background: '#FFC300', width: '240px' }}
    //         onClick={() => { this.confirmAddLesson(this.state.lesson); }}
    //       >
    //         Add
    //       </div>
    //     </div>
    //   );
    // }
  }

  renderInfoModal = () => {
    console.log('in info modal');
    if (this.state.lesson === null) {
      return (
        <div />
      );
    } else if (this.state.adding) {
      return (
        <div className="community-modal">
          <div className="community-modal-body" style={{ padding: '3vh 0px', justifyContent: 'flex-start' }}>
            <div className="community-adding-text">Where would you like to add this?</div>
            {this.renderCommunityDropDown()}
            <button type="button" className="community-modal-button-new cm-red" onClick={() => { this.setState({ adding: false }); }}>
              Back
            </button>
          </div>
        </div>
      );
    } else if (this.state.viewing) {
      let percent = ((this.state.lesson.completed_count / this.state.lesson.attempt_count) * 100).toFixed(0).toString().concat('%');
      console.log('VIEWING attempt_count: ', this.state.lesson.attempt_count);
      if (this.state.lesson.attempt_count === 0 || this.state.lesson.attempt_count === null || this.state.lesson.attempt_count === undefined) {
        percent = 'n/a';
      }
      return (
        <div className="community-modal">
          <div className="community-modal-body">
            <div className="community-modal-x-out"><img onClick={() => { this.setState({ viewing: false }); }} src={blackX} alt="black x to exit" className="community-modal-x-out-image" /></div>
            <div className="community-modal-title">
              {this.state.lesson.title}
            </div>
            <br />
            <div className="community-modal-bottom">
              <div className="community-modal-stat">
                <div className="community-modal-stat-number stat-number-purple">{this.state.lesson.completed_count}</div>
                <div className="community-modal-stat-title">total completions</div>
              </div>
              <div className="community-modal-stat">
                <div className="community-modal-stat-number stat-number-red">{percent}</div>
                <div className="community-modal-stat-title">completion rate</div>
              </div>
              <div className="community-modal-stat">
                <div className="community-modal-stat-number stat-number-green">{this.state.lesson.num_classes_added}</div>
                <div className="community-modal-stat-title">classrooms that have added this lesson</div>
              </div>
            </div>

            <div className="community-modal-buttons">
              <button className="community-modal-button-new cm-lightblue" onClick={this.preview} type="button">
                Try it
              </button>
              <button className="community-modal-button-new cm-darkblue" type="button" onClick={() => { this.setState({ adding: true }); }}>
                Add to class
              </button>
            </div>
          </div>
        </div>
      );
    } else if (this.state.successAdding) {
      return (
        <div className="community-modal">
          <div className="community-modal-body">
            <div className="adding-success">
              Success! Added <span style={{ color: '#FFC300' }}>{this.state.lesson.title}</span> to <span style={{ color: '#1CB0F6' }}>{this.state.selectedClass.name}</span>!
            </div>
            <br />
            <button type="button" className="community-modal-button-new cm-red" onClick={() => { this.setState({ successAdding: false, viewing: false }); }}>
              Close
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div />
      );
    }
  }

  renderLessons = () => {
    if (this.props.lesson !== undefined && this.props.lesson.lessonids !== undefined && this.props.lesson.lessonids !== null) {
      let noLessons = true;
      return (
        <div className="community-lessons">{
                this.props.lesson.lessonids.map((lesson) => {
                  let date = 'unknown';
                  let time = 'unknown';
                  if (lesson.createdAt !== undefined && lesson.createdAt !== null) {
                    // eslint-disable-next-line no-unused-vars
                    [date, time] = lesson.createdAt.split('T');
                  }
                  if (!lesson.publish) {
                    return (<div />);
                  }
                  noLessons = false;
                  return (
                    <div className="community-lesson" key={lesson._id}>
                      <div className="community-lesson-icons">
                        <img id="community-lesson-icon" src={insertChart} alt="chart" onClick={() => { this.viewStats(lesson); }} />
                        <img id="community-lesson-icon" src={libraryAdd} alt="add" onClick={() => { this.addLesson(lesson); }} />
                      </div>
                      <div className="community-lesson-title">
                        Title: {lesson.title}
                      </div>
                      <div className="community-lesson-subtitle">
                        Author: {lesson.author_id}
                      </div>
                      <div className="community-lesson-subtitle">
                        Created: {date}
                      </div>
                    </div>
                  );
                })
            }
          { noLessons
            && (
            <div>
              No Community lessons yet :( Why not make the first?
            </div>
            )}
        </div>
      );
    } else {
      return (
        <div>Loading...</div>
      );
    }
  }

  render() {
    if (this.state.preview) {
      return (
        <div>
          <div className="lesson-complete-exit-holder-fixed">
            <div className="lesson-complete-exit" onClick={this.exitPreview} style={{ margin: '1vh 1vh' }}>
              Exit Preview
            </div>
          </div>
          <Lesson type="preview" />
        </div>
      );
    } else {
      return (
        <div>
          {this.renderInfoModal()}
          <NewNavBar items={[
            {
              text: 'Sign Out',
              link: '/home',
              icon: 'fas fa-sign-out-alt',
            },
          ]}
            back_button
            title="Warbler Community"
          />
          <div className="community-holder">
            <div className="community-body">
              {this.renderLessons()}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(connect(mapStateToProps, {
  getLessons, getLesson, getRandomLesson, registerLessonAttempt, addPublishedLessonToMyClass, getUserInfo,
})(Community));
