/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import insertChart from '../../svg/insert_chart.svg';
import {
  saveLesson, getClass, getLesson, getLessonToEdit, setPublish,
} from '../../../actions/index';
import blackX from '../../../../images/classroom/black-x-modal.svg';

class ClassroomPageTeacherLessonView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // selected_lesson: 'Rhythm',
      lesson: null,
      showLessonModal: false,
      completeStudents: [],
      incompleteStudents: [],
      // firstPublish: true,
    };
  }

  componentDidMount() {
    this.props.getClass(this.props.class.code);
  }

  goToLC = () => {
    this.props.history.push(`/createlesson/${this.props.class.code}`);
  }

  renderPublishButton = () => {
    console.log('authorid', this.state.lesson.author_id, 'userid', this.props.user.id);
    if (this.state.lesson.author_id === this.props.user.id) {
      if (!this.state.lesson.publish) {
        return (
          <div className="lesson-complete-try-holder" style={{ background: '#51830c', width: '280px' }}>
            <div className="lesson-complete-try" style={{ background: '#7AC70C', width: '272px' }} onClick={() => this.publish(true)}>
              Publish
            </div>
          </div>
        );
      } else {
        return (
          <div className="lesson-complete-try-holder" style={{ background: '#51830c', width: '280px' }}>
            <div className="lesson-complete-try" style={{ background: '#7AC70C', width: '272px' }} onClick={() => this.publish(false)}>
              UnPublish
            </div>
          </div>
        );
      }
    } else {
      return (
        <span />
      );
    }
  }

  publish = (a) => {
    console.log('publishing');
    console.log(this.state.lesson._id);
    this.props.setPublish(this.state.lesson._id, a);
    this.props.setPublish(this.state.lesson._id, a);
    // if (this.state.firstPublish) {
    //   this.props.setPublish(this.state.lesson._id, a);
    //   this.setState({ firstPublish: false });
    // }
    // Update that specific lesson in the redux state.
    this.setState({ showLessonModal: false });
    this.props.getClass(this.props.class.code);
  }

  edit = () => {
    console.log('getting lesson to edit', this.state.lesson._id, this.props.history, this.props.class.code);
    this.props.getLessonToEdit(this.state.lesson._id, this.props.history, this.props.class.code);
  }

  renderEditButton = () => {
    if (this.state.lesson.author_id === this.props.user.id) {
      return (
        <div className="lesson-complete-try-holder" style={{ background: '#1c7494', width: '280px' }}>
          <div className="lesson-complete-try" style={{ background: '#2BA0CC', width: '272px' }} onClick={this.edit}>
            Edit
          </div>
        </div>
      );
    } else {
      return (
        <div />
      );
    }
  }

  renderLessonModal = () => {
    if (this.state.showLessonModal) {
      let { completeStudents } = this.state;
      let { incompleteStudents } = this.state;
      if (this.state.completeStudents === null || this.state.incompleteStudents === null || completeStudents.length + incompleteStudents < this.props.class.students.length) {
        const complete = [];
        const incomplete = [];
        this.props.class.students.map((student) => {
          if (student.completed_lessons.includes(this.state.lesson._id)) {
            complete.push(student);
          } else {
            incomplete.push(student);
          }
        });
        this.setState({ completeStudents: complete, incompleteStudents: incomplete });
        completeStudents = complete;
        incompleteStudents = incomplete;
      }
      console.log('incomplete/complete', incompleteStudents, completeStudents);

      // let percent = ((completeStudents.length / (this.props.class.students.length)) * 100).toFixed(0).toString().concat('%');
      // if (this.props.class.students.length === 0) {
      //   percent = 'n/a';
      // }
      return (
        <div className="lesson-modal">
          <div className="community-modal-body" id="lesson-modal-body">
            <div className="community-modal-x-out"><img onClick={() => { this.setState({ showLessonModal: false }); }}
              src={blackX}
              alt="black x to exit"
              className="community-modal-x-out-image"
            />
            </div>
            <div className="community-modal-title">
              {this.state.lesson.title}
            </div>
            <br />
            <div className="community-modal-bottom">
              <div className="community-modal-stat">
                <div className="community-modal-stat-number stat-number-purple">{this.state.lesson.completed_count}</div>
                <div className="community-modal-stat-title">total completions</div>
              </div>
              <div className="community-modal-stat">
                <div className="community-modal-stat-number stat-number-red">{completeStudents.length}</div>
                <div className="community-modal-stat-title">students in this class completed</div>
              </div>
              {/* <div className="community-modal-stat">
                <div className="community-modal-stat-number">{percent}</div>
                <div className="community-modal-stat-title">% of your students completed</div>
              </div> */}
              <div className="community-modal-stat">
                <div className="community-modal-stat-number stat-number-green">{this.state.lesson.num_classes_added}</div>
                <div className="community-modal-stat-title">classrooms have added this lesson</div>
              </div>
            </div>
            <div className="lesson-modal-class-stats">
              <div className="lmcs-title">Your Students: </div>
              <div className="lesson-modal-students-section">
                <div className="lesson-modal-students" id="lesson-complete">
                  <div className="lesson-modal-complete-title stat-number-green">
                    Completed:
                  </div>
                  {completeStudents.map((student) => {
                    return (
                      <div className="lesson-modal-student-new" id="lesson-modal-student-complete">{student.username}</div>
                    );
                  })}
                </div>
                <div className="lesson-modal-students" id="lesson-incomplete">
                  <div className="lesson-modal-complete-title stat-number-red">
                    Incomplete:
                  </div>
                  {incompleteStudents.map((student) => {
                    return (
                      <div className="lesson-modal-student-new" id="lesson-modal-student-incomplete">{student.username}</div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="lesson-modal-flex-button">
              <button type="button" className="community-modal-button-new cm-lightblue" onClick={this.preview}>
                Play
              </button>
              {this.renderPublishButton()}
              {this.renderEditButton()}
            </div>
          </div>
        </div>
      );
    } else {
      return (<div />);
    }
  }

  preview = () => {
    console.log('previewing!');
    this.props.getLesson(this.state.lesson._id, this.props.history, false);
    this.props.previewLesson();
  }

  viewStats = (lesson) => {
    this.setState({ showLessonModal: true, lesson });
  }

  renderPublished = (bool) => {
    if (bool) {
      return (
        <span style={{ color: '#7AC70C' }}>published</span>
      );
    } else {
      return (
        <span style={{ color: '#D33131' }}>unpublished</span>
      );
    }
  }

  renderLessons = () => {
    if (this.props.class.lessons.length > 0) {
      return (
        <div className="lesson-list">
          {this.props.lessons.map((lesson) => {
            const date = lesson.createdAt.split('T')[0];

            return (
            // <div className="example-lesson-container" key={lesson.num} index={lesson.num} role="tab" onClick={lesson.goToLesson} tabIndex={0}>
            //   <span className="lesson-title">{lesson.title}</span>
            //   <span className="num-students-finished">This is not anything yet</span>
            //   <div className="community-lesson-icons">
            //     <img id="community-lesson-icon" src={insertChart} alt="chart" onClick={() => { this.viewStats(lesson); }} />
            //   </div>
            // </div>
              <div className="community-lesson" key={lesson._id} id="lesson-list-lesson">
                <div className="community-lesson-icons">
                  <img id="community-lesson-icon" src={insertChart} alt="chart" onClick={() => { this.viewStats(lesson); }} />
                </div>
                <div className="community-lesson-title">
                  Title: {lesson.title}
                </div>
                <div className="community-lesson-subtitle">
                  Author: {lesson.author_name}
                </div>
                <div className="community-lesson-subtitle">
                  Created: {date}
                </div>
                <div className="community-lesson-subtitle">
                  {this.renderPublished(lesson.publish)}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="no-lessons">
          <div id="no-lessons" style={{ fontSize: '2.5em', fontWeight: 'bold' }}>This class has no lessons <i className="fas fa-frown-open" /> </div>
          <div id="no-lessons" style={{ fontSize: '2em' }}> Either create one or go to the Warbler community to add the first lesson to this class!</div>
        </div>
      );
    }
  }

  render() {
    console.log('rendering ', this.props.lessons);
    return (
      <div className="teacher-lessons-view-container">
        {/*
          <div className="top-stuff" />
          {this.renderLessonList()} */}
        {this.renderLessonModal()}
        <div className="top-stuff">
          {/* <div className="left-dropdown">
              <span className="lesson-type-dropdown-name">{this.state.selected_lesson}</span>
              <i className="fas fa-chevron-down" />
            </div>
            <div className="right-buttons">
              <button type="button" className="edit-button">Edit</button>
              <button type="button" className="create-lesson-button invite-students-button" onClick={() => this.goToLC()}> Create Lesson</button>
            </div> */}
        </div>
        {this.renderLessons()}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    user: reduxState.user,
    class: reduxState.class,
    lessons: reduxState.class.lessons,
  };
}

export default withRouter(connect(mapStateToProps, {
  saveLesson, getClass, getLesson, getLessonToEdit, setPublish,
})(ClassroomPageTeacherLessonView));
