/* eslint-disable no-case-declarations */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LCNav from './LCNav';
import LCInterface from './LCInterface';
import Lesson from '../Lesson';
import {
  saveLesson, mapPagesToState, getUserInfo, getClass, changeCreateorPreview,
} from '../../../actions';
import { checkValidInput } from '../LetterType';
import { isValidDuration } from '../lesson_creation_components/question_creation/InputVerification';
import NewNavBar from '../../cs98_components/NewNavBar';

class LCTopLevel extends Component {
  componentDidMount() {
    if (this.props.user.username === null) {
      this.props.getUserInfo();
    }
    if (this.props.class.students === null) {
      this.props.getClass(window.location.pathname.split('/')[2]);
    }
  }

  saveLesson = (publish = false, sendReq = true, preview = null, toasty = true) => {
    let error = false;
    const pagesToAdd = [];
    const pages = document.getElementsByClassName('lc-individual-page-container');
    for (let page = 0; page < pages.length; page += 1) {
      const pageType = pages[page].getElementsByClassName('react-select__single-value')[0].innerText;
      let pageInfo;
      switch (pageType) {
        // GETTING CONTENT SENSING PAGE INFO
        case 'Content':
          pageInfo = {
            page_type: 'Content',
            info: {
              title: pages[page].querySelector('input.page-description-text').value,
              description: pages[page].querySelector('textarea.content-description-input').value,
            },
            _id: pages[page].querySelector('input.id-hidden-input').value,
          };
          console.log('content page info', pageInfo);
          pagesToAdd.push(pageInfo);
          break;
        // GETTING RHYTHM SENSING PAGE INFO
        case 'Rhythm-Sensing':
          const tsSelect = pages[page].getElementsByClassName('react-select-container-ts')[0];
          const chosenTS = tsSelect.getElementsByClassName('react-select__single-value')[0].innerText;
          const bpmSelect = pages[page].getElementsByClassName('react-select-container-bpm')[0];
          const chosenBPMText = bpmSelect.getElementsByClassName('react-select__single-value')[0].innerText;
          const chosenBPM = Number(chosenBPMText.split(' ')[0]);
          console.log('chosen BPM is: ', chosenBPM);
          console.log(tsSelect.getElementsByClassName('react-select__single-value')[0].innerText);
          console.log(pages[page].getElementsByClassName('react-select-container-bpm'));
          const chosen = [];
          for (let i = 0; i < pages[page].querySelectorAll('div.note-pick-container').length; i += 1) {
            const toGet = `note-pick-id-${i}`;
            // if (checkValidInput(pages[page].querySelector(`#${toGet}`).value)) {
            //   chosen.push(pages[page].querySelector(`#${toGet}`).value);
            //   // chosen.push(document.getElementById(toGet).value);
            //   pages[page].querySelector(`#${toGet}`).style.borderColor = 'black';
            const dur = pages[page].querySelector(`#${toGet}`).value.toLowerCase();
            if (isValidDuration(dur)) {
              console.log(dur);
              chosen.push(dur);
              // chosen.push(document.getElementById(toGet).value);
              pages[page].querySelector(`#${toGet}`).style.border = 'solid 3px rgb(118, 118, 118)';
              pages[page].querySelector(`#${toGet}`).style.borderRadius = '3px';
            } else {
              pages[page].querySelector(`#${toGet}`).style.border = 'solid 3px red';
              pages[page].querySelector(`#${toGet}`).style.borderRadius = '3px';
              error = true;
            }
          }

          pageInfo = {
            page_type: 'activity',
            info: {
              activity_type: 'Rhythm-Sensing',
              r: {
                instructions: pages[page].querySelector('input.page-description-text').value,
                cleftype: 'treble',
                bpm: chosenBPM,
                time_signature: chosenTS,
                notes: chosen,
                answer_count: chosen.length,
              },
            },
            _id: pages[page].querySelector('input.id-hidden-input').value,
          };
          console.log('pageInfoR', pageInfo);
          pagesToAdd.push(pageInfo);
          break;
        case 'Listening-Intervals':
          console.log('saving listening-intervals');
          const toReturn = {
            intervals: [],
            answers: [],
          };
          const intervalContainers = pages[page].getElementsByClassName('big-answer-input-box-container');
          console.log(intervalContainers);
          for (let i = 0; i < intervalContainers.length; i += 1) {
            const interval = [];
            if (checkValidInput(intervalContainers[i].getElementsByClassName('first-note')[0].value)) {
              interval.push(intervalContainers[i].getElementsByClassName('first-note')[0].value); // need in C/4 format
              intervalContainers[i].getElementsByClassName('first-note')[0].style.border = 'solid 2px rgb(118, 118, 118)';
              intervalContainers[i].getElementsByClassName('first-note')[0].style.borderRadius = '3px';
            } else {
              intervalContainers[i].getElementsByClassName('first-note')[0].style.border = 'solid 1px red';
              intervalContainers[i].getElementsByClassName('first-note')[0].style.borderRadius = '3px';
              error = true;
            }

            if (checkValidInput(intervalContainers[i].getElementsByClassName('second-note')[0].value)) {
              interval.push(intervalContainers[i].getElementsByClassName('second-note')[0].value);
              intervalContainers[i].getElementsByClassName('second-note')[0].style.border = 'solid 2px rgb(118, 118, 118)';
              intervalContainers[i].getElementsByClassName('second-note')[0].style.borderRadius = '3px';
            } else {
              intervalContainers[i].getElementsByClassName('second-note')[0].style.border = 'solid 1px red';
              intervalContainers[i].getElementsByClassName('second-note')[0].style.borderRadius = '3px';
              error = true;
            }

            if (interval.length === 2) toReturn.intervals.push(interval);

            if (intervalContainers[i].querySelector('.yes-or-no-input').checked === true) {
              console.log('interval ', i, 'was corrent');
              toReturn.answers.push(true);
            } else {
              console.log(intervalContainers[i].querySelector('.yes-or-no-input').checked);
              toReturn.answers.push(false);
            }
          }

          console.log('toReturn is:', toReturn);

          pageInfo = {
            page_type: 'activity',
            info: {
              activity_type: 'Listening-Intervals',
              i: {
                instructions: pages[page].querySelector('input.page-description-text').value,
                intervals: toReturn.intervals,
                answers: toReturn.answers,
                interval_text: pages[page].querySelector('input.interval-to-test').value,
              },
            },
            _id: pages[page].querySelector('input.id-hidden-input').value,
          };
          console.log('pageInfo', pageInfo);
          pagesToAdd.push(pageInfo);
          break;
        case 'Listening-Multiple-Choice':
          const ksSelectLCM = pages[page].getElementsByClassName('react-select-container-ks')[0];
          const chosenKSLCM = ksSelectLCM.getElementsByClassName('react-select__single-value')[0].innerText;
          const clefSelectLCM = pages[page].getElementsByClassName('react-select-container-clef')[0];
          const chosenClefLCM = clefSelectLCM.getElementsByClassName('react-select__single-value')[0].innerText;

          const options = [];
          const listeningMcContainers = pages[page].getElementsByClassName('listening-mc-answer-input-box-container');
          console.log(listeningMcContainers);
          for (let i = 0; i < listeningMcContainers.length; i += 1) {
            const option = [];
            const information = listeningMcContainers[i].getElementsByClassName('lmc-input-box');
            // const information = pages[page].querySelector(`#answer-input-box-${i + 1}`).getElementsByTagName('input');
            // const information = document.getElementById(`answer-input-box-${i + 1}`).getElementsByTagName('input');
            console.log(information);
            for (let j = 0; j < information.length; j += 1) {
              console.log(document.getElementById(`input-lmc-${i + 1}-${j + 1}`).value);
              if (checkValidInput(document.getElementById(`input-lmc-${i + 1}-${j + 1}`).value)) {
                option.push(document.getElementById(`input-lmc-${i + 1}-${j + 1}`).value);
                document.getElementById(`input-lmc-${i + 1}-${j + 1}`).style.border = 'solid 3px rgb(118, 118, 118)'; // if previously wrong, change back to correct
                document.getElementById(`input-lmc-${i + 1}-${j + 1}`).style.borderRadius = '3px';
              } else {
                // have some sort of error flag that doesn't allow the lesson to be saved??
                document.getElementById(`input-lmc-${i + 1}-${j + 1}`).style.border = 'solid 3px red';
                document.getElementById(`input-lmc-${i + 1}-${j + 1}`).style.borderRadius = '3px';
                error = true;
              }
            }
            options.push(option);
          }

          pageInfo = {
            page_type: 'activity',
            info: {
              activity_type: 'Listening-Multiple-Choice',
              mc: {
                instructions: pages[page].querySelector('input.page-description-text').value,
                options,
                key_signature: chosenKSLCM,
                cleftype: chosenClefLCM,
                correct_answer: 0, // from sathvi: noticed this was off by one
              },
            },
            _id: pages[page].querySelector('input.id-hidden-input').value,
          };
          console.log(pageInfo);
          pagesToAdd.push(pageInfo);
          break;
        case 'Pitch-Matching':
          const ksSelectPM = pages[page].getElementsByClassName('react-select-container-ks')[0];
          const chosenKSPM = ksSelectPM.getElementsByClassName('react-select__single-value')[0].innerText;
          const clefSelectPM = pages[page].getElementsByClassName('react-select-container-clef')[0];
          const chosenClefPM = clefSelectPM.getElementsByClassName('react-select__single-value')[0].innerText;

          const inpBoxes = pages[page].getElementsByClassName('pm-note-input-boxes');
          const correctAnswers = [];
          for (let i = 0; i < inpBoxes.length; i += 1) {
            if (checkValidInput(inpBoxes[i].value)) {
              correctAnswers.push(inpBoxes[i].value); // need C/4 format
              inpBoxes[i].style.border = 'solid 3px rgb(118, 118, 118)';
              inpBoxes[i].style.borderRadius = '3px';
            } else {
              inpBoxes[i].style.border = 'solid 3px red';
              inpBoxes[i].style.borderRadius = '3px';
              error = true;
            }
          }
          pageInfo = {
            page_type: 'activity',
            info: {
              activity_type: 'Pitch-Matching',
              p: {
                instructions: pages[page].querySelector('input.page-description-text').value,
                cleftype: chosenClefPM,
                key_signature: chosenKSPM,
                correct_answers: correctAnswers,
              },
            },
            _id: pages[page].querySelector('input.id-hidden-input').value,
          };
          console.log(pageInfo);
          pagesToAdd.push(pageInfo);
          break;
        case 'Sightreading':
          const ksSelectSR = pages[page].getElementsByClassName('react-select-container-ks')[0];
          const chosenKSSR = ksSelectSR.getElementsByClassName('react-select__single-value')[0].innerText;
          const clefSelectSR = pages[page].getElementsByClassName('react-select-container-clef')[0];
          const chosenClefSR = clefSelectSR.getElementsByClassName('react-select__single-value')[0].innerText;

          const notes = [];
          const srInputs = pages[page].getElementsByClassName('sightreading-answer-input-box-container');
          for (let i = 0; i < srInputs.length; i += 1) {
            if (checkValidInput(srInputs[i].getElementsByClassName('sr-note-input-box')[0].value)) {
              const keys = [];
              keys.push(srInputs[i].getElementsByClassName('sr-note-input-box')[0].value);
              notes.push({
                keys,
                duration: 'w',
              });
              // srInputs[i].getElementsByClassName('sr-note-input-box')[0].style.border = 'solid 3px rgb(118, 118, 118)';
              // srInputs[i].getElementsByClassName('sr-note-input-box')[0].style.borderRadius = '3px';
              // error = true;
            } else { // push empty array if no note and mark red so that they know to put a note
              const keys = [];
              notes.push({
                keys,
                duration: 'w',
              });
              srInputs[i].getElementsByClassName('sr-note-input-box')[0].style.border = 'solid 3px red';
              srInputs[i].getElementsByClassName('sr-note-input-box')[0].style.borderRadius = '3px';
              error = true;
            }
          }
          pageInfo = {
            page_type: 'activity',
            info: {
              activity_type: 'Sightreading',
              sr: {
                instructions: pages[page].querySelector('input.page-description-text').value,
                key_signature: chosenKSSR,
                time_signature: '4/4',
                answer_count: 6,
                cleftype: chosenClefSR,
                correct_answers: notes,
              },
            },
            _id: pages[page].querySelector('input.id-hidden-input').value,
          };
          console.log(pageInfo);
          pagesToAdd.push(pageInfo);
          break;
        default:
          console.log('page type not understood.');
      }
    }
    console.log('pagesToAdd', pagesToAdd);
    // Getting the title input and description.
    const titlePanel = {
      title: document.getElementById('lesson-title-input').value,
      description: document.getElementById('lesson-description-input').value,
    };
    if (sendReq && !error) {
      console.log('NO ERROR');
      if (toasty) {
        toast.info('💾  Lesson saved!', {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      this.props.saveLesson(pagesToAdd, titlePanel, this.props.lessonid, publish, this.props.class._id, this.props.user.id);
    }
    if (!error) {
      this.props.mapPagesToState(pagesToAdd);
    } else if (toasty) {
      toast.error('Error: Invalid Entries', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log('ERROR');
    }

    if (preview !== null && !error) {
      this.props.changeCreateorPreview(preview.id);
      $('.lc-nav-selected').removeClass('lc-nav-selected').addClass('lc-nav-unselected');
      $(preview).removeClass('lc-nav-unselected').addClass('lc-nav-selected');
    }
  }

  render() {
    let toRender = null;
    if (this.props.cOrP === 'Preview') {
      toRender = <Lesson type="preview" />;
    } else {
      toRender = <LCInterface saveLessonFunc={this.saveLesson} />;
    }
    return (
      <div className="lesson-background">
        <NewNavBar items={[
          {
            text: 'Save',
            func: this.saveLesson,
            icon: 'fas fa-save',
          },
          {
            text: 'Publish',
            func: this.saveLesson, // Not done yet, needs to be refined to publish
            icon: 'fas fa-upload',
          },
        ]}
          back_button
          title="Lesson Creation"
        />
        <LCNav saveLessonFunc={this.saveLesson} />
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {toRender}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    cOrP: reduxState.lessonMaking.cOrP,
    lessonid: reduxState.lessonMaking.lessonid,
    class: reduxState.class,
    user: reduxState.user,
  };
}

export default connect(mapStateToProps, {
  saveLesson, mapPagesToState, getUserInfo, getClass, changeCreateorPreview,
})(LCTopLevel);
