/* eslint-disable no-case-declarations */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import $ from 'jquery';
import backarrow from '../../../../images/backarrow.svg';
import { saveLesson, changeCreateorPreview } from '../../../actions';
import 'react-toastify/dist/ReactToastify.css';

class LCNav extends Component {
  changePage = (event) => {
    // this.props.saveLessonFunc(false);
    if (this.props.cOrP === 'Create') {
      console.log('saving');
      this.props.saveLessonFunc(false, true, event.target);
    } else {
      this.props.changeCreateorPreview(event.target.id);
      $('.lc-nav-selected').removeClass('lc-nav-selected').addClass('lc-nav-unselected');
      $(event.target).removeClass('lc-nav-unselected').addClass('lc-nav-selected');
    }
    // document.querySelector('div.lc-nav-selected').classList.remove('lc-nav-selected');
    // event.target.classList.add('lc-nav-selected');
  }

  saveIt = () => {
    this.props.saveLessonFunc(false, true);
  }

  publishIt = () => {
    this.props.saveLessonFunc(true, true);
    // toast.success('😎  Lesson published!', {
    //   position: 'bottom-right',
    //   autoClose: 2000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
  }

  render() {
    return (
      <div className="lesson-creation-nav">
        <div className="lesson-creation-nav-top-half">
          {/* <div className="back-group">
            <div className="arrow-back-button blue-arrow">
              <NavLink to={`/class/${this.props.class.code}`}>
                <FontAwesomeIcon className="back-arrow" icon={faChevronLeft} />
              </NavLink>
            </div>
            <div className="back-word white-word">Back</div>
          </div> */}
          <NavLink to={`/class/${this.props.class.code}`} className="nav-flex-row margin-left-nav">
            <div className="arrow-back-button blue-arrow">
              <img className="back-arrow-img" src={backarrow} alt="back arrow" />
            </div>
            <div className="go-back white">Back</div>
          </NavLink>
          <div className="lesson-creation-nav-buttons">
            <button type="button" className="lesson-creation-nav-save-button" onClick={() => this.saveIt()}>Save</button>
            <button type="button" className="lesson-creation-nav-publish-button" onClick={() => this.publishIt()}>Publish</button>
          </div>
        </div>
        <div className="lesson-creation-nav-bottom-half">
          <div id="Create" className="lesson-creation-nav-page-selectors lesson-creation-nav-create lc-nav-selected" onClick={this.changePage} role="tab" tabIndex={0}>
            Create
          </div>
          <div id="Preview" className="lesson-creation-nav-page-selectors lesson-creation-nav-preview lc-nav-unselected" onClick={this.changePage} role="tab" tabIndex={-1}>
            Preview
          </div>
        </div>
        {/* <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    lessonid: reduxState.lessonMaking.lessonid,
    class: reduxState.class,
    cOrP: reduxState.lessonMaking.cOrP,
  };
}

export default connect(mapStateToProps, { saveLesson, changeCreateorPreview })(LCNav);
