const items = {
  Top: [{
    itemType: 'Top',
    name: 'frog',
    price: 300,
  },
  {
    itemType: 'Top',
    name: 'bow',
    price: 200,
  },
  {
    itemType: 'Top',
    name: 'beanie',
    price: 200,
  }],
  Accessory: [{
    itemType: 'Accessory',
    name: 'mic',
    price: 200,
  }],
  Platform: [{
    itemType: 'Platform',
    name: 'grass',
    price: 200,
  }],
};

export default items;
