/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { signInUser, setError, hideError } from '../../actions/index';
import Loading from './Loading';
import ErrorNotification from './ErrorNotification';
import NewNavBar from '../cs98_components/NewNavBar';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  componentDidUpdate() {
    console.log(this.props.user.username, this.props.authentication);
    if (this.props.user.username !== null && this.props.authentication) {
      console.log('pushing to userhome because username was defined and user information has been received.');
      this.props.history.push('/userhome');
    }
  }

  submit = () => {
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    if (email && password) {
      if (this.props.error.open === true) {
        this.props.hideError();
      }
      const user = {
        email,
        password,
      };
      this.props.signInUser(user, this.props.history);
    } else {
      this.props.setError(700); // Both fields must be filled
    }
  }

  renderLoginButton = () => {
    if (!this.state.loading || this.props.error.messages.length > 0) {
      if (this.state.loading) {
        this.setState({ loading: false });
      }
      return (
        <button className="login-button orange" type="button" onClick={this.submit}>LOGIN</button>
      );
    } else {
      return (
        <div />
      );
    }
  }

  render() {
    return (
      <div className="login-holder">
        <NewNavBar
          renderLogo
          items={[
            {
              text: 'Sign up',
              link: '/newuser',
              icon: 'fas fa-user-plus',
            },
          ]}
          dropdownItems={[
            {
              text: 'Teacher Info',
              link: '/home/teachers',
            },
            {
              text: 'Student Info',
              link: '/home/students',
            },
            {
              text: 'About the Team',
              link: '/about',
            },
          ]}
          dropdownIcon="fas fa-book-open"
        />
        <div className="login-input-section">
          <div className="login-title">Login</div>
          <div className="get-username-container">
            <input className="login-input" type="text" id="email" placeholder="email" />
          </div>
          <div className="get-password-container">
            <input className="login-input" type="password" id="password" placeholder="password" />
          </div>
          <ErrorNotification />
          <div className="login-bottom">
            <Loading loading={this.state.loading} />
            {this.renderLoginButton()}
          </div>
        </div>
        <div className="login-bottom-text">Don&apos;t let a Warbler be better at music than you.</div>
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    authentication: reduxState.auth.authenticated,
    error: reduxState.error,
    user: reduxState.user,
  };
}

export default withRouter(connect(mapStateToProps, { signInUser, setError, hideError })(Login));
