import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserInfo, assignXP, assignCoins } from '../../actions/index';
import ListeningMCQuestion from './HeadToHeadGame/ListeningMCQuestion';
import HeadToHeadSlider from './HeadToHeadGame/HeadToHeadSlider';
import HeadtoHeadIntro from './HeadtoHeadIntro';
import HeadtoHeadLobby from './HeadtoHeadLobby';
import GameOver from './HeadtoHeadGameOver';
import NewNavBar from '../cs98_components/NewNavBar';
import blueBird from '../../../images/coloredBirds/blueBird.svg';
import yellowBird from '../../../images/coloredBirds/yellowBird.svg';
import greenBird from '../../../images/coloredBirds/greenBird.svg';
import tealBird from '../../../images/coloredBirds/tealBird.svg';
import purpleBird from '../../../images/coloredBirds/purpleBird.svg';
import blueSliderBird from '../../../images/blueSliderBird.svg';
import yellowSliderBird from '../../../images/yellowSliderBird.svg';
import wormImage from '../../../images/wormSlider.svg';
import WarblerCoin from '../../../images/profile/woin.svg';
import H2HSocket from './H2HSocket';

// const HOST = 'ws://localhost:3000/';
// const HOST = 'ws://warbler-head-2-head.herokuapp.com/';

class HeadToHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playersInLobbyCount: 0,
      playersInLobby: [],
      playersInGameCount: 0,
      playersColors: {},
      playersScores: {},
      partnerID: null,
      partnerUsername: null,
      inLobby: true, // true when waiting in lobby to be matched up, false once matched up/otherwise
      pairedUp: false, // true when player clicks play button AND is matched up to player, false otherwise
      inGame: false, // true once matched up, false while in lobby or in game
      gameOver: false, // set to true once out of questions (when game ends)
      questions: [],
      questionNum: 0, // increment when we go to the next question
      gameID: null,
      playerInitialized: false,
      heartbeatRate: 1000,
    };
    this.props.getUserInfo();
    // eslint-disable-next-line new-cap
  }

  componentDidMount() {
    // eslint-disable-next-line new-cap
    this.ws = H2HSocket(this.ws || undefined);
    this.waitForSocketConnection(this.ws, () => {
      console.log('Player initialized status: ', this.state.playerInitialized);
      if (this.ws) {
        this.interval = setInterval(this.sendHeartbeat, this.state.heartbeatRate);
        this.ws.onmessage = (event) => {
          const msg = JSON.parse(event.data);
          switch (msg.type) {
            case 'matched_up':
              this.setState({ pairedUp: true });
              this.setState({ inLobby: false });
              this.setState({ inGame: false });
              this.setState({ gameOver: false });
              this.setState({ partnerID: msg.partnerID });
              this.setState({ partnerUsername: msg.partnerUsername });
              break;
            case 'load_questions': {
              this.setState({ questions: JSON.parse(msg.questions), gameID: msg.gameID });
              this.setState({ pairedUp: false });
              this.setState({ inLobby: false });
              this.setState({ inGame: true });
              this.setState({ gameOver: false });
              const initialScores = {};
              initialScores[this.props.user.id] = 0;
              initialScores[this.state.partnerID] = 0;
              this.setState({ playersScores: initialScores });
              break;
            }
            case 'answer_checked':
              console.log('checked answer!', msg.message);
              // let user know the answer was wrong or right
              break;
            case 'update_scores':
              this.setState({ playersScores: this.processPlayersScores(msg.scores) });

              // if both players answered question, show next question -- this isn't reloading the page though
              if (msg.didBothAnswer) {
                if (this.state.questionNum + 1 === this.state.questions.length) {
                  this.setState({ gameOver: true });
                  this.setState({ inGame: false });
                  this.setState({ pairedUp: false });
                  this.setState({ inLobby: false });

                  const scoreDiff = Math.abs(this.state.playersScores[this.props.user.id] - this.state.playersScores[this.state.partnerID]);
                  if (this.state.playersScores[this.props.user.id] > this.state.playersScores[this.state.partnerID]) { // this player won
                    this.props.assignXP(100 * scoreDiff, this.props.user.id);
                    this.props.assignCoins(10 * scoreDiff, this.props.user.id);
                  } else if (this.state.playersScores[this.props.user.id] <= this.state.playersScores[this.state.partnerID]) { // tie or this player lost
                    this.props.assignXP(50, this.props.user.id);
                    this.props.assignCoins(5, this.props.user.id);
                  }
                  // send a finish game message to the backend
                } else if (this.state.questionNum + 1 < this.state.questions.length) {
                  setTimeout(() => {
                    this.setState((prevState) => {
                      return ({ questionNum: prevState.questionNum + 1 });
                    });
                  }, 1500);
                }
              }
              break;
            case 'update_lobby_count':
              this.setState({ playersInLobbyCount: msg.lobbyCount });
              this.setState({ playersInLobby: msg.lobbyPlayers });
              this.setState({ playersInGameCount: msg.inGameCount });
              console.log('In game count was: ', msg.inGameCount);
              this.setState({ playersColors: this.processLobbyPlayersColors(msg.lobbyPlayers) });
              break;
            case 'partner_left':
              this.history.push('/userhome');
              break;
            default:
              console.log('cannot read this message');
          }
        };
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.heartbeatRate !== this.state.heartbeatRate) {
      clearInterval(this.interval);
      this.interval = setInterval(this.sendHeartbeat, this.state.heartbeatRate);
    }
  }

  waitForSocketConnection = (socket, callback) => {
    setTimeout(
      () => {
        if (socket.readyState === 1) {
          console.log('Connection is made');
          if (callback != null) {
            callback();
          }
        } else {
          console.log('wait for connection...');
          this.waitForSocketConnection(socket, callback);
        }
      }, 5,
    ); // wait 5 milisecond for the connection...
  }

  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }

  sendHeartbeat = () => {
    if (this.ws) {
      console.log('sending hearbeat');
      this.ws.send(JSON.stringify({
        type: 'heartbeat',
        clientID: this.props.user.id,
      }));
    }
  }

  processLobbyPlayersColors = (playerMsg) => {
    const playerDict = {};
    for (let i = 0; i < playerMsg.length; i += 2) {
      playerDict[playerMsg[i]] = this.getBirdImage(playerMsg[i + 1]);
    }
    return playerDict;
  }

  processPlayersScores = (scores) => {
    console.log('scores', scores);
    const scoreDict = {};
    for (let i = 0; i < scores.length; i += 2) {
      scoreDict[scores[i]] = scores[i + 1];
    }
    console.log('SCORE DICT', scoreDict);
    return scoreDict;
  }

  getBirdImage = (imgString) => {
    let birdImage = purpleBird;
    switch (imgString) {
      case 'blueBird':
        birdImage = blueBird;
        break;
      case 'yellowBird':
        birdImage = yellowBird;
        break;
      case 'greenBird':
        birdImage = greenBird;
        break;
      case 'tealBird':
        birdImage = tealBird;
        break;
      default:
        birdImage = purpleBird;
    }
    return birdImage;
  }

  // Only send initialize if this is the first time the player has been to this page, not returning after one game.
  initializePlayer = () => {
    if (!this.state.playerInitialized) {
      console.log('initialized', this.state.playersInLobby);
      const playerInitMsg = {
        type: 'player_init',
        clientID: this.props.user.id,
        username: this.props.user.username,
      };
      this.ws.send(JSON.stringify(playerInitMsg));
      this.setState({ playerInitialized: true });
    }
  }

  getAnswerFromActivity = (answerChoice) => {
    console.log('answer choice from h2h: ', answerChoice);
    this.sendAnswer(answerChoice);
  }

  sendAnswer = (answerChoice) => {
    const sendAnswerMsg = {
      type: 'send_answer',
      answer: answerChoice,
      questionNum: this.state.questionNum,
      gameID: this.state.gameID,
      clientID: this.props.user.id,
    };
    this.ws.send(JSON.stringify(sendAnswerMsg));
    console.log('sent answer to head2head server');
  }

  clickedPlay = () => {
    // match up + create game object on server end
    // frontend: go to rules page and show the gray modal
    console.log('clicked play! waiting for a game!');
    const clickedPlayMsg = {
      type: 'clicked_play',
      clientID: this.props.user.id,
    };
    this.ws.send(JSON.stringify(clickedPlayMsg));
  }

  clickedStart = () => {
    console.log('clicked start! waiting for game to start!!');
    const clickedStartMsg = {
      type: 'clicked_start',
      clientID: this.props.user.id,
      partnerID: this.state.partnerID,
    };
    this.ws.send(JSON.stringify(clickedStartMsg));
    this.setState({ inGame: true });
    this.setState({ pairedUp: false });
    this.setState({ inLobby: false });
    this.setState({ gameOver: false });
  }

  joinLobby = () => {
    if (!this.state.playersInLobby.includes(this.props.user.username)) {
      this.initializePlayer();
      console.log('putting player in the lobby');
      const putInLobbyMsg = {
        type: 'join_lobby',
        clientID: this.props.user.id,
      };
      this.ws.send(JSON.stringify(putInLobbyMsg));
    }
  }

  renderInstructions = () => {
    if (this.state.pairedUp && !this.state.inLobby && !this.state.inGame && !this.state.gameOver) {
      return (
        <HeadtoHeadIntro
          clickedStart={this.clickedStart}
          ws={this.ws}
        />
      );
    } else return null;
  }

  goBackHome = () => {
    const { history } = this.props;
    const finishGameMsg = {
      type: 'finished_game',
      clientID: this.props.user.id,
    };
    this.ws.send(JSON.stringify(finishGameMsg));
    this.ws = undefined;
    history.push('/userhome');
  }

  renderInGame = () => {
    if (this.state.inGame && !this.state.inLobby && !this.state.pairedUp && !this.state.gameOver) {
      console.log('You are in game number ', this.state.gameID);
      return (
        <div>
          { (this.state.questions.length !== 0)
            ? <ListeningMCQuestion key={this.state.questionNum} page={this.state.questions[this.state.questionNum]} getAnswer={this.getAnswerFromActivity} />
            : null }
        </div>
      );
    } else if (this.state.gameOver && !this.state.inGame && !this.state.inLobby && !this.state.pairedUp) {
      const wonGame = this.state.playersScores[this.props.user.id] > this.state.playersScores[this.state.partnerID];
      const scoreDiff = Math.abs(this.state.playersScores[this.props.user.id] - this.state.playersScores[this.state.partnerID]);
      return (
        <div>
          <GameOver
            wonGame={wonGame}
            username={this.props.user.username}
            partner={this.state.partnerUsername}
            difference={scoreDiff}
            xpGained={100 * scoreDiff}
            coinsGained={10 * scoreDiff}
            goBackHome={this.goBackHome}
          />
        </div>
      );
    } else return null;
  }

  renderBirdsInLobby = () => {
    if (Object.keys(this.state.playersColors).length > 0) {
      const names = [];
      Object.keys(this.state.playersColors).forEach((key) => {
        names.push(key);
      });

      return (
        names.map((playerName) => {
          return (
            <div className="bird-name-h2h-lobby" key={playerName}>
              <img className="bird-player-h2h-lobby" src={this.state.playersColors[playerName]} alt="birdPlayer" />
              <div className="player-name-h2h-lobby">{playerName}</div>
            </div>
          );
        })
      );
    } else return null;
  }

  renderLobby = () => {
    if (this.ws) {
      if (this.state.inLobby && !this.state.inGame && !this.state.pairedUp && !this.state.gameOver) {
        return (
          <HeadtoHeadLobby
            joinLobby={this.joinLobby}
            clickedPlay={this.clickedPlay}
            playersInLobbyCount={this.state.playersInLobbyCount}
            playersInLobby={this.state.playersInLobby}
            playersInGameCount={this.state.playersInGameCount}
            renderBirdsInLobby={this.renderBirdsInLobby}
            ws={this.ws}
          />
        );
      } else return null;
    } else return null;
  }

  renderBirdsInSlider = () => {
    if (Object.keys(this.state.playersScores).length === 2) {
      const players = [];
      Object.keys(this.state.playersScores).forEach((key) => {
        players.push(key);
      });
      console.log(players);
      return (
        <div className="head-to-head-slider">
          <div className="players-in-h2h-slider">
            {players.map((playerID) => {
              const sliderBirdImage = playerID === players[0] ? blueSliderBird : yellowSliderBird;
              const birdName = playerID === this.props.user.id ? 'YOU' : this.state.partnerUsername;
              const birdNameTextClass = playerID === this.props.user.id ? 'red' : 'black';
              return (
                <div className="player-in-h2h-slider"
                  key={playerID}
                  style={{
                    position: 'relative',
                    left: `${this.state.playersScores[playerID] * (window.screen.width / 7)}px`,
                  }}
                >
                  <img className="bird-player-img-h2h-slider" src={sliderBirdImage} alt="player-bird" />
                  <div className={`${birdNameTextClass}-bird-name-h2h-slider`}>{birdName}</div>
                </div>
              );
            })}
          </div>
          <div className="worm-in-h2h-slider">
            <img className="worm-in-h2h-slider-img" src={wormImage} alt="worming" />
            <div className="worm-in-h2h-slider-label">worm</div>
          </div>
        </div>
      );
    } else return null;
  }

  renderSlider = () => {
    if (!this.state.gameOver) {
      return (
        <div>
          <HeadToHeadSlider
            renderBirdsInSlider={this.renderBirdsInSlider}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }

  renderNavBar = () => {
    if (!this.state.gameOver) {
      return (
        <NewNavBar
          title="Head to Head"
          items={[
            {
              text: '',
              link: '/home',
            },
            {
              text: `${this.props.user.stats.xp} XP`,
            },
            {
              text: `${this.props.user.stats.coins}`,
              icon: WarblerCoin,
              iconType: 'img',
              link: '/HeadToHeadGame',
              iconToRight: true,
            },
          ]}
          back_button
        />
      );
    } else return <div />;
  }

  render() {
    if (this.props.user.id) {
      return (
        <div className="head-to-head-body">
          { this.renderNavBar() }
          { this.renderSlider() }
          { this.renderLobby() }
          { this.renderInstructions() }
          { this.renderInGame() }
        </div>
      );
    } else {
      return (
        <div>Loading</div>
      );
    }
  }
}

function mapStateToProps(reduxState) {
  return {
    user: reduxState.user,
  };
}

export default connect(mapStateToProps, { getUserInfo, assignXP, assignCoins })(HeadToHead);
