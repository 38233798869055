/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import NewNavBar from '../../../cs98_components/NewNavBar';
import StudentListeningMCDisplay from './ActivityDisplays/StudentListeningMCDisplay';
import StudentRhythmDisplay from './ActivityDisplays/StudentRhythmDisplay';
import WarblerCoin from '../../../../../images/profile/woin.svg';

const StudentAnswerQuestion = ({
  guessAnswer, answerNumber, questionType, currentQuestion, submitAnswerRhythm, xp, coins,
}) => {
  console.log(currentQuestion, 'currentQuestion');
  const mql = window.matchMedia('(max-width: 900px)');
  const mobileView = mql.matches;

  if (questionType === undefined || questionType === null) {
    return (
      <div />
    );
  }

  if (xp !== undefined && coins !== undefined) {
    return (
      <div className="ic-student-answer-flex">
        <NewNavBar
          title="In-class"
          items={[
            {
              text: `${xp} XP`,
            },
            {
              text: `${coins}`,
              icon: WarblerCoin,
              iconType: 'img',
              link: '/InClassGame',
              iconToRight: true,
              alt: 'woin',
            },
          ]}
          back_button
        />
        {questionType === 'Listening-Multiple-Choice'
          ? (
            <StudentListeningMCDisplay
              answerNumber={answerNumber}
              guessAnswer={guessAnswer}
            />
          )
          : (
            currentQuestion === null || currentQuestion === undefined
              ? <div />
              : (
                <StudentRhythmDisplay
                  submitAnswer={submitAnswerRhythm}
                  mobile={mobileView}
                  bpm={currentQuestion?.info?.r?.bpm}
                  timeSignature={currentQuestion?.info?.r?.time_signature}
                  notes={currentQuestion?.info?.r?.notes}
                />
              )
          )}
      </div>
    );
  } else {
    return (
      <div className="ic-student-answer-flex">
        <NewNavBar
          title="In-class"
          items={[]}
          back_button
        />
        {questionType === 'Listening-Multiple-Choice'
          ? (
            <StudentListeningMCDisplay
              answerNumber={answerNumber}
              guessAnswer={guessAnswer}
            />
          )
          : (
            currentQuestion === null || currentQuestion === undefined
              ? <div />
              : (
                <StudentRhythmDisplay
                  submitAnswer={submitAnswerRhythm}
                  mobile={mobileView}
                  bpm={currentQuestion?.info?.r?.bpm}
                  timeSignature={currentQuestion?.info?.r?.time_signature}
                  notes={currentQuestion?.info?.r?.notes}
                />
              )
          )}
      </div>
    );
  }
};

export default StudentAnswerQuestion;
