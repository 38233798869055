/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import meetyou from '../../../images/meetyou.svg';
import jordan from '../../../images/jordan.png';
import jacob from '../../../images/jacob.png';
import shane from '../../../images/shane.png';
import gia from '../../../images/gia.png';
import sathvi from '../../../images/sathvi.png';
import NewNavBar from '../cs98_components/NewNavBar';

// eslint-disable-next-line react/prefer-stateless-function
class AboutUsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="about-us-page">
        <NewNavBar
          renderLogo
          items={[
            {
              text: 'Sign up',
              link: '/newuser',
              icon: 'fas fa-user-plus',
            },
            {
              text: 'Log in',
              link: '/login',
              icon: 'fa fa-sign-in',
            },
          ]}
          dropdownItems={[
            {
              text: 'Teacher Info',
              link: '/home/teachers',
            },
            {
              text: 'Student Info',
              link: '/home/students',
            },
            {
              text: 'About the Team',
              link: '/about',
            },
          ]}
          dropdownIcon="fas fa-book-open"
        />
        <img className="meet-you" src={meetyou} alt="the words 'nice to meet you!' curved" />

        <div className="about-us-intro">
          <b>We are a group of five Dartmouth College students with lifelong passions for music. </b>
          Four of us had access to formal musical training—and one of us didn’t.
          Without access to private music lessons, gaining the feedback needed to learn and refine core musicianship skills is daunting at best—and we wanted to change that.
          We came up with Warbler in one of our computer science classes, and now we’re bringing it to the real world!
        </div>
        <div className="get-to-know-us"> Get to know us individually 😎 </div>
        <div className="who-we-are">
          <div className="tile">
            <div className="image">
              <img src={jordan} alt="jordan" />
            </div>
            <div className="right-side-tile">
              <div className="name-tag">
                <div className="name-text">Jordan Sanz</div>
              </div>
              <div className="location">📍 Midland, Texas</div>
              <div className="short-desc">I&apos;m Jordan! I&apos;m studying Computer Science and Quantitative Social Science.
                On the Warbler team, I&apos;m a developer. <b>When I’m not coding, I am...</b>
              </div>
              <div className="hobbies">
                <div className="hobby-yellow-tag">
                  <div className="hobby-text">🎶 singing</div>
                </div>
                <div className="hobby-red-tag">
                  <div className="hobby-text">🎤 beatboxing</div>
                </div>
                <div className="hobby-purple-tag">
                  <div className="hobby-text">🤙 hanging with friends</div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile">
            <div className="image">
              <img src={jacob} alt="jacob" />
            </div>
            <div className="right-side-tile">
              <div className="name-tag">
                <div className="name-text">Jacob Donoghue</div>
              </div>
              <div className="location">📍 Washington, D.C.</div>
              <div className="short-desc">I&apos;m Jacob! I&apos;m studying Computer Science and Government.
                On the Warbler team, I&apos;m a developer. <b>When I’m not coding, I am...</b>
              </div>
              <div className="hobbies">
                <div className="hobby-yellow-tag">
                  <div className="hobby-text">🎶 singing</div>
                </div>
                <div className="hobby-red-tag">
                  <div className="hobby-text">🏔 hiking</div>
                </div>
                <div className="hobby-purple-tag">
                  <div className="hobby-text">🤾‍♂️ playing spikeball</div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile">
            <div className="image">
              <img src={shane} alt="shane" />
            </div>
            <div className="right-side-tile">
              <div className="name-tag">
                <div className="name-text">Shane Hewitt</div>
              </div>
              <div className="location">📍 Minneapolis, Minnesota</div>
              <div className="short-desc">I&apos;m Shane! I&apos;m studying Computer Science, Engineering, and Economics.
                On the Warbler team, I&apos;m a developer. <b>When I’m not coding, I am...</b>
              </div>
              <div className="hobbies">
                <div className="hobby-yellow-tag">
                  <div className="hobby-text">🏌️‍♂️ golfing</div>
                </div>
                <div className="hobby-red-tag">
                  <div className="hobby-text">⛹️‍♂️ playing basketball</div>
                </div>
                <div className="hobby-purple-tag">
                  <div className="hobby-text">🐀 watching ratatouille</div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile">
            <div className="image">
              <img src={gia} alt="gia" />
            </div>
            <div className="right-side-tile">
              <div className="name-tag">
                <div className="name-text">Gia Kim</div>
              </div>
              <div className="location">📍 Hau’ula, Hawai’i</div>
              <div className="short-desc">I&apos;m Gia! I&apos;m studying Computer Science and Digital Arts.
                On the Warbler team, I&apos;m a designer/developer. <b>When I’m not coding, I am...</b>
              </div>
              <div className="hobbies">
                <div className="hobby-yellow-tag">
                  <div className="hobby-text">🎶 singing</div>
                </div>
                <div className="hobby-red-tag">
                  <div className="hobby-text">🍳 cooking</div>
                </div>
                <div className="hobby-purple-tag">
                  <div className="hobby-text">🎨 painting</div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile">
            <div className="image">
              <img src={sathvi} alt="sathvi" />
            </div>
            <div className="right-side-tile">
              <div className="name-tag">
                <div className="name-text">Sathvi Korandla</div>
              </div>
              <div className="location">📍 Nashua, New Hampshire</div>
              <div className="short-desc">I&apos;m Sathvi! I&apos;m studying Computer Science and Art History.
                On the Warbler team, I&apos;m a developer. <b>When I’m not coding, I am...</b>
              </div>
              <div className="hobbies">
                <div className="hobby-yellow-tag">
                  <div className="hobby-text">🧁 baking</div>
                </div>
                <div className="hobby-red-tag">
                  <div className="hobby-text">🏃‍♀️ running</div>
                </div>
                <div className="hobby-purple-tag">
                  <div className="hobby-text">🎧 making playlists</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us-footer">
          <div className="about-us-footer-text">Want to get to know us more?</div>
          <div className="about-us-footer-text">Shoot us an email at <span className="dark-blue-text">warblermusic@gmail.com</span></div>
        </div>
      </div>
    );
  }
}

export default AboutUsPage;
