/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import LCPageTypeDropdown from './LCPageTypeDropdown';

import ContentMaking from '../lesson_creation_components/ContentMaking';
import RhythmQuestionCreate from '../lesson_creation_components/question_creation/RhythmQuestionCreate';
import IntervalsQuestionCreate from '../lesson_creation_components/question_creation/IntervalsQuestionCreate';
import SingingQuestionCreate from '../lesson_creation_components/question_creation/SingingQuestionCreate';
import ListeningMCQuestionCreate from '../lesson_creation_components/question_creation/ListeningMCQuestionCreate';
import SightreadingQuestionCreate from '../lesson_creation_components/question_creation/SightreadingQuestionCreate';
import SelectDropdown from './SelectDropdown';
import { changeIndividualPageType } from '../../../actions';

const PageTypes = ['Content', 'Rhythm-Sensing', 'Listening-Intervals', 'Listening-Multiple-Choice', 'Pitch-Matching', 'Sightreading'];

class LCIndividualPage extends Component {
  constructor(props) {
    super(props);
    console.log('on page with info', this.props.init_info);
    console.log('individual page props are from constructor: ', this.props);
    if (this.props.init_page_type === 'activity') {
      this.state = {
        selected: this.props.init_info.info.activity_type,
      };
    } else {
      this.state = {
        selected: this.props.init_page_type,
      };
    }
  }

  handleChange = (value) => {
    console.log('changing the select box');
    this.setState({ selected: PageTypes[value] });
    console.log(PageTypes[value]);
    // this.props.saveLessonFunc(false, true);
    // sleep(1);
    this.props.changeIndividualPageType(this.props.value, PageTypes[value]);
  }

  checkActivity = () => {
    const toReturn = {
      html: <div />,
      page_type: '',
    };
    let type;
    console.log('check activity: ', this.props);
    if (this.props.init_page_type === 'activity') {
      type = this.props.init_info.info.activity_type;
    } else {
      type = this.props.init_info.page_type;
    }
    switch (type) {
      case 'Content':
        console.log('in the individual page props: ', this.props);
        toReturn.html = <ContentMaking pageInfo={this.props.init_info} saveLessonFunc={this.props.saveLessonFunc} />;
        toReturn.page_type = 'Content';
        break;
      case 'content':
        console.log('in the individual page props: ', this.props);
        toReturn.html = <ContentMaking pageInfo={this.props.init_info} saveLessonFunc={this.props.saveLessonFunc} />;
        toReturn.page_type = 'Content';
        break;
      case 'Rhythm-Sensing':
        toReturn.html = <RhythmQuestionCreate pageInfo={this.props.init_info} saveLessonFunc={this.props.saveLessonFunc} />;
        toReturn.page_type = 'Rhythm-Sensing';
        break;
      case 'Listening-Intervals':
        toReturn.html = <IntervalsQuestionCreate pageInfo={this.props.init_info} pageNum={this.props.value} saveLessonFunc={this.props.saveLessonFunc} />;
        toReturn.page_type = 'Listening-Intervals';
        break;
      case 'Listening-Multiple-Choice':
        toReturn.html = <ListeningMCQuestionCreate pageInfo={this.props.init_info} saveLessonFunc={this.props.saveLessonFunc} />;
        toReturn.page_type = 'Listening-Multiple-Choice';
        break;
      case 'Pitch-Matching':
        toReturn.html = <SingingQuestionCreate pageInfo={this.props.init_info} saveLessonFunc={this.props.saveLessonFunc} />;
        toReturn.page_type = 'Pitch-Matching';
        break;
      case 'Sightreading':
        toReturn.html = <SightreadingQuestionCreate pageInfo={this.props.init_info} saveLessonFunc={this.props.saveLessonFunc} />;
        toReturn.page_type = 'Sightreading';
        break;
      default:
        toReturn.html = <div />;
        toReturn.page_type = '';
    }
    return toReturn;
  }

  checkIfCustomDescription = () => {
    if (this.props.init_info.info) {
      console.log('found custom instructions', this.props.init_info);
      if (this.props.init_info.page_type.toLowerCase() === 'content') {
        return this.props.init_info.info.title;
      } else {
        switch (this.props.init_info.info.activity_type) {
          case 'Rhythm-Sensing':
            return this.props.init_info.info.r.instructions;
          case 'Sightreading':
            return this.props.init_info.info.sr.instructions;
          case 'Listening-Multiple-Choice':
            return this.props.init_info.info.mc.instructions;
          case 'Pitch-Matching':
            return this.props.init_info.info.p.instructions;
          case 'Listening-Intervals':
            return this.props.init_info.info.i.instructions;
          default:
            return this.returnDefaultDescription();
        }
      }
    } else {
      return this.returnDefaultDescription();
    }
  }

  returnDefaultDescription = () => {
    console.log('returning');
    console.log('state', this.state.selected);
    switch (this.state.selected) {
      case 'Sightreading':
        return 'Drag the letters underneath their corresponding note! (They&apos;ll fill up when they&apos;re in the right spot!)';
      case 'Listening-Multiple-Choice':
        return 'Listen to the audio and select what you hear!';
      case 'Listening-Intervals':
        return 'Listen to the audio. Is this a...';
      case 'Pitch-Matching':
        return 'Let’s sing and match the pitch!';
      case 'Rhythm-Sensing':
        return 'Press the space bar to the correct rhythm!';
      case 'Content':
        return 'Type your title here';
      default:
        return 'Blank Description';
    }
  }

  render() {
    const toRender = this.checkActivity();
    this.checkIfCustomDescription();
    return (
      <div className={`lc-individual-page-container ${this.state.selected}-left-border-color`} page_type={toRender.page_type} num_id={this.props.value}>
        <div className="lc-individual-page-container-top">
          <input type="text" className="page-description-text" defaultValue={this.checkIfCustomDescription()} />
          <SelectDropdown func={this.handleChange} optionsList={PageTypes} init_page_type={toRender.page_type} />

          <div className="lc-i-page-text-and-buttons">

            <div className="lc-individual-page-container-buttons">
              <div className="lc-individual-page-container-copy">
                <div className="lc-individual-page-container-copy-button">
                  {this.props.copyButton}
                </div>
                <div className="lc-individual-page-container-copy-hidden-text">
                  Duplicate
                </div>

              </div>
              <div className="lc-individual-page-container-delete">
                <div className="lc-individual-page-container-delete-button">
                  {this.props.deleteButton}
                </div>
                <div className="lc-individual-page-container-delete-hidden-text">
                  Delete
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lc-render-question">
          {toRender.html}
        </div>
      </div>

    );
  }
}

export default connect(null, { changeIndividualPageType })(LCIndividualPage);
