import React from 'react';
import { connect } from 'react-redux';

// class ErrorNotification extends Component {
//     render() {

//     }
// }

const ErrorNotification = (props) => {
  if (props.error.open) {
    return (
      <div className="error-container">
        {props.error.messages.map((message) => {
          return (
            <div className="error" key={message}>
              {message}
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

function mapStateToProps(reduxState) {
  return {
    error: reduxState.error,
  };
}

export default connect(mapStateToProps)(ErrorNotification);
