/* eslint-disable no-plusplus */

function toUppercaseNoSlash(notesLower) {
  console.log('noteslower', notesLower);
  const notesUpper = [];
  let i;
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < notesLower.length; i += 1) {
    const [newNote] = notesLower[i].keys;
    const note = newNote.split('/')[0];
    if (note.length > 1) {
      const letter = note[0].toUpperCase() + note[1];
      notesUpper.push(letter);
    } else {
      const letter = note.toUpperCase();
      notesUpper.push(letter);
    }
  }
  return notesUpper;
}

function vexToToneFormat(notes) {
  const toneNotes = [];

  for (let i = 0; i < notes.length; i++) {
    const arr = notes[i].split('/');
    const note = arr[0].toUpperCase().concat(arr[1]);
    toneNotes.push(note);
  }
  return toneNotes;
}

function notesAndColors(notesLower) {
  // shuffle around notes so they're not in the correct order
  const notesUpper = toUppercaseNoSlash(notesLower).sort(() => 0.5 - Math.random());
  const colors = ['orange', 'purple', 'pink', 'brown', 'light-blue', 'red'];
  const letters = [];
  let i;
  for (i = 0; i < notesUpper.length; i++) {
    letters.push({ letter: notesUpper[i], color: colors[i] });
  }
  return letters;
}

function checkValidInput(note) {
  const hasSlash = note.includes('/');
  let validBeforeSlash = false;
  let validAfterSlash = false;
  if (hasSlash) {
    const parts = note.split('/');
    if (parts) {
      const beforeSlash = parts[0];
      if (beforeSlash.length === 3) {
        const beforeAccidentalAndSlash = beforeSlash[0];
        const accidental = beforeSlash.substring(1, 3);
        validBeforeSlash = (beforeAccidentalAndSlash.match(/^[a-g]+$/i) !== null) && (accidental === 'bb' || accidental === '##');
      } else if (beforeSlash.length === 2) {
        const beforeAccidentalAndSlash = beforeSlash[0];
        const accidental = beforeSlash[1];
        validBeforeSlash = (beforeAccidentalAndSlash.match(/^[a-g]+$/i) !== null) && (accidental.match(/[#b]/) !== null);
      } else if (beforeSlash.length === 1) {
        validBeforeSlash = beforeSlash.match(/^[a-g]+$/i) !== null;
      }
      const afterSlash = parts[1];
      validAfterSlash = afterSlash.match(/^[0-9]+$/) !== null;
    }
  }
  return hasSlash && validBeforeSlash && validAfterSlash;
}

export {
  toUppercaseNoSlash, notesAndColors, checkValidInput, vexToToneFormat,
};
