export function isValidDuration(string) {
  const str = string.toLowerCase();
  // test
  if (str === '16' || str === '16r') {
    return true;
  } else if (str === '8' || str === '8r') {
    return true;
  } else if (str === '8d' || str === '8dr') {
    return true;
  } else if (str === '8dd' || str === '8ddr') {
    return true;
  } else if (str === '8ddd' || str === '8dddr') {
    return true;
  } else if (str === 'q' || str === 'qr') {
    return true;
  } else if (str === 'qd' || str === 'qdr') {
    return true;
  } else if (str === 'qdd' || str === 'qddr') {
    return true;
  } else if (str === 'qddd' || str === 'qdddr') {
    return true;
  } else if (str === 'h' || str === 'hr') {
    return true;
  } else if (str === 'hd' || str === 'hdr') {
    return true;
  } else if (str === 'hdd' || str === 'hddr') {
    return true;
  } else if (str === 'hddd' || str === 'hdddr') {
    return true;
  } else if (str === 'w' || str === 'wr') {
    return true;
  } else if (str === '1/2' || str === '1/2r') {
    return true;
  } else {
    return false;
  }
}

export function isValidClef(string) {
  const str = string.toLowerCase();
  if (str === 'treble'
  || str === 'alto'
  || str === 'soprano'
  || str === 'mezzo-soprano'
  || str === 'baritone-c'
  || str === 'baritone-f'
  || str === 'subbass'
  || str === 'percussion'
  || str === 'french'
  || str === 'bass'
  ) {
    return true;
  }
  return false;
}

export function isValidKeySignature(string) {
  const str = string.toLowerCase();
  const majorKeys = [
    'c',
    'f',
    'bb',
    'eb',
    'ab',
    'db',
    'gb',
    'cb',
    'g',
    'd',
    'a',
    'e',
    'b',
    'f#',
    'c#',
  ];

  const minorKeys = [
    'am',
    'dm',
    'gm',
    'cm',
    'fm',
    'bbm',
    'ebm',
    'abm',
    'em',
    'bm',
    'f#m',
    'c#m',
    'g#m',
    'd#m',
    'a#m',
  ];

  if (majorKeys.includes(str) || minorKeys.includes(str)) {
    return true;
  }
  return false;
}

export function isValidPitch(string) {
  const str = string.toLowerCase();
  // split by /
  if (!str.includes('/')) {
    return false;
  }

  const arr = str.split('/');

  const pitch = arr[0];
  const octave = arr[1];

  const pitches = [
    'a', 'b', 'c', 'd', 'e', 'f', 'g',
    'a#', 'b#', 'c#', 'd#', 'e#', 'f#', 'g#',
    'a##', 'b##', 'c##', 'd##', 'e##', 'f##', 'g##',
    'ab', 'bb', 'cb', 'db', 'eb', 'fb', 'gb',
    'abb', 'bbb', 'cbb', 'dbb', 'ebb', 'fbb', 'gbb',
  ];

  const octaves = ['1', '2', '3', '4', '5', '6', '7', '8'];

  if (pitches.includes(pitch) && octaves.includes(octave)) {
    return true;
  }
  return false;
}

export function isValidInterval(string) {
  const str = string.toLowerCase();
  const intervals = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '12', '13'];
  if (intervals.includes(str)) {
    return true;
  }
  return false;
}

export function isValidVex(timeSignature, notes) {
  // render the stave but with a 0-sized window
  //   --> will trigger the callback function if invalid
  //   --> will trigger a different callback function if valid

}
