import React from 'react';

const TeacherDetailedResults = ({ backToResults }) => {
  return (
    <div>
      <div>This screen is still in progress! Come back later for more.</div>
      <button className="red-button" type="button" onClick={() => { backToResults(); }}>Go back</button>
    </div>
  );
};

export default TeacherDetailedResults;
