/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeActivityPage } from '../../../../actions';
import addBox from '../../../../../images/lessonCreation/addBox.svg';
import removeBox from '../../../../../images/lessonCreation/removeBox.svg';

const IntervalInput = (props) => {
  // const fnId = `first-note-input-${props.num}-${props.pageNum}`;
  // const snId = `second-note-input-${props.num}-${props.pageNum}`;
  // const ynId = `yn-input-${props.num}-${props.pageNum}`;
  const defaultVals = {
    n1: '',
    n2: '',
    checked: false,
  };

  if (props.values !== null && props.values !== undefined) {
    defaultVals.n1 = props.values.n1;
    defaultVals.n2 = props.values.n2;
    defaultVals.checked = props.values.checked;
  }
  return (
    <div className="big-answer-input-box-container" id={props.num}>
      <div className="answer-input-box-container interval-container">
        <div className="note-title">first note: </div>
        <input type="text" className="first-note sr-note-input-box" id={Math.random()} defaultValue={defaultVals.n1} onBlur={props.handleChange} />
      </div>
      <div className="answer-input-box-container interval-container">
        <div className="note-title">second note: </div>
        <input type="text" className="second-note sr-note-input-box" id={Math.random()} defaultValue={defaultVals.n2} onBlur={props.handleChange} />
      </div>
      <div className="answer-input-box-container interval-container correct-container-interval">
        <div className="note-title correct-note-title">Correct?</div>
        <label className="container">
          <input type="checkbox" className="yes-or-no-input" id={Math.random()} defaultChecked={defaultVals.checked} onBlur={props.handleChange} />
          <span className="checkmark" />
        </label>
      </div>
    </div>
  );
};

class IntervalsQuestionCreate extends Component {
  constructor(props) {
    super(props);
    if (this.props.pageInfo !== null && this.props.pageInfo !== undefined && this.props.pageInfo.info !== null && this.props.pageInfo.info !== undefined) {
      this.state = {
        numQuestions: this.props.pageInfo.info.i.intervals.length,
      };
    } else {
      this.state = {
        numQuestions: 3,
      };
    }
  }

  handleChange = () => {
    this.props.saveLessonFunc(false, true, null, false);
  };

  getIntervalInputBoxes = () => {
    console.log('rendering with number questions: ', this.state.numQuestions);
    const toReturn = [];
    if (this.props.pageInfo !== null && this.props.pageInfo !== undefined && this.props.pageInfo.info !== null && this.props.pageInfo.info !== undefined) {
      for (let i = 0; i < this.props.pageInfo.info.i.intervals.length; i += 1) {
        let c = false;
        if (this.props.pageInfo.info.i.answers[i] === true) {
          c = true;
        }
        const values = {
          n1: this.props.pageInfo.info.i.intervals[i][0],
          n2: this.props.pageInfo.info.i.intervals[i][1],
          checked: c,
        };
        toReturn.push(<IntervalInput num={i + 1} key={i} values={values} pageNum={this.props.value} handleChange={this.handleChange} />);
      }
      while (toReturn.length < this.state.numQuestions) {
        toReturn.push(<IntervalInput num={toReturn.length} key={toReturn.length + 1} pageNum={this.props.value} handleChange={this.handleChange} />);
      }
      while (toReturn.length > this.state.numQuestions) {
        toReturn.pop();
      }
    } else {
      for (let i = 0; i < this.state.numQuestions; i += 1) {
        toReturn.push(<IntervalInput num={i + 1} key={i} pageNum={this.props.value} saveLessonFunc={this.props.saveLessonFunc} handleChange={this.handleChange} />);
      }
    }

    return toReturn;
  }

  addInterval = () => {
    console.log('adding interval box');
    this.setState((prevState) => ({
      numQuestions: prevState.numQuestions + 1,
    }));
  }

  deleteInterval = () => {
    this.setState((prevState) => ({
      numQuestions: prevState.numQuestions - 1,
    }));
  }

  render() {
    return (
      <div className="nlc-question-main-width-flexstart">
        <div className="nlc-question-selects">
          <div className="select-container">
            <div className="select-title">Testing interval: </div>
            <input
              className="sr-note-input-box interval-to-test"
              id={Math.random()}
              type="text"
              defaultValue={(this.props.pageInfo.info) ? this.props.pageInfo.info.i.interval_text : ''}
              onBlur={this.handleChange}
            />
          </div>
        </div>
        <div className="nlc-entry-inputs-flex-extra">
          {this.getIntervalInputBoxes()}
        </div>
        <div className="nlc-add-remove-buttons">
          <div className="nlc-add-remove-button" role="button" type="button" onClick={() => this.addInterval()}>
            <img src={addBox} alt="blue box with addition sign" />
            <div className="add-button-text">Add interval</div>
          </div>
          <div className="nlc-add-remove-button" role="button" type="button" onClick={() => this.deleteInterval()}>
            <img src={removeBox} alt="red box with minus sign" />
            <div className="remove-button-text">Delete last interval</div>
          </div>
        </div>
        <input type="hidden" className="id-hidden-input" value={this.props.pageInfo._id} />
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    lessonid: reduxState.lessonMaking.lessonid,
  };
}

export default connect(mapStateToProps, { makeActivityPage })(IntervalsQuestionCreate);
