import React from 'react';

const BarGraph = ({
  count, type, bigger, correctAnswer,
}) => {
  return (
    <div className="main-bar-ic">
      <div className={`bar-count ${type === 'correct' ? 'green-text-ic' : 'red-text-ic'}`}>{count}</div>
      <div className={`bar ${bigger ? 'bigger-bar' : 'smaller-bar'} ${type === 'correct' ? 'green-bar' : 'red-bar'}`}>
        {type === 'correct' && correctAnswer !== -1 ? <div className="bar-correct-answer"><span className="correct-answer-big-num">{correctAnswer}</span> was the correct answer!</div> : null}
      </div>
      <div className={`bar-text ${type === 'correct' ? 'green-text-ic' : 'red-text-ic'}`}>answered {type === 'correct' ? 'correctly!' : 'incorrectly.'}</div>
    </div>
  );
};

export default BarGraph;
