/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ClassroomPageTeacherStudentsView from './ClassroomPageTeacherStudentsView';
import ClassroomPageTeacherLessonView from './ClassroomPageTeacherLessonView';
import ClassroomPageTeacherLeaderBoard from './ClassroomPageTeacherLeaderBoard';
import Lesson from '../Lesson';
import LCTopLevel from '../NewLessonCreation/LessonCreationTopLevel';
import { getClass, getLessonToEdit, clearLessonMaking } from '../../../actions';
import quit from '../../../../images/classroom/quit.svg';
import NewNavBar from '../../cs98_components/NewNavBar';

const StudentItem = (props) => {
  return (
    <div className="example-student-container" key={props.num}>
      <img alt="" src="https://blog.nature.org/science/files/2017/09/blackpoll-warbler-Flicr-CC-Scott-Heron-1260x708.jpg" />
      <span className="student-name">{props.studentInfo.username}</span>
      <span className="experience-points">{props.studentInfo.stats.xp}<span style={{ fontSize: '0.9em' }}>XP</span></span>
    </div>
  );
};

const LessonItem = (props) => {
  return (
    <div className="example-lesson-container" key={props.num} index={props.num} role="tab" onClick={props.goToLesson} tabIndex={0}>
      <span className="lesson-title">{props.lessonInfo.title}</span>
      <span className="num-students-finished">This is not anything yet</span>
    </div>
  );
};

class ClassroomPageTeacher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewLesson: false,
    };
    if (this.props.class.students) {
      this.props.getClass(window.location.pathname.split('/')[2]);
    }
    this.state = {
      selected_page: 'studentsView',
    };
  }

  componentDidMount() {
    this.props.clearLessonMaking();
    if (this.props.class.students === null) {
      console.log('called in did mount');
      this.props.getClass(window.location.pathname.split('/')[2]);
    }
  }

  goToLesson = (event) => {
    // const pushTo = `/createlesson/${this.props.class.code}`;
    console.log(event.target);
    this.props.getLessonToEdit(this.props.class.lessons[Number(event.target.getAttribute('index'))]._id, this.props.history, this.props.class.code);
  }

  getLessons = () => {
    const lessons = [];
    for (let i = 0; i < this.props.class.lessons.length; i += 1) {
      lessons.push(<LessonItem key={i} id={`lesson-panel-${i}`} num={i} lessonInfo={this.props.class.lessons[i]} goToLesson={this.goToLesson} />);
    }
    // return lessons;
    return this.props.class.lessons;
  }

  getStudents = () => {
    const students = [];
    for (let i = 0; i < this.props.class.students.length; i += 1) {
      students.push(<StudentItem key={i} id={`student-panel-${i}`} num={i} studentInfo={this.props.class.students[i]} />);
    }
    return students;
  }

  changePage = (props) => {
    document.querySelector('div.selected').classList.remove('selected');
    let result = null;
    if (props.target.classList[0] === 'fa' || props.target.classList[0] === 'fas') {
      console.log('fa was in');
      result = props.target.parentElement.parentElement;
    } else {
      result = props.target.parentElement;
    }
    result.classList.add('selected');
    if (result.id === 'Students') {
      this.setState({ selected_page: 'studentsView' });
    } else if (result.id === 'Lessons') {
      this.setState({ selected_page: 'lessonsView' });
    } else if (result.id === 'Lesson Creation') {
      this.props.history.push(`/createlesson/${this.props.class.code}`);
    } else if (result.id === 'Leaderboard') {
      this.setState({ selected_page: 'leaderboard' });
    }
  }

  render() {
    if (this.props.class.students === null) {
      return (
        <div className="loading" />
      );
    } else {
      let toRender = <div>{this.state.selected_page}</div>;
      if (this.state.selected_page === 'studentsView') {
        toRender = <ClassroomPageTeacherStudentsView studentsList={this.getStudents()} />;
      } else if (this.state.selected_page === 'lessonsView') {
        toRender = <ClassroomPageTeacherLessonView previewLesson={() => { this.setState({ previewLesson: true }); }} />;
      } else if (this.state.selected_page === 'lessonCreation') {
        toRender = <LCTopLevel />;
      } else if (this.state.selected_page === 'leaderboard') {
        toRender = <ClassroomPageTeacherLeaderBoard />;
      }
      let selectedPageClass;
      if (this.state.selected_page === 'leaderboard') {
        selectedPageClass = 'selected-page-container-leaderboard';
      } else {
        selectedPageClass = 'selected-page-container';
      }

      if (this.state.previewLesson) {
        return (
          <div>
            <div className="lesson-complete-exit-holder-fixed" onClick={() => { this.setState({ previewLesson: false }); }}>
              <img className="quit" alt="quit icon" src={quit} />
              <div className="quit-text">Quit</div>
            </div>
            <Lesson type="preview" />
          </div>
        );
      }
      return (
        <div className="classroom-teacher-page-container">
          <NewNavBar items={[
            {
              text: 'Sign Out',
              link: '/home',
              icon: 'fas fa-sign-out-alt',
            },
          ]}
            back_button
            title={this.props.class.name}
          />
          <div className="non-navbar-content-container">
            <div className="left-menu-container">
              <div className="selected" id="Students">
                <span role="tab" tabIndex={0} onClick={this.changePage}>
                  <i className="fa fa-user" />
                </span>
                <span className="tab-text" role="tab" tabIndex={0} onClick={this.changePage}>Students</span>
              </div>
              <div id="Lessons">
                <span role="tab" tabIndex={0} onClick={this.changePage}>
                  <i className="fa fa-list-alt" />
                </span>
                <span className="tab-text" role="tab" tabIndex={0} onClick={this.changePage}>Lessons</span>
              </div>
              <div id="Lesson Creation">
                <span role="tab" tabIndex={0} onClick={this.changePage}>
                  <i className="fas fa-puzzle-piece" />
                </span>
                <span className="tab-text" role="tab" tabIndex={0} onClick={this.changePage}>Lesson Creation</span>
              </div>
              <div id="Leaderboard">
                <span role="tab" tabIndex={0} onClick={this.changePage}>
                  <i className="fas fa-fire" />
                </span>
                <span className="tab-text" role="tab" tabIndex={0} onClick={this.changePage}>Leaderboard</span>
              </div>

            </div>
            <div className={selectedPageClass}>
              {toRender}
            </div>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(reduxState) {
  return {
    class: reduxState.class,
  };
}

export default withRouter(connect(mapStateToProps, { getClass, getLessonToEdit, clearLessonMaking })(ClassroomPageTeacher));
