/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

class Loading extends Component {
  render() {
    if (this.props.loading) {
      return (
        <div className="lds-circle"><div /></div>
      );
    } else {
      return (
        <div />
      );
    }
  }
}

export default Loading;
