/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import mistakes from '../../../../images/landing/mistakes.svg';
import tldr from '../../../../images/landing/tldr.svg';
import NewNavBar from '../../cs98_components/NewNavBar';

class LandingPageStudents extends Component {
  scrollToTLDR = () => {
    $('html, body').animate({
      scrollTop: $('#tldr-scroll-to').offset().top,
    }, 800);
  }

  render() {
    return (
      <div className="landing-page-students-flex">
        <NewNavBar
          renderLogo
          items={[
            {
              text: 'Sign up',
              link: '/newuser',
              icon: 'fas fa-user-plus',
            },
            {
              text: 'Log in',
              link: '/login',
              icon: 'fa fa-sign-in',
            },
          ]}
          dropdownItems={[
            {
              text: 'Teacher Info',
              link: '/home/teachers',
            },
            {
              text: 'Student Info',
              link: '/home/students',
            },
            {
              text: 'About the Team',
              link: '/about',
            },
          ]}
          dropdownIcon="fas fa-book-open"
        />

        <div className="landing-page-students-section">
          <div className="landing-page-students-title-text-flex" id="lpsttf-1">
            <div className="landing-page-students-title-text">Hey students, got homework?</div>
          </div>
          <div className="landing-page-students-title-text-flex" id="lpsttf-2">
            <div className="landing-page-students-title-text">Click&nbsp;
              <button className="go-to-TLDR" type="button">
                <span className="yellow-underline" onClick={this.scrollToTLDR}>here</span>
              </button>&nbsp;for a TLDR ;&#41;
            </div>
          </div>
          <div className="landing-page-students-title-text-flex" id="lpsttf-3">
            <div className="landing-page-students-mini-text">Not a student? Teachers click <NavLink to="/home/teachers"><span className="blue-underline hover-word">here.</span></NavLink>
            </div>
          </div>
        </div>

        <div className="landing-page-students-section" id="lpss-2">
          <div className="landing-page-students-section-2-left">
            <div className="make-mistakes-wrapper">
              <img className="curved" src={mistakes} alt="the words 'make mistakes' curved" />
            </div>
            <div className="emoji-wrapper">
              <div className="emoji1">😎</div>
            </div>
          </div>
          <div className="landing-page-students-section-2-right">
            <div className="landing-page-students-minititle" id="lpsm-1">LET&apos;S HAVE FUN.</div>
            <div className="landing-page-students-minititle-subtext" id="lpsms-1">Making music in front of people is hard and inherently personal.</div>
            <div className="landing-page-students-text">Usually the only way to get individual feedback is to put yourself out there and do it in front of other people.
              However, by using our platform, you can learn and make mistakes in a safe environment!
            </div>
          </div>
        </div>

        <div className="landing-page-students-section" id="lpss-3">
          <div className="landing-page-students-section-3-left">
            <div className="landing-page-students-minititle" id="lpsm-3">&quot;How will you make learning fun?&quot;</div>
            <div className="landing-page-students-minititle-subtext" id="lpsms-3">We&apos;re glad you asked...Short answer: 5 activity types. Just for you.</div>
            <div className="landing-page-students-text"> Learning the nitty gritty of music theory is fine and all,
              but here at Warbler we’re about learning skills. We want you to feel confident looking at a piece of music
              (or hearing a melody) and being able to replicate it with your preferred instrument.
            </div>
          </div>
          <div className="landing-page-students-section-3-right">
            <div className="emoji">🤔</div>
          </div>
        </div>

        <div className="landing-page-students-section-flex">
          <div className="landing-page-students-section" id="lpss-numbers">
            <div className="lpss-num-flex">
              <div className="lpss-num" id="lpss-num-1">1</div>
              <div className="lpss-num-text">Interval Training</div>
            </div>
            <div className="lpss-num-flex">
              <div className="lpss-num" id="lpss-num-2">2</div>
              <div className="lpss-num-text">Rhythm</div>
            </div>
            <div className="lpss-num-flex">
              <div className="lpss-num" id="lpss-num-3">3</div>
              <div className="lpss-num-text">Pitch-matching</div>
            </div>
            <div className="lpss-num-flex">
              <div className="lpss-num" id="lpss-num-4">4</div>
              <div className="lpss-num-text">Listening</div>
            </div>
            <div className="lpss-num-flex">
              <div className="lpss-num" id="lpss-num-5">5</div>
              <div className="lpss-num-text">Sightreading</div>
            </div>
          </div>
        </div>

        <div className="landing-page-students-section" id="lpss-5">
          <div className="landing-page-students-minititle red-lpss lp-push lp-push-top">OK READ ME THO. FR. (pretty please)</div>
          <div className="landing-page-students-minititle-subtext red-lpss lp-push">If you don&apos;t read anything else, read this part.</div>
          <div className="landing-page-students-text-extra-width lp-push lp-push-bottom">Warbler is made up of a bunch of college kids that like music and gaming (just like you ;&#41;),
            so we went <span className="red-text">harddddd</span> on fun stuff. Here are the highlights:
          </div>
          <div className="lpss-5-image-row">
            <div className="lpss-5-image-group">
              <div className="lpss-5-image" alt="battery">🔋</div>
              <h4 className="lpss-5-image-subtitle red-lpss">Level up.</h4>
              <h5 className="lpss-5-image-text">
                The more exercises you complete, the more XP you get.
              </h5>
            </div>

            <div className="lpss-5-image-group">
              <div className="lpss-5-image" alt="trophy">🏆</div>
              <h4 className="lpss-5-image-subtitle red-lpss">Get rewards!</h4>
              <h5 className="lpss-5-image-text">
                Win badges and achievements.
              </h5>
            </div>

            <div className="lpss-5-image-group">
              <div className="lpss-5-image" alt="medal">🥇</div>
              <h4 className="lpss-5-image-subtitle red-lpss">Who&apos;s boss?</h4>
              <h5 className="lpss-5-image-text">
                Get to the top of the leaderboard. Maybe even learn something along the way.
              </h5>
            </div>
          </div>
        </div>

        <div className="landing-page-students-section" id="tldr-scroll-to">
          <img src={tldr} alt="tldr;" className="tldr" />
          <div className="lpss-6-title">Imagine if Kahoot and choir class had a child. That’s Warbler.</div>
          <div className="lpss-6-subtitle lpss-6-margins">We’re giving it out for free, so tell your teachers about it so the fun can begin.</div>
        </div>

        <div className="landing-students-footer">
          <div className="landing-footer-text">Got feedback or ideas for new fun features?</div>
          <div className="landing-footer-text">Shoot us an email at <span className="dark-blue-text">warblermusic@gmail.com</span></div>
        </div>
      </div>
    );
  }
}

export default LandingPageStudents;
