/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { getClass, getLesson, registerLessonAttempt } from '../../../actions';
import ribbon from '../../../../images/classroom/badge.svg';
import blueX from '../../../../images/classroom/blue-x.svg';
import { calculateAvailableXP } from '../XP';
import NewNavBar from '../../cs98_components/NewNavBar';

const LessonItem = (props) => {
  return (
    <div className={`example-lesson-container ${props.className}`} key={props.num} index={props.num} role="tab" onClick={props.goToLesson} tabIndex={0}>
      <div className={`lesson-title ${props.lessonTitleClass}`}>{props.lessonInfo.title}</div>
      <div className={`num-students-finished ${props.numClass}`}>{props.lessonInfo.description}</div>
    </div>
  );
};

class ClassroomPageStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lessonModalOpen: false,
      selectedLesson: null,
      availableXP: 0,
    };
    this.state = {
      leaderboard: null,
    };
  }

  componentDidMount() {
    if (this.props.class.students === null) {
      console.log('called in did mount');
      this.props.getClass(window.location.pathname.split('/')[2]);
    }
  }

  goToLesson = (event) => {
    // register attempt
    this.props.registerLessonAttempt(this.props.class.lessons[this.props.class.lessons.indexOf(this.state.selectedLesson)]._id, this.props.user.id);
    this.props.getLesson(this.props.class.lessons[this.props.class.lessons.indexOf(this.state.selectedLesson)]._id, this.props.history, true);
  }

  getLessons = (isBlue) => {
    const lessons = [];
    for (let i = 0; i < this.props.class.lessons.length; i += 1) {
      if (isBlue && this.props.class.lessons[i].publish) {
        console.log('user', this.props.user);
        lessons.push(<LessonItem className="blue-lesson"
          lessonTitleClass="blue-lesson-title"
          numClass="blue-lesson-num"
          key={i}
          id={`lesson-panel-${i}`}
          num={i}
          lessonInfo={this.props.class.lessons[i]}
          goToLesson={this.goToLesson}
        />);
      } else if (this.props.class.lessons[i].publish) {
        lessons.push(<LessonItem className="gray-lesson"
          lessonTitleClass="gray-lesson-title"
          numClass="gray-lesson-num"
          key={i}
          id={`lesson-panel-${i}`}
          num={i}
          lessonInfo={this.props.class.lessons[i]}
          goToLesson={this.goToLesson}
        />);
      }
    }
    console.log('got lessons', lessons);
    return lessons;
  }

  openModal = (e) => {
    console.log(this.state.lessonModalOpen);
    console.log(e.target.getAttribute('index'));
    const i = Number(e.target.getAttribute('index'));
    this.setState({
      lessonModalOpen: true,
      selectedLesson: this.props.class.lessons[i],
      availableXP: calculateAvailableXP(this.props.class.lessons[i]),
    });
    console.log(this.state.selectedLesson);
  }

  closeModal = () => {
    this.setState({
      lessonModalOpen: false,
    });
  }

  createLeaderboard = () => {
    if (this.props.class.students !== null) {
      console.log('leaderboard:', this.props.class.students);
      const students = [];
      // replace user's xp with most up-to-date count
      this.props.class.students.forEach((s) => {
        const student = s;
        if (student.username === this.props.user.username) {
          student.xp = this.props.user.stats.xp;
        }
        students.push(student);
      });
      // const leaderboard = this.props.class.students; // replace with this.sortLeaders(this.props.class.students, 0, this.props.class.students.length - 1); when all student's xp is stored on backend
      const leaderboard = this.sortLeaders(this.props.class.students, 0, this.props.class.students.length - 1);
      console.log('leaderboard is: ', leaderboard);
      this.setState({ leaderboard });
    }
  }

  sortLeaders = (leaderboard, l, h) => {
    /* modified quickSort */
    if (l < h) {
      // eslint-disable-next-line prefer-const
      let [p, leaders] = this.partition(leaderboard, l, h);
      leaders = this.sortLeaders(leaders, l, p - 1);
      leaders = this.sortLeaders(leaders, p + 1, h);

      return leaders;
    }
    return leaderboard;
  }

  partition = (leaderboard, l, h) => {
    const pi = l + parseInt(Math.random() * (h - l), 10);
    const p = leaderboard[pi].xp;
    let leaders = this.swap(leaderboard, pi, h);

    let i = l;
    for (let j = l; j < h; j += 1) {
      if (leaders[j].xp > p) {
        leaders = this.swap(leaders, i, j);
        i += 1;
      }
    }

    leaders = this.swap(leaders, i, h);
    return [i, leaders];
  }

  swap = (leaderboard, i1, i2) => {
    const leaders = leaderboard;
    const temp = leaders[i1];
    leaders[i1] = leaders[i2];
    leaders[i2] = temp;
    return leaders;
  }

  renderLeaderBoard = () => {
    if (this.state.leaderboard === null || this.state.leaderboard === undefined) {
      this.createLeaderboard();
      return (
        <div className="leaderboard">Loading...</div>
      );
    } else {
      console.log('rendering w leaderboard', this.state.leaderboard);
      let renderedCurr = false;
      let place = 0;
      let studPlace = 0;
      return (
        <div className="cspc-right">
          <div className="leaderboard-header">
            <img src={ribbon} alt="badge" /> &nbsp; Leaderboard
          </div>
          <div className="leaderboard-students">
            { // render first 8 students
              this.state.leaderboard.map((student) => {
                place += 1;
                if (student.username === this.props.user.username) {
                  studPlace = place;
                  if (place < 9) {
                    renderedCurr = true;
                  }
                }
                if (place > 9) {
                  return (
                    <div />
                  );
                } else {
                  return (
                    <div id={student.username} className="leaderboard-entry" key={student.username}>
                      <div>{place}. {student.username}</div>
                      <div className="leaderboard-entry-xp">{student.xp}</div>
                    </div>
                  );
                }
              })
              }
            {
              !renderedCurr && (
              <div id={this.props.user.username} className="leaderboard-entry">
                <div>{studPlace}. {this.props.user.username}</div>
                <div className="leaderboard-entry-xp">{this.props.user.stats.xp}</div>
              </div>
              )
              }
          </div>
          <div className="leaderboard-bottom">
            <div className="leaderboard-red-text">
              You&apos;re in {studPlace}{studPlace === 1 && 'st'}{studPlace === 2 && 'nd'}{studPlace === 3 && 'rd'}{studPlace >= 4 && 'th'} place!
            </div>
            {studPlace > 1
            && (
            <div className="leaderboard-subtext">
              You&apos;re {this.state.leaderboard[studPlace - 2].xp - this.props.user.stats.xp} XP behind {studPlace - 1}
              {studPlace - 1 === 1 && 'st'}{studPlace - 1 === 2 && 'nd'}{studPlace - 1 === 3 && 'rd'}{studPlace - 1 >= 4 && 'th'} place.
            </div>
            )}
          </div>
        </div>
      );
    }
  }

  getClassLessonIDs = () => {
    const toReturn = this.props.class.lessons.map((lesson) => {
      return lesson._id;
    });
    return toReturn;
  }

  makeCompletedLessons = () => {
    const completedLessons = [];
    const incompleteLessons = [];
    const allLessonIDs = this.getClassLessonIDs();
    for (let i = 0; i < allLessonIDs.length; i += 1) {
      console.log(allLessonIDs[i]);
      console.log('in makeCompleted', this.props.user.completedLessons.indexOf(allLessonIDs[i]));
      console.log('checking if id: ', allLessonIDs[i], 'is in ', this.props.user.completedLessons);
      if (this.props.user.completedLessons.indexOf(allLessonIDs[i]) > -1 && this.props.class.lessons[i].publish) {
        completedLessons.push(<LessonItem className="gray-lesson"
          lessonTitleClass="gray-lesson-title"
          numClass="gray-lesson-num"
          key={i}
          id={`lesson-panel-${i}`}
          num={i}
          lessonInfo={this.props.class.lessons[i]}
          goToLesson={this.openModal}
        />);
      } else if (this.props.class.lessons[i].publish) {
        incompleteLessons.push(<LessonItem className="blue-lesson"
          lessonTitleClass="blue-lesson-title"
          numClass="blue-lesson-num"
          key={i}
          id={`lesson-panel-${i}`}
          num={i}
          lessonInfo={this.props.class.lessons[i]}
          goToLesson={this.openModal}
        />);
      }
    }
    console.log('completedLessons', completedLessons);
    console.log('incompleteLessons: ', incompleteLessons);
    return [completedLessons, incompleteLessons];
  }

  render() {
    console.log('rendering');
    console.log('leaderboard', this.state.leaderboard);
    if (this.props.class.students === null) {
      return (
        <div>
          Response not yet received.
        </div>
      );
    } else {
      this.makeCompletedLessons();
      const [completedLessonsList, incompleteLessonsList] = this.makeCompletedLessons();
      console.log(completedLessonsList, incompleteLessonsList);

      const lessonInformation = (this.state.selectedLesson !== undefined) ? {
        title: this.state.selectedLesson.title,
        author: this.state.selectedLesson.author_id,
        attempts: this.state.selectedLesson.attempt_count,
        completions: this.state.selectedLesson.completed_count,
      } : {
        title: 'Grow up',
        author: 'idk',
        attempts: 69,
      };
      const allLessons = this.getLessons(false);
      return (
        <div className="classroom-student-page-container">
          <NewNavBar items={[
            {
              text: 'Sign Out',
              link: '/home',
              icon: 'fas fa-sign-out-alt',
            },
          ]}
            back_button
            title={this.props.class.name}
          />
          <Modal
            isOpen={this.state.lessonModalOpen}
            onRequestClose={this.closeModal}
            className="Modal modal lesson-modal-student-revised"
            overlay="overlay"
            contentLabel="Lesson"
            ariaHideApp={false}

          >
            <div className="modal-top">
              <img className="blue-x" src={blueX} alt="blue x to exit out of modal" onClick={this.closeModal} />
            </div>
            <div className="lesson-modal-title">{lessonInformation.title}</div>
            {/* <div className="lesson-modal-author">Author: {lessonInformation.author}</div> */}
            <div className="lesson-modal-stats-flex">
              <div className="lesson-modal-stat-box box-green">
                <div className="lesson-modal-stat-title">Possible Obtainable XP</div>
                <div className="lesson-modal-stat">{this.state.availableXP}XP</div>
              </div>
              <div className="lesson-modal-stat-box lime-green">
                <div className="lesson-modal-stat-title"># of Attempts</div>
                <div className="lesson-modal-stat">{lessonInformation.attempts}</div>
              </div>
              <div className="lesson-modal-stat-box dark-green">
                <div className="lesson-modal-stat-title">Completion Rate</div>
                <div className="lesson-modal-stat">{
                (parseInt((lessonInformation.completions * 100) / lessonInformation.attempts, 10) === undefined
                || parseInt((lessonInformation.completions * 100) / lessonInformation.attempts, 10) === null)
                  ? '0'
                  : parseInt((lessonInformation.completions * 100) / lessonInformation.attempts, 10)
}%
                </div>
              </div>
            </div>
            <button type="button" className="play-button-modal" onClick={this.goToLesson}>Play</button>
          </Modal>
          <div className="non-navbar-content-container student-classroom-container">
            <div className="classroom-student-page-content-container">

              {/* Left side */}
              <div className="cspc-left">
                <div className="cspc-row" id="first-row">
                  <div className="cspc-title cspc-margin-left-title sky-blue-text">Incomplete <span className="cspc-title-subtext light-blue-text">{incompleteLessonsList.length} lessons</span></div>
                  <div className="cspc-lesson-list">{incompleteLessonsList}</div>
                </div>
                <div className="cspc-row">
                  <div className="cspc-title cspc-margin-left-title gray-text">Completed <span className="cspc-title-subtext gray-text">{completedLessonsList.length} lessons</span></div>
                  <div className="cspc-lesson-list">{completedLessonsList}</div>
                </div>
                <div className="cspc-row">
                  <div className="cspc-title cspc-margin-left-title gray-text">All <span className="cspc-title-subtext gray-text">{allLessons.length} lessons</span></div>
                  <div className="cspc-lesson-list">{allLessons}</div>
                </div>
                {/* <button type="button" className="open" onClick={this.openModal}>Open modal</button> */}
              </div>

              {this.renderLeaderBoard()}
            </div>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(reduxState) {
  return {
    class: reduxState.class,
    user: reduxState.user,
  };
}

export default withRouter(connect(mapStateToProps, { getClass, getLesson, registerLessonAttempt })(ClassroomPageStudent));
