import React from 'react';
import Lottie from 'react-lottie';
import countdown from '../assets/countdown.json';
import NewNavBar from '../../../cs98_components/NewNavBar';
import { difficulties } from '../../../../lib/constants';

const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const animStyle = {
  width: '74vw',
  position: 'absolute',
  zIndex: 10,
  left: 0,
  top: 0,
  marginLeft: '13vw',
  marginTop: 'auto',
};

const TeacherStartGame = ({
  msg, gameDifficulty, newGamePin, numOfPlayers, setupNewGame, changeDifficulty, startTheGame, playerData, startButtonClicked, playCountdown,
}) => {
  console.log({ gameDifficulty });
  return (
    <div className="ic-game-flex">
      <NewNavBar back_button
        items={[
          {
            text: '',
            link: '/home',
          },
          {
            text: 'Sign Out',
            link: '/home',
            icon: 'fas fa-sign-out-alt',
          },
        ]}
        title="In-class"
      />
      {
        playCountdown
          ? (
            <Lottie
              isStopped={playCountdown}
              className="countdown-anim"
              style={animStyle}
              options={{ ...defaultOptions, animationData: countdown }}
            />
          )
          : null

      }
      <div className="ic-game-flex-col-teacher">

        <div className="ic-left-half">
          <div className="ic-left-upper-section">
            <h1 id="ic-teacher-title">Ready? Set...Go!!!</h1>
            <div className="ic-teacher-text-section">
              <p className="j ic-teacher-text">In-class activity generates random questions for your students to play against each other in real time!</p>
              <p className="j ic-teacher-text">Every time you click the generate code button, it will create a new game. Create a code for students to join
                <span className="bold"> and click the start button when you are ready!</span>
              </p>
            </div>
          </div>
          <div className="ic-left-lower-section">
            <div className="ic-left-lower-left-section">
              <h2 className="j" id="ic-code-title">Your code:</h2>
              <div className="j" id="ic-code-holder">{newGamePin}</div>
              <div className="difficulty-buttons">
                <button className={`difficulty-button green-dif ${gameDifficulty === difficulties.EASY ? 'active-difficulty' : ''}`}
                  type="button"
                  onClick={() => { changeDifficulty(difficulties.EASY); }}
                >EASY
                </button>
                <button className={`difficulty-button yellow-dif ${gameDifficulty === difficulties.MEDIUM ? 'active-difficulty' : ''}`}
                  type="button"
                  onClick={() => { changeDifficulty(difficulties.MEDIUM); }}
                >MED
                </button>
                <button className={`difficulty-button red-dif ${gameDifficulty === difficulties.HARD ? 'active-difficulty' : ''}`}
                  type="button"
                  onClick={() => { changeDifficulty(difficulties.HARD); }}
                >HARD
                </button>
              </div>
              <button id="blue-button-new-width" className="blue button" type="button" onClick={() => { setupNewGame(); }}>Generate New Code</button>
            </div>
            <div className="ic-left-lower-right-section">
              <h3 id="ic-players-playing">{numOfPlayers}</h3>
              <p id="ic-players-playing-subtitle">students joined</p>
            </div>
          </div>
        </div>

        <div className="ic-right-section">
          <div className="ic-right-upper">
            <h2 id="ic-list-title">List of students joined:</h2>
            <div className="ic-right-upper-inner">
              <ol className="ic-names-box">
                {playerData !== undefined ? playerData?.map((val, key) => {
                  return (
                    <li className="names-ic">
                      <div className="num-name-ic">{key + 1}.</div>
                      <div className="name-ic-name">{val.name}</div>
                    </li>
                  );
                }) : null}
              </ol>
            </div>
          </div>
          <button className="green-button" type="button" onClick={() => { if (!startButtonClicked) startTheGame(); }}>Start!</button>
        </div>
      </div>
    </div>
  );
};

export default TeacherStartGame;
