/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import redflame from '../svg/redflame.svg';
import yellowflame from '../svg/yellowflame.svg';

class LessonComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {

  }

  renderStats = () => {
    if (this.props.random) {
      return (
        <div className="lesson-complete-regular">
          You got to level {this.props.depth}!
        </div>
      );
    } else {
      return (
        <div className="lesson-complete-regular">
          You finished the lesson!
        </div>
      );
    }
  }

  goBack = () => {
    const { history } = this.props;
    history.push('/userhome');
  }

  renderCompleteButtons = () => {
    console.log('type:', this.props.type);
    if (this.props.type === 'preview') {
      return (
        <div className="lesson-complete-buttons" />
      );
    } else {
      return (
        <div className="lesson-complete-buttons">
          <div className="lesson-complete-try-holder">
            <div className="lesson-complete-try" onClick={this.props.reset}>
              Try Again
            </div>
          </div>
          <div className="lesson-complete-exit-holder">
            <div className="lesson-complete-exit" onClick={this.goBack}>
              Exit
            </div>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="lesson-complete-wrapper">
        <div className="lesson-complete">
          <div className="lesson-complete-body">
            <div className="lesson-complete-circle">
              <div className="lesson-complete-fire">
                <div className="lesson-complete-red">
                  <img src={redflame} alt="redflame" />
                </div>
                <div className="lesson-complete-yellow">
                  <img src={yellowflame} alt="yellowflame" />
                </div>
              </div>
              <div className="lesson-complete-message">
                +{this.props.xp} XP

              </div>
            </div>
            <div className="lesson-complete-bottom-text">
              <div className="lesson-complete-bold"> Great Job! </div>
              {this.renderStats()}
            </div>
            {this.renderCompleteButtons()}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  if (window.location.pathname.split('/')[1] === 'createlesson') {
    console.log('in lesson making');
    return {
      lesson: reduxState.lessonMaking,
    };
  } else {
    return {
      user: reduxState.user,
      lesson: reduxState.lesson.currentLesson,
      lessonMaking: reduxState.lessonMaking,
    };
  }
}

export default withRouter(connect(mapStateToProps, {
})(LessonComplete));
