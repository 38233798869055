import React from 'react';
import { connect } from 'react-redux';

const Fallback = (props) => {
  props.history.push('/home');
  return (
    <div>URL NOT FOUND</div>
  );
};

export default connect()(Fallback);
