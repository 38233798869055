/*
    Fuction to calculate coins gained
*/
export function calculateCoins(mode, attempts) {
  console.log('calculating coins with mode', mode, 'attempts', attempts);
  let coins = 0;
  if (mode === 'activity') { // assign for completed activity
    /*
      param = attempts (Integer)
      */
    if (attempts === 1) {
      coins = 20;
    } else {
      coins = 5;
    }
  } else if (mode === 'completed-lesson') {
    /*
      param = null
      */
    coins = 40;
  } else if (mode === 'random_activity') {
    /*
      param = depth (Integer)
      */
    coins = Math.ceil(20 * (1.5 ** attempts));
  }
  return coins;
}

export function calculateAvailableCoins(lesson) {
  let baseline = 40;

  lesson.pages.forEach((page) => {
    if (page.page_type !== 'content') {
      baseline += 20;
    }
  });

  return baseline;
}
