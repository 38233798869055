/* eslint-disable array-callback-return */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Tone from 'tone';
import Vex from '../Vex';
import PlayButton from '../Exercises/PlayButton';
import { vexToToneFormat } from '../LetterType';
// import { registerActivityCompletion } from '../../../actions';

class ListeningMCQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerChoices: this.props.page.info.mc.options,
      correctAnswer: this.props.page.info.mc.correct_answer,
      correctAnswerClicked: false,
      timeSignature: this.props.page.info.mc.time_signature,
      clef: this.props.page.info.mc.clef,
      divId: ['1', '2', '3', '4'],
      vexNotes: null,
      completed: false,
      blueShadow: '',
      orangeShadow: '',
      pinkShadow: '',
      optionClicked: 0,
    };
  }

  componentDidUpdate() {
    console.log('props changes, component update called');
  }

  processVexNotes = () => {
    const vexNotes = [];
    this.props.page.info.mc.options.map((notes) => {
      const notesObj = [];
      notes.map((note) => {
        notesObj.push({ keys: [note], duration: 'q' });
      });
      vexNotes.push(notesObj);
    });
    console.log('vexNotes', vexNotes);
    this.setState({ vexNotes });
  }

  playMusic = () => {
    const sampler = new Tone.Sampler({
      urls: {
        A0: 'A0.mp3',
        C1: 'C1.mp3',
        'D#1': 'Ds1.mp3',
        'F#1': 'Fs1.mp3',
        A1: 'A1.mp3',
        C2: 'C2.mp3',
        'D#2': 'Ds2.mp3',
        'F#2': 'Fs2.mp3',
        A2: 'A2.mp3',
        C3: 'C3.mp3',
        'D#3': 'Ds3.mp3',
        'F#3': 'Fs3.mp3',
        A3: 'A3.mp3',
        C4: 'C4.mp3',
        'D#4': 'Ds4.mp3',
        'F#4': 'Fs4.mp3',
        A4: 'A4.mp3',
        C5: 'C5.mp3',
        'D#5': 'Ds5.mp3',
        'F#5': 'Fs5.mp3',
        A5: 'A5.mp3',
        C6: 'C6.mp3',
        'D#6': 'Ds6.mp3',
        'F#6': 'Fs6.mp3',
        A6: 'A6.mp3',
        C7: 'C7.mp3',
        'D#7': 'Ds7.mp3',
        'F#7': 'Fs7.mp3',
        A7: 'A7.mp3',
        C8: 'C8.mp3',
      },
      release: 1,
      baseUrl: 'https://tonejs.github.io/audio/salamander/',
      onload: () => {
        const now = Tone.now();
        const notes = vexToToneFormat(this.state.answerChoices[this.state.correctAnswer]);
        console.log('playMusic called', notes);
        for (let i = 0; i < notes.length; i += 1) {
          const diff = 0.5 * i;
          const note = notes[i];
          console.log(note);
          console.log(diff);
          sampler.triggerAttackRelease(note, '4n', now + diff);
        }
      },
    }).toDestination();
  }

  submitAnswer = () => {
    if (this.state.correctAnswerClicked) {
      // correct answer, so change outline color to green
      this.props.getAnswer(this.state.optionClicked); // send answer to server
      if (this.state.optionClicked === 0) {
        this.setState({ orangeShadow: '0 0 50px 30px #9ef509' });
      } else if (this.state.optionClicked === 1) {
        this.setState({ pinkShadow: '0 0 50px 30px #9ef509' });
      } else if (this.state.optionClicked === 2) {
        this.setState({ blueShadow: '0 0 50px 30px #9ef509' });
      } else {
        this.setState({ purpleShadow: '0 0 50px 30px #9ef509' });
      }
    } else {
      // wrong answer, so change outline color to red
      this.props.getAnswer(this.state.optionClicked); // send answer to server
      if (this.state.optionClicked === 0) {
        this.setState({ orangeShadow: '0 0 40px 20px #D33131' });
      } else if (this.state.optionClicked === 1) {
        this.setState({ pinkShadow: '0 0 40px 20px #D33131' });
      } else if (this.state.optionClicked === 2) {
        this.setState({ blueShadow: '0 0 40px 20px #D33131' });
      } else {
        this.setState({ purpleShadow: '0 0 40px 20px #D33131' });
      }
    }
    this.setState({ completed: true });
  }

  selectOption = (n) => {
    if (!this.state.completed) {
      let pinkShadow = '';
      let orangeShadow = '';
      let blueShadow = '';
      let purpleShadow = '';

      if (n === 1) {
        orangeShadow = '0 0 40px 20px #FF9400';
      } else if (n === 2) {
        pinkShadow = '0 0 40px 20px #E47EA6';
      } else if (n === 3) {
        blueShadow = '0 0 40px 20px #14D4F4';
      } else {
        purpleShadow = '0 0 40px 20px #A560EB';
      }

      this.setState({
        pinkShadow, orangeShadow, blueShadow, purpleShadow,
      });

      document.getElementById('result').innerHTML = '';
      if (n - 1 === this.props.page.info.mc.correct_answer) {
        this.setState({ correctAnswerClicked: true, optionClicked: n - 1 });
      } else {
        this.setState({ correctAnswerClicked: false, optionClicked: n - 1 });
      }
      console.log('OPTION CLICKED: ', n - 1);
    }
  }

  renderBottomButton = () => {
    if (!this.state.completed) {
      return (
        <div className="next-button-holder">
          <button className="next-button" type="button" onClick={this.submitAnswer}>Submit</button>
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    if (this.state.vexNotes === null) {
      this.processVexNotes();
    }

    if (this.state.vexNotes !== null) {
      return (
        <div key={this.props.page.info.mc} className="listening-mc-page">
          <div className="listening-mc-main">
            {/* <div className="activity-type">Listening (multiple choice)</div> */}
            <div className="instructions">{this.props.page.info.mc.instructions}</div>
            <div className="listening-play-bar">
              <PlayButton playMusic={this.playMusic} />
              <div className="listening-bar" />
            </div>

            <div className="mc-options">
              <div className="mc-toprow">
                <button type="button"
                  id="orange-answer"
                  style={{ boxShadow: this.state.orangeShadow }}
                  className="mc-answer-button"
                  onClick={() => {
                    this.selectOption(1);
                  }}
                >
                  <Vex
                    notes={this.state.vexNotes[0]}
                    timeSignature="4/4"
                    clef={this.props.page.info.mc.cleftype}
                    keySignature="C"
                    divId="listening-vex-1"
                    color="black"
                    mode="listening"
                    setMeasureInfo={() => {}}
                    correctnessArray={[]}
                    rerender={false}
                    setMeasureCount={() => { console.log('setMeasureCount called in listening'); }}
                  />
                </button>
                <button type="button"
                  style={{ boxShadow: this.state.pinkShadow }}
                  id="pink-answer"
                  className="mc-answer-button"
                  onClick={() => {
                    this.selectOption(2);
                  }}
                >
                  <Vex
                    notes={this.state.vexNotes[1]}
                    timeSignature="4/4"
                    clef={this.props.page.info.mc.cleftype}
                    keySignature="C"
                    divId="listening-vex-2"
                    color="black"
                    mode="listening"
                    setMeasureInfo={() => {}}
                    correctnessArray={[]}
                    rerender={false}
                    setMeasureCount={() => { console.log('setMeasureCount called in listening'); }}
                  />
                </button>
              </div>
              <div className="mc-bottomrow">
                <button type="button"
                  id="blue-answer"
                  style={{ boxShadow: this.state.blueShadow }}
                  className="mc-answer-button"
                  onClick={() => {
                    this.selectOption(3);
                  }}
                >
                  <Vex
                    notes={this.state.vexNotes[2]}
                    timeSignature="4/4"
                    clef={this.props.page.info.mc.cleftype}
                    keySignature="C"
                    divId="listening-vex-3"
                    color="black"
                    mode="listening"
                    setMeasureInfo={() => {}}
                    correctnessArray={[]}
                    rerender={false}
                    setMeasureCount={() => { console.log('setMeasureCount called in listening'); }}
                  />
                </button>
                <button type="button"
                  className="mc-answer-button"
                  id="purple-answer"
                  style={{ boxShadow: this.state.purpleShadow }}
                  onClick={() => {
                    this.selectOption(4);
                  }}
                >
                  <Vex
                    notes={this.state.vexNotes[3]}
                    timeSignature="4/4"
                    clef={this.props.page.info.mc.cleftype}
                    keySignature="C"
                    divId="listening-vex-4"
                    color="black"
                    mode="listening"
                    setMeasureInfo={() => {}}
                    correctnessArray={[]}
                    rerender={false}
                    setMeasureCount={() => { console.log('setMeasureCount called in listening'); }}
                  />
                </button>
              </div>
            </div>
            <div id="result" style={{ height: '3vh' }}> </div>
          </div>
          {this.renderBottomButton()}
        </div>
      );
    } else {
      return (
        <div>Loading...</div>
      );
    }
  }
}

function mapStateToProps(reduxState) {
  return {
    user: reduxState.user,
  };
}

export default connect(mapStateToProps)(ListeningMCQuestion);
