/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LCPages from './LCPages';
import { initializeLesson, saveLesson } from '../../../actions';

class LCInterface extends Component {
  // componentDidMount() {
  //   const lesson = {
  //     title: document.getElementById('lesson-title-input').value,
  //     document: document.getElementById('lesson-description-input').value,
  //   };
  //   console.log('lessonid is', this.props.lessonid);
  //   if (!this.props.lessonid) {
  //     this.props.saveLesson(lesson, this.props.class._id, this.props.user.id);
  //   }
  // }

  render() {
    return (
      <div className="lesson-creation-main-page">
        <div className="title-panel-container">
          <div className="lesson-creation-text-section">
            <input type="text" id="lesson-title-input" defaultValue={this.props.lesson.title || 'Lesson Title'} />
            <input type="text" id="lesson-description-input" defaultValue={this.props.lesson.description || 'lesson description goes here'} />
          </div>
        </div>
        <LCPages saveLessonFunc={this.props.saveLessonFunc} />
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    lessonid: reduxState.lessonMaking.lessonid,
    lesson: reduxState.lessonMaking,
    class: reduxState.class,
    user: reduxState.user,
  };
}

export default connect(mapStateToProps, { initializeLesson, saveLesson })(LCInterface);
