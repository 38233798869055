import React from 'react';
import { NavLink } from 'react-router-dom';
import suffixOrdinal from '../utility';
import WarblerCoin from '../../../../../images/profile/woin.svg';

const StudentFinalResults = ({
  unknown, finalScore, finalPlace, resetGame,
}) => {
  return (
    <div className="result-background-student-final">
      <h1 className="result-final-title">You did it!</h1>
      <h2 className="result-final-end-place">You ended in <span className="bold">{ suffixOrdinal(finalPlace)} place.</span></h2>
      <div className="result-final-points-box">{finalScore} POINTS</div>
      <div className="result-final-ic-xp-box">+{finalScore} XP</div>
      <div className="result-final-ic-coins-box">
        +{finalScore / 10}
        <img className="result-final-ic-woin-image" src={WarblerCoin} alt="woin" />
      </div>
      {unknown ? <button onClick={() => { resetGame(); }} type="button" className="red-button" id="return-to-lobby-student">Return to lobby</button>
        : (
          <NavLink to="/userhome">
            <button type="button" className="red-button" id="return-to-lobby-student">Return to lobby</button>
          </NavLink>
        )}

    </div>
  );
};

export default StudentFinalResults;
