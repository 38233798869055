/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

const StudentListeningMCDisplay = ({ answerNumber, guessAnswer }) => {
  const [answer, setAnswer] = useState(0);
  return (
    <div className="ic-student-answer-flex">
      <div className="ic-student-answer-row">
        <div
          className={`${answer === answerNumber.answer1 ? 'selected-ic-choice' : (answer === 0 ? '' : 'nonselected-ic-choice')} ic-answer-button color-red`}
          type="button"
          onClick={() => { setAnswer(answerNumber.answer1); }}
        >1
        </div>
        <div
          className={`${answer === answerNumber.answer2 ? 'selected-ic-choice' : (answer === 0 ? '' : 'nonselected-ic-choice')} ic-answer-button color-gold`}
          type="button"
          onClick={() => { setAnswer(answerNumber.answer2); }}
        >2
        </div>
      </div>
      <div className="ic-student-answer-row">
        <div
          className={`${answer === answerNumber.answer3 ? 'selected-ic-choice' : (answer === 0 ? '' : 'nonselected-ic-choice')} ic-answer-button color-calm-blue`}
          onClick={() => { setAnswer(answerNumber.answer3); }}
        >3
        </div>
        <div
          className={`${answer === answerNumber.answer4 ? 'selected-ic-choice' : (answer === 0 ? '' : 'nonselected-ic-choice')} ic-answer-button color-purple`}
          type="button"
          onClick={() => { setAnswer(answerNumber.answer4); }}
        >4
        </div>
      </div>
      <div className="ic-student-answer-bottom">
        <button className="green-button" type="button" onClick={() => { guessAnswer(answer); }}>Submit</button>
      </div>
    </div>
  );
};

export default StudentListeningMCDisplay;
