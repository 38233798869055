/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { components } from 'react-select';
import selectArrow from '../../../../../images/lessonCreation/selectArrow.svg';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={selectArrow} alt="arrow" />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
