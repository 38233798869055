/* eslint-disable react/no-unused-state */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import KeySignatureSelect from './KeySignatureSelect';
import ClefSelect from './ClefSelect';

// ASSUMING THAT THERE ARE 4 POSSIBLE ANSWERS FOR THE QUESTION.
class SightreadingQuestionCreate extends Component {
  constructor(props) {
    super(props);
    if (this.props.pageInfo !== undefined && this.props.pageInfo !== null && this.props.pageInfo.info !== null && this.props.pageInfo.info !== undefined) {
      this.state = {
        keySig: this.props.pageInfo.info.sr.key_signature,
        cleftype: this.props.pageInfo.info.sr.cleftype,
      };
    } else {
      this.state = {
        keySig: 'C',
        cleftype: 'treble',
      };
    }
  }

  updateKeySig = (ks) => {
    this.setState({ keySig: ks });
  }

  updateClef = (clef) => {
    this.setState({ cleftype: clef });
  }

  handleChange = () => {
    this.props.saveLessonFunc(false, true, null, false);
  };

  toRenderInputs = () => {
    const toRender = [];
    if (this.props.pageInfo !== undefined && this.props.pageInfo !== null && this.props.pageInfo.info !== null && this.props.pageInfo.info !== undefined) {
      for (let i = 0; i < 6; i += 1) {
        toRender.push(
          <div className="sightreading-answer-input-box-container" key={i}>
            <div className="note-title">note {i + 1}: </div>
            <input className="sr-note-input-box" key={i} defaultValue={this.props.pageInfo.info.sr.correct_answers[i].keys[0]} onBlur={this.handleChange} />
          </div>,
        );
      }
    } else {
      for (let i = 0; i < 6; i += 1) {
        toRender.push(
          <div className="sightreading-answer-input-box-container" key={i}>
            <div className="note-title">note {i + 1}: </div>
            <input className="sr-note-input-box" key={i} onBlur={this.handleChange} />
          </div>,
        );
      }
    }
    return toRender;
  }

  render() {
    return (
      <div className="nlc-question-main" id="nlc-question-sightreading">
        <div className="nlc-question-selects">
          <div className="select-container">
            <div className="select-title">Key Signature: </div>
            <KeySignatureSelect ks={this.state.keySig} func={this.updateKeySig} handleChange={this.handleChange} />
          </div>
          <div className="select-container">
            <div className="select-title">Clef Type: </div>
            <ClefSelect clef={this.state.cleftype} func={this.updateClef} handleChange={this.handleChange} />
          </div>
        </div>
        <div className="nlc-entry-inputs-flex">
          {this.toRenderInputs()}
        </div>
        <input type="hidden" className="id-hidden-input" value={this.props.pageInfo._id} />
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    lessonid: reduxState.lessonMaking.lessonid,
  };
}
export default connect(mapStateToProps)(SightreadingQuestionCreate);
