import React from 'react';

const SpecialTile = ({
  id, toPushTo, title, imageSrc, imageAlt, renderBottom, renderStar, renderImage, imageId, renderImageCallback, history,
}) => {
  return (
    <div className="class-view" id={id} onClick={() => { history.push(toPushTo); }} role="link" tabIndex={0}>
      <div className="class-view-top-section">
        <div className="star-container">
          {renderStar ? <i className="fas fa-star" id="star" /> : null}
        </div>

        <div to="/class" className="tile-view-top">{title}</div>

      </div>
      <div className="ac-special-tile-div">
        {renderImage ? <img src={imageSrc} alt={imageAlt} id={imageId} /> : renderImageCallback()}

      </div>
      {renderBottom ? <div id="infinity-view-bottom" /> : null}

    </div>
  );
};

export default SpecialTile;
