import React from 'react';
import InClassGameMain from '../InClassGameMain';

const InClassGameWrapper = () => {
  return (
    <div>
      <InClassGameMain unknown />
    </div>
  );
};

export default InClassGameWrapper;
