/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import * as Tone from 'tone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';
import ExerciseNav from '../ExerciseNav';
import NextButton from '../NextButton';
import correctAnim from '../../../../../animations/checkmark-anim.json';
import incorrectAnim from '../../../../../animations/wrong-anim.json';
import { vexToToneFormat } from '../../LetterType';
import { registerActivityCompletion } from '../../../../actions';

const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const correctStyle = {
  width: '100vw',
  height: '100vh',
  position: 'absolute',
};

const incorrectStyle = {
  width: '20vw',
  left: '50%',
  marginLeft: '-10vw',
  height: '30vh',
  position: 'absolute',
};

class Intervals extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    this.state = {
      index: 0,
      completed: false,
      answers: [],
      percentCorrect: null,
      attempts: 0,
      isStopped: true,
      animType: 'incorrect',
      continue: true,
    };
  }

  playMusic = () => {
    const sampler = new Tone.Sampler({
      urls: {
        A0: 'A0.mp3',
        C1: 'C1.mp3',
        'D#1': 'Ds1.mp3',
        'F#1': 'Fs1.mp3',
        A1: 'A1.mp3',
        C2: 'C2.mp3',
        'D#2': 'Ds2.mp3',
        'F#2': 'Fs2.mp3',
        A2: 'A2.mp3',
        C3: 'C3.mp3',
        'D#3': 'Ds3.mp3',
        'F#3': 'Fs3.mp3',
        A3: 'A3.mp3',
        C4: 'C4.mp3',
        'D#4': 'Ds4.mp3',
        'F#4': 'Fs4.mp3',
        A4: 'A4.mp3',
        C5: 'C5.mp3',
        'D#5': 'Ds5.mp3',
        'F#5': 'Fs5.mp3',
        A5: 'A5.mp3',
        C6: 'C6.mp3',
        'D#6': 'Ds6.mp3',
        'F#6': 'Fs6.mp3',
        A6: 'A6.mp3',
        C7: 'C7.mp3',
        'D#7': 'Ds7.mp3',
        'F#7': 'Fs7.mp3',
        A7: 'A7.mp3',
        C8: 'C8.mp3',
      },
      release: 1,
      baseUrl: 'https://tonejs.github.io/audio/salamander/',
      onload: () => {
        const now = Tone.now();
        const notes = vexToToneFormat(this.props.page.info.i.intervals[this.state.index]);
        console.log('playing music with notes', notes);
        for (let i = 0; i < notes.length; i += 1) {
          const diff = 0.5 * i;
          const note = notes[i];
          console.log(note);
          console.log(diff);
          sampler.triggerAttackRelease(note, '4n', now + diff);
        }
      },
    }).toDestination();
  }

  // submitAnswer = () => {
  //   const correct = this.props.page.info.i.answers[this.state.index];
  //   // const correct = this.state.answers[this.state.index];
  //   let userAnswer;
  //   if (document.getElementById('yes').checked) {
  //     console.log('user selected yes');
  //     userAnswer = 'yes';
  //   } else if (document.getElementById('no').checked) {
  //     console.log('user selected no');
  //     userAnswer = 'no';
  //   } else {
  //     userAnswer = '';
  //     console.log('none selected');
  //   }
  //   if (correct === userAnswer) {
  //     document.getElementById('result').innerHTML = 'You\'re correct!';
  //     if (this.state.index < this.props.page.info.i.intervals.length - 1) {
  //       this.setState((prevState) => {
  //         return { index: prevState.index + 1 };
  //       });
  //     } else if (this.state.index === this.props.page.info.i.intervals.length - 1) {
  //       this.setState({ completed: true });
  //     }
  //   } else {
  //     document.getElementById('result').innerHTML = 'Sorry, that\'s not correct. Please try again!';
  //   }
  // }

  processAnswer = (answer, index) => {
    if (this.state.index === index) {
      console.log('processAnswer called with answer', answer);
      let i = this.state.index;
      const { answers } = this.state;
      if (answer === this.props.page.info.i.answers[i]) {
        console.log('correct!');
        answers[i] = true;
        this.setState({
          isStopped: false,
          animType: 'correct',
          continue: false,
        });
        setTimeout(() => {
          this.setState({ isStopped: true, continue: true });
        }, 1000);
      } else {
        console.log('incorrect!');
        answers[i] = false;
        this.setState({
          isStopped: false,
          animType: 'incorrect',
          continue: false,
        });
        setTimeout(() => {
          this.setState({ isStopped: true, continue: true });
        }, 1000);
      }
      i += 1;
      this.setState({ index: i, answers });
      if (i < this.props.page.info.i.answers.length) {
        this.playMusic();
      } else {
        const percentCorrect = this.getCorrectPercent();
        this.setState({ percentCorrect });
        this.setState((prevState) => {
          return ({ attempts: prevState.attempts + 1 });
        });
        if (percentCorrect < 80) {
          this.props.registerFailure();
        } else {
          this.props.registerActivityCompletion(this.props.page._id, this.state.attempts, this.props.user.id, 'I');
        }
        this.setState({ completed: true });
      }
    }
  }

  getCorrectPercent = () => {
    let correctCount = 0;
    for (let i = 0; i < this.state.answers.length; i += 1) {
      if (this.state.answers[i]) {
        correctCount += 1;
      }
    }
    const finalPercent = parseInt(100 * (correctCount / this.state.answers.length), 10);
    return finalPercent;
  }

  renderAnswers = () => {
    if (this.state.answers.length > 0) {
      return (
        <div className="intervals-dots">{this.state.answers.map((ans) => {
          if (ans === 0) {
            return (
              <i className="fas fa-music" id="intervals-icon-2" style={{ color: '#bbb' }} />
            );
          } else if (ans) {
            return (
              <i className="fas fa-music" id="intervals-icon-2" style={{ color: '#7AC70C' }} />
            );
          } else {
            return (
              <i className="fas fa-music" id="intervals-icon-2" style={{ color: '#D33131' }} />
            );
          }
        })}
        </div>
      );
    } else {
      return (<span />);
    }
  }

  createAnswersArray = () => {
    const arr = [];
    for (let i = 0; i < this.props.page.info.i.answers.length; i += 1) {
      arr.push(0);
    }
    this.setState({ answers: arr });
  }

  renderCompletedMessage = () => {
    // calculate % correct
    const finalPercent = this.getCorrectPercent();

    let color = '#D33131';
    if (finalPercent > 80) {
      color = '#7AC70C';
    } else if (finalPercent > 50) {
      color = '#FFC300';
    }

    return (
      <div className="intervals-results">
        <div>You got </div>
        <div style={{ backgroundColor: color }} className="intervals-percent">{finalPercent}%</div>
        <div>of questions correct!</div>
      </div>
    );
  }

  goToNext = () => {
    console.log('going to next in intervals');
    this.props.goToNext(1, 'Listening-Interval');
  }

  reset = () => {
    this.setState({
      completed: false, answers: [], percentCorrect: null, index: 0,
    });
  }

  render() {
    if (this.state.answers.length === 0) {
      this.createAnswersArray();
    }
    if (!this.state.completed) {
      return (
        <div className="listening-interval-exercise">
          <ExerciseNav
            lives={this.props.lives}
            percentage={this.props.percentage}
            xp={this.props.xp}
            page={this.props.page}
            infinity={this.props.infinity}
            level={this.props.level}
            type={this.props.type}
            changePage={this.props.changePage}
            pageCount={this.props.pageCount}
            currentPage={this.props.currentPage}
          />
          <div>{this.renderAnswers()}</div>
          <div className="listening-main-page">
            {this.state.isStopped ? null
              : (
                <Lottie
                  className={this.state.animType === 'correct' ? 'correct-anim' : 'incorrect-anim'}
                  options={{ ...defaultOptions, animationData: this.state.animType === 'correct' ? correctAnim : incorrectAnim }}
                  style={this.state.animType === 'correct' ? correctStyle : incorrectStyle}
                  isStopped={this.state.isStopped}
                />
              )}
            <div className="instructions">{this.props.page.info.i.instructions} {this.props.page.info.i.interval_text}.</div>
            <div className="listening-play-bar">
              <div className="play-background" type="button" onClick={this.playMusic}>
                <FontAwesomeIcon icon={faPlay} id="play-intervals-button" />
              </div>
              <div className="listening-bar" />
            </div>
            <div className="interval-options">
              <div className="interval-choice"
                id="yes-div"
                onClick={() => {
                  console.log('calling from yes');
                  if (this.state.continue) {
                    this.processAnswer(true, this.state.index);
                  }
                }}
              >
                {/* <input type="radio" id="yes" name="interval" value="yes" /> */}
                <label className="option-label" htmlFor="yes">Yes</label>
              </div>
              <div className="interval-choice"
                id="no-div"
                onClick={() => {
                  if (this.state.continue) {
                    this.processAnswer(false, this.state.index);
                  }
                }}
              >
                {/* <input type="radio" id="no" name="interval" value="no" /> */}
                <label htmlFor="no" className="option-label">No</label>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.percentCorrect < 80) {
      return (
        <div className="listening-interval-exercise">
          <ExerciseNav lives={this.props.lives} xp={this.props.xp} />
          <div>{this.renderAnswers()}</div>
          <div className="listening-main-page">
            <div>{this.renderCompletedMessage()}</div>
          </div>

          <div className="next-button-holder">
            <button type="submit" className="next-button" onClick={this.reset}>Try Again</button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="listening-interval-exercise">
          <ExerciseNav lives={this.props.lives} xp={this.props.xp} />
          <div className="listening-main-page">
            <div>{this.renderCompletedMessage()}</div>
          </div>
          <div>{this.renderAnswers()}</div>
          <NextButton goToNext={this.goToNext} />
        </div>
      );
    }
  }
}

function mapStateToProps(reduxState) {
  return {
    user: reduxState.user,
  };
}

export default connect(mapStateToProps, { registerActivityCompletion })(Intervals);
