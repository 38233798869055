import React from 'react';
import { NavLink } from 'react-router-dom';
import BarFinalResult from './BarFinalResult';

const TeacherFinalResults = ({ names, isAThird, renderDetails }) => {
  const mql = window.matchMedia('(max-width: 600px)');
  const mobileView = mql.matches;
  console.log('names', names);

  return (
    <div className="confetti-background-final full-screen flex-center-screen">
      <h1 className="final-results-teacher-title">FINAL RESULTS</h1>
      <div className="bar-section-final">
        <BarFinalResult place={mobileView ? 1 : 2}
          name={mobileView ? names[0].name : names[1].name}
          mobileView={mobileView}
        />
        <BarFinalResult place={mobileView ? 2 : 1}
          name={mobileView ? names[1].name : names[0].name}
          mobileView={mobileView}
        />
        {isAThird
          ? (
            <BarFinalResult place={3}
              name={names[2].name}
              mobileView={mobileView}
            />
          )
          : (
            <BarFinalResult place={-1}
              mobileView={mobileView}
              name=""
            />
          )}
      </div>
      <div className="final-results-button-section">
        <button type="button" id="render-details-button" className="green-button" onClick={() => { renderDetails(); }}>View Detailed Results</button>
        <NavLink to="/userhome">
          <button type="button" id="return-to-lobby-teacher" className="red-button">Return to lobby</button>
        </NavLink>
      </div>
    </div>
  );
};
export default TeacherFinalResults;
