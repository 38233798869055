/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone, faPlusCircle, faTrashAlt, faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import LCIndividualPage from './LCIndividualPage';
import {
  mapPagesToState, addPageToLC, removePageFromPages, copyPageinLessonCreation,
} from '../../../actions';
import blackx from '../../../../images/black_x.svg';
import sixteenthNote from '../../../../images/sixteenthNote.svg';
import eighthNote from '../../../../images/eighthNote.svg';
import quarterNote from '../../../../images/quarterNote.svg';
import halfNote from '../../../../images/halfNote.svg';
import wholeNote from '../../../../images/wholeNote.svg';
import doubleWholeNote from '../../../../images/doubleWholeNote.svg';
import quarterRest from '../../../../images/quarterRest.svg';
import dottedNote from '../../../../images/dottedNote.svg';
import doubleDottedNote from '../../../../images/doubleDottedNote.svg';

// There needs to be functionality in this component for adding new pages.

class LCPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: 0,
      showCheatSheet: false,
      cheatSheetPage: 'duration',
    };
    this.removePage = this.removePage.bind(this);
    this.addPage = this.addPage.bind(this);
    if (this.props.lessonPages.length < 1) {
      this.props.addPageToLC(
        {
          page_type: 'Content',
          info: {
            title: 'Type your title here',
            description: 'Content will go here. Type as how you would want it to display.',
          },
        },
      );
    }
  }

  findElementWithAttribute = (array, attr, value) => {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  removePage = (event) => {
    if (this.props.lessonPages.length > 1) {
      let indexToDelete;
      toast.error('Deleted activity.', {
        position: 'bottom-right',
        autoClose: 1700,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // keep track of the updates made to other pages.
      this.props.saveLessonFunc(false, false);
      if (event.target.tagName === 'path') {
        console.log('tag name was path.');
        indexToDelete = Number(event.target.parentNode.getAttribute('value'));
      } else {
        indexToDelete = Number(event.target.getAttribute('value'));
      }
      console.log('deleting index', indexToDelete);
      const newPages = this.props.lessonPages;
      console.log('prior to deletion is: ', newPages);
      const deleted = newPages.splice(indexToDelete, 1);
      console.log('deleted item: ', deleted);
      console.log(newPages, 'after deletion');
      // this.props.mapPagesToState(newPages);
      this.props.removePageFromPages(indexToDelete);
      this.setState((prevState) => prevState.numPages - 1);
    }
  }

  copyPage = (event) => {
    let pageIndex;
    toast.warn('Copied activity.', {
      position: 'bottom-right',
      autoClose: 1700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (event.target.tagName === 'path') {
      pageIndex = Number(event.target.parentNode.getAttribute('value'));
    } else {
      pageIndex = Number(event.target.getAttribute('value'));
    }
    // Get the index of the page.
    console.log('copying page index: ', pageIndex);
    // Call function to go in and copy that index page to the end of the list of pages.
    this.props.copyPageinLessonCreation(pageIndex, this.props.lessonPages);
    this.setState({ });
  }

  addPage = (pageType = 'Content') => {
    console.log('adding page with page type: ', pageType);
    const newPage = {
      page_type: pageType,
      info: {
        title: 'Type your title here',
        description: 'Content will go here. Type as how you would want it to display.',
      },
    };
    this.props.addPageToLC(newPage);
    this.setState((prevState) => prevState.numPages + 1);
  }

  openCheatSheet = () => {
    this.setState({
      showCheatSheet: true,
    });
  }

  closeCheatSheet = () => {
    this.setState({
      showCheatSheet: false,
    });
  }

  changePage = (props) => {
    document.querySelector('div.selected').classList.remove('selected');
    let result = null;
    result = props.target.parentElement;
    result.classList.add('selected');
    if (result.id === 'duration') {
      this.setState({ cheatSheetPage: 'duration' });
    } else if (result.id === 'pitch') {
      this.setState({ cheatSheetPage: 'pitch' });
    }
  }

  addActivity = () => {
    toast.info('Added actvity!', {
      position: 'bottom-right',
      autoClose: 1700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    this.addPage('Content');
  }

  render() {
    let count = -1;
    const pagesList = this.props.lessonPages.map((page) => {
      count += 1;
      console.log('making page with info: ', page);
      return (
        <LCIndividualPage
          key={Math.random()}
          init_page_type={page.page_type}
          saveLessonFunc={this.props.saveLessonFunc}
          deleteButton={(
            <FontAwesomeIcon
              className="lc-page-icon"
              icon={faTrashAlt}
              value={this.props.lessonPages.indexOf(page)}
              role="button"
              aria-label="del-page"
              tabIndex={0}
              onClick={this.removePage}
            />
        )}
          copyButton={(
            <FontAwesomeIcon
              className="lc-page-icon"
              icon={faClone}
              value={this.props.lessonPages.indexOf(page)}
              role="button"
              aria-label="copy-page"
              tabIndex={0}
              onClick={this.copyPage}
              page_type={this.page_type}
            />
        )}
          value={count}
          init_info={page}
        />
      );
    });
    return (
      <div className="lesson-creation-all-pages">
        <div className="lesson-creation-pages-render">
          {pagesList}
        </div>
        {/* {this.state.pagesList} */}
        <div className="add-page-button-side">
          <div className="add-page-button-side-absolute">
            <div type="button" className="add-page-button" onClick={() => this.addActivity()}>
              <FontAwesomeIcon icon={faPlusCircle} className="add-page-plus-circle" />
            </div>
            <div className="add-page-button-hidden-text">
              Add Activity
            </div>
          </div>
          <div className="hide-page-button-side-absolute">
            <div type="button" className="hide-page-button" onClick={() => this.openCheatSheet()}>
              <FontAwesomeIcon icon={faQuestionCircle} className="add-page-plus-circle" />
            </div>
            <div className="hide-page-button-hidden-text">
              Help
            </div>
          </div>
        </div>
        {this.state.cheatSheetPage === 'duration' ? (
          <div>
            <Modal
              isOpen={this.state.showCheatSheet}
              onRequestClose={this.closeCheatSheet}
              className="Modal modal cheat-sheet"
              overlay="overlay"
              contentLabel="cheat-sheet"
              ariaHideApp={false}
            >
              <div className="code-modal-x">
                <img alt="x-out" className="code-modal-render-x" src={blackx} onClick={this.closeCheatSheet} />
              </div>
              <div className="cheat-sheet-title">Cheat Sheet</div>
              <div className="cheat-sheet-toggle-container">
                <div className="selected" id="duration">
                  <span className="tab-text" role="tab" tabIndex={0} onClick={this.changePage}>Duration</span>
                </div>
                <div id="pitch">
                  <span className="tab-text" role="tab" tabIndex={0} onClick={this.changePage}>Pitch</span>
                </div>
              </div>
              <div className="cheat-sheet-subtitle">Base Code:</div>
              <div className="cheat-sheet-top-row-d">
                <div className="cheat-sheet-top-item-d">
                  <img src={sixteenthNote} alt="sixteenth-note" />
                  <br />
                  <span className="note-abrev">&apos;16&apos;</span>
                  <div>16th note</div>
                </div>
                <div className="cheat-sheet-top-item-d">
                  <img src={eighthNote} alt="eighth-note" />
                  <br />
                  <span className="note-abrev">&apos;8&apos;</span>
                  <div>8th note</div>
                </div>
                <div className="cheat-sheet-top-item-d">
                  <img src={quarterNote} alt="quarter-note" />
                  <br />
                  <span className="note-abrev">&apos;q&apos;</span>
                  <div>quarter note</div>
                </div>
                <div className="cheat-sheet-top-item-d">
                  <img src={halfNote} alt="half-note" />
                  <br />
                  <span className="note-abrev">&apos;h&apos;</span>
                  <div>half note</div>
                </div>
                <div className="cheat-sheet-top-item-d">
                  <img src={wholeNote} alt="whole-note" id="whole-note" />
                  <br />
                  <span className="note-abrev">&apos;w&apos;</span>
                  <div>whole note</div>
                </div>
                <div className="cheat-sheet-top-item-d">
                  <img src={doubleWholeNote} alt="double-whole-note" id="double-whole-note" />
                  <br />
                  <span className="note-abrev">&apos;1/2&apos;</span>
                  <div>double whole note</div>
                </div>
              </div>
              <div className="cheat-sheet-bottom-row-d">
                <div className="cheat-sheet-bottom-item-d">
                  <div>Add <span className="note-abrev-d">&apos;r&apos;</span> to make it a rest:</div>
                  <img src={quarterRest} alt="quarter-rest" />
                  <div>ex: &apos;qr&apos; = quarter rest</div>
                </div>
                <div className="cheat-sheet-bottom-item-d">
                  <div>Add <span className="note-abrev-d">&apos;d&apos;</span> or <span className="note-abrev-d">&apos;dd&apos;</span> to make the note dotted/double-dotted:</div>
                  <div className="cheat-sheet-dotted-container">
                    <div className="cheat-sheet-dotted-item">
                      <img src={dottedNote} alt="dotted-note" />
                      <div>ex: &apos;qd&apos; = dotted quarter note</div>
                    </div>
                    <div className="cheat-sheet-dotted-item">
                      <img src={doubleDottedNote} alt="double-dotted-note" />
                      <div>ex: &apos;qdd&apos; = double-dotted quarter note</div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        ) : (
          <div>
            <Modal
              isOpen={this.state.showCheatSheet}
              onRequestClose={this.closeCheatSheet}
              className="Modal modal cheat-sheet"
              overlay="overlay"
              contentLabel="cheat-sheet"
              ariaHideApp={false}
            >
              <div className="code-modal-x">
                <img alt="x-out" className="code-modal-render-x" src={blackx} onClick={this.closeCheatSheet} />
              </div>
              <div className="cheat-sheet-title">Cheat Sheet</div>
              <div className="cheat-sheet-toggle-container">
                <div className="selected" id="duration">
                  <span className="tab-text" role="tab" tabIndex={0} onClick={this.changePage}>Duration</span>
                </div>
                <div id="pitch">
                  <span className="tab-text" role="tab" tabIndex={0} onClick={this.changePage}>Pitch</span>
                </div>
              </div>
              <div className="cheat-sheet-subtitle">
                General Format: &nbsp;
                <span className="cheat-sheet-yellow-highlight">notename</span> +
                <span className="cheat-sheet-purple-highlight">modifiers</span>/
                <span className="cheat-sheet-blue-highlight">octave</span>
              </div>
              <div className="cheat-sheet-top-row-p">
                <div className="cheat-sheet-top-item-p">
                  <div className="cheat-sheet-top-item-title">
                    <span className="cheat-sheet-yellow-highlight" id="cheat-sheet-top-item-title">valid notes</span>
                  </div>
                  <div className="cheat-sheet-valid-notes">c, d, e, f, g, a, b</div>
                </div>
                <div className="cheat-sheet-top-item-p">
                  <div className="cheat-sheet-top-item-title">
                    <span className="cheat-sheet-purple-highlight">valid modifiers</span>
                  </div>
                  <div className="cheat-sheet-modifiers-container">
                    <div className="cheat-sheet-modifiers-item">
                      <span className="cheat-sheet-purple-highlight"> &apos;#&apos;</span>
                      <div className="cheat-sheet-modifiers-text">sharp</div>
                    </div>
                    <div className="cheat-sheet-modifiers-item">
                      <span className="cheat-sheet-purple-highlight"> &apos;##&apos;</span>
                      <div className="cheat-sheet-modifiers-text">double sharp</div>
                    </div>
                    <div className="cheat-sheet-modifiers-item">
                      <span className="cheat-sheet-purple-highlight"> &apos;b&apos;</span>
                      <div className="cheat-sheet-modifiers-text">flat</div>
                    </div>
                    <div className="cheat-sheet-modifiers-item">
                      <span className="cheat-sheet-purple-highlight"> &apos;bb&apos;</span>
                      <div className="cheat-sheet-modifiers-text">double flat</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cheat-sheet-bottom-row-p">
                <div className="cheat-sheet-bottom-item-p" id="green-box">
                  <div className="cheat-sheet-bottom-box-title">correct examples:</div>
                  <div>c/4</div>
                  <div>d#/2</div>
                  <div>gb/1</div>
                  <div>abb/6</div>
                </div>
                <div className="cheat-sheet-bottom-item-p" id="red-box">
                  <div className="cheat-sheet-bottom-box-title">incorrect examples:</div>
                  <div>c / 4</div>
                  <div>E/ 3</div>
                  <div>E3bb</div>
                  <div>3bb</div>
                </div>
              </div>
            </Modal>
          </div>
        )}
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    lessonPages: reduxState.lessonMaking.pages,
  };
}

export default connect(mapStateToProps, {
  mapPagesToState, addPageToLC, removePageFromPages, copyPageinLessonCreation,
})(LCPages);
