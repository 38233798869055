import React, { Component } from 'react';
import Select from 'react-select';
import DropdownIndicator from './dropdownIndicator';

// What should be included here?
const TSoptions = ['4/4', '3/4', '2/3'];

const customStyles = {

  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#DEDCDC',
    width: '139px',
    height: '37px',

  }),

  singleValue: (provided, state) => ({
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '23px',
    lineHeight: '23px',
    letterSpacing: '0.03em',
    color: '#757575',
  }),
  menuList: (provided, state) => ({
    ...provided,
    height: '110px',
    fontFamily: 'Josefin Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '23px',
    lineHeight: '23px',
    letterSpacing: '0.03em',
    color: '#757575',
    paddingTop: '0px',
    paddingBottom: '0px',
    boxSizing: 'border-box',
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
  option: (provided, state) => ({
    ...provided,
    borderTop: '1px solid #DEDCDC',
    borderBottom: '1px solid #DEDCDC',
    boxSizing: 'border-box',
    backgroundColor: '#F3F3F3',
    color: '#757575',
    height: '37px',
  }),
};

// eslint-disable-next-line react/prefer-stateless-function
class TimeSignatureSelect extends Component {
  setSelected = (selectedOption) => {
    // eslint-disable-next-line react/no-unused-state
    this.props.func(selectedOption.label);
    this.props.handleChange();
  }

  makeOptions = () => {
    const toReturn = [];
    for (let i = 0; i < TSoptions.length; i += 1) {
      toReturn.push({ value: i, label: TSoptions[i] });
    }
    return toReturn;
  }

  render() {
    console.log(this.props);
    const options = this.makeOptions();
    console.log('filter result', options.filter((option) => option.label === this.props.ts));
    return (
      <div className="time-signature-select">
        <Select
          className="react-select-container-ts"
          classNamePrefix="react-select"
          key={false}
          components={{ DropdownIndicator }}
          styles={customStyles}
          options={options}
          value={options.filter((option) => option.label === this.props.ts)}
          onChange={this.setSelected}
          isSearchable={false}
        />
      </div>
    );
  }
}

export default TimeSignatureSelect;
