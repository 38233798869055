/* eslint-disable no-param-reassign */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import {
  signupUser, createClass, addStudentToClass, setError,
} from '../../actions/index';
import Loading from './Loading';
import ErrorNotification from './ErrorNotification';
import NewNavBar from '../cs98_components/NewNavBar';

class NewUserPage extends Component {
  constructor() {
    super();
    this.state = {
      studentChecked: false,
      loading: false,
      error: null, // Only allowable and usable because we can do the front end validation live but realistically this should change.
    };
  }

  checkboxes = (props) => {
    const boxes = document.getElementsByClassName('checkbox');
    console.log(boxes);
    Object.values(boxes).forEach((element) => {
      if (element.id !== props.target.id) {
        element.checked = false;
      }
      if (element.id === 'student' && element.checked === true) {
        this.setState({ studentChecked: true });
      }
      if ((element.id === 'teacher' && element.checked === true) || (element.id === 'student' && element.checked === false)) {
        this.setState({ studentChecked: false });
      }
    });
  }

  makeNewUser = () => {
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;
    const email = document.getElementById('email').value;
    const isStudent = this.state.studentChecked;
    const isTeacher = document.getElementById('teacher').checked;
    const firstname = document.getElementById('firstname').value;
    const lastname = document.getElementById('lastname').value;

    if (username && password && email) {
      if (password.length > 5) {
        if (username.length > 10) {
          this.props.setError(702); // Username must be less than 10 characters.
          return;
        }
        if (isStudent || isTeacher) {
          this.setState({ loading: true });
          if (isStudent === true) {
            const user = {
              username,
              password,
              firstname,
              lastname,
              email,
              type: 'student',
            };
            console.log('student', user);
            this.props.signupUser(user, this.props.history);
          } else if (isTeacher === true) {
            const user = {
              username,
              firstname,
              lastname,
              password,
              email,
              type: 'teacher',
            };
            console.log('teacher', user);
            this.props.signupUser(user, this.props.history);
          }
        } else {
          this.props.setError(701); // Must select an account type
        }
      } else {
        this.props.setError(703); // Password must be at least 6 characters
      }
    } else if (this.state.error === 'Invalid Email') {
      this.props.setError(704); // Can't sign up with Invalid Email
    } else {
      this.props.setError(705); // Must fill all fields
    }
    if (email && isTeacher) {
      this.props.createClass(email);
    }
  }

  renderError = () => {
    if (this.state.error !== null) {
      return (
        <div className="error">{this.state.error}</div>
      );
    } else {
      return (
        <div />
      );
    }
  }

  renderSignUpButton = () => {
    if (this.state.loading) {
      return (
        <div />
      );
    } else {
      return (
        <button className="sign-up-button orange" type="button" onClick={this.makeNewUser}>SIGN UP</button>
      );
    }
  }

  validateEmail = (e) => {
    if (validator.isEmail(e.target.value)) {
      this.setState({ error: null });
    } else {
      this.setState({ error: 'Invalid Email' });
    }
  }

  render() {
    return (
      <div className="new-user-main">
        <NewNavBar
          renderLogo
          items={[
            {
              text: 'Log in',
              link: '/login',
              icon: 'fa fa-sign-in',
            },
          ]}
          dropdownItems={[
            {
              text: 'Teacher Info',
              link: '/home/teachers',
            },
            {
              text: 'Student Info',
              link: '/home/students',
            },
            {
              text: 'About the Team',
              link: '/about',
            },
          ]}
          dropdownIcon="fas fa-book-open"
        />
        <div className="new-user-container">
          <div className="login-title">Sign up</div>
          <div className="get-name-container">
            <input className="login-input-half" type="text" id="firstname" placeholder="first name" />
            <input className="login-input-half" type="text" id="lastname" placeholder="last name" />
          </div>
          <div className="get-username-container">
            <input className="login-input" type="text" id="username" placeholder="username" />
          </div>
          <div className="get-email-container">
            <input className="login-input" type="text" id="email" placeholder="email" onChange={this.validateEmail} />
          </div>
          <div className="get-password-container">
            <input className="login-input" type="password" id="password" placeholder="password" />
          </div>
          <div className="get-user-type">
            <div className="student-checkbox-type">
              <input type="radio" id="student" className="checkbox" onChange={this.checkboxes} />
              <div className="radio-text">I&apos;m a student</div>
            </div>
            <div className="teacher-checkbox-type">
              <input type="radio" id="teacher" className="checkbox" onChange={this.checkboxes} />
              <div className="radio-text">I&apos;m a teacher</div>
            </div>
          </div>
          <div className="new-user-bottom">
            <Loading loading={this.state.loading} />
            <ErrorNotification />
            {this.renderSignUpButton()}
          </div>
        </div>

        <div className="login-bottom-text">Don&apos;t let a Warbler be better at music than you.</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication: state.auth.authentication,
    error: state.error,
  };
}

export default connect(mapStateToProps, {
  signupUser, createClass, addStudentToClass, setError,
})(NewUserPage);
