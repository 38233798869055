import React, { Component } from 'react';

class ProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    return (
      <div className="progress-bar">
        <div className="progress-filler" style={{ width: `${this.props.percentage}%` }} />
      </div>
    );
  }
}

export default ProgressBar;
