/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { makeActivityPage } from '../../../../actions';
import BPMSelect from './BPMSelect';
import TimeSignatureSelect from './TimeSignatureSelect';
import RhythmNoteTypeInput from './RhythmNoteTypeInput';
import addBox from '../../../../../images/lessonCreation/addBox.svg';

// Input box not able to be changed after coming back from preview and editing.
// const NoteTypeInput = (props) => {
//   console.log('props are: ', props);
//   const id = `note-pick-id-${props.num}`;
//   let duration = '';
//   if (props.dur !== null) {
//     duration = props.dur;
//   }
//   // let inpBox;
//   // if (props.value === null || props.value === undefined) {
//   //   console.log('value did not exist');
//   //   inpBox = <input type="text" id={id} />;
//   // } else {
//   //   console.log('value existed for box');
//   //   inpBox = <input type="text" id={id} defaultValue={props.value} />;
//   // }
//   return (
//     <div className="note-pick-container">
//       <span>Enter note index {props.num + 1} </span>
//       {/* {inpBox} */}
//       <input type="text" className="note-pick-input" id={id} defaultValue={duration} onChange={workplz} />
//     </div>
//   );
// };

class RhythmQuestionCreate extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    console.log('NEW CONSTRUCTION');
    if (this.props.pageInfo !== undefined && this.props.pageInfo !== null && this.props.pageInfo.info !== null && this.props.pageInfo.info !== undefined) {
      let bpm;
      switch (this.props.pageInfo.info.r.bpm) {
        case 90:
          bpm = '90 (Slow)';
          break;
        case 120:
          bpm = '120 (Medium)';
          break;
        case 150:
          bpm = '150 (Fast)';
          break;
        default:
          bpm = '120 (Medium)';
      }
      this.state = {
        noteOptions: this.startingNoteOptions(),
        time_signature: this.props.pageInfo.info.r.time_signature,
        bpm,
      };
    } else {
      this.state = {
        noteOptions: [],
        time_signature: '4/4',
        bpm: '120 (Medium)',
      };
    }
  }

  updateTS = (ts) => {
    console.log('updated the time signature');
    this.setState({ time_signature: ts });
  }

  updateBPM = (bpm) => {
    this.setState({ bpm });
  }

  startingNoteOptions = () => {
    const startingNotes = [];
    for (let i = 0; i < this.props.pageInfo.info.r.notes.length; i += 1) {
      // startingNotes.push(<NoteTypeInput num={i} key={i} value={this.props.pageInfo.info.r.notes[i]} />);
      startingNotes.push({ num: i, value: this.props.pageInfo.info.r.notes[i] });
    }
    console.log(startingNotes);
    return startingNotes;
  }

  addNoteOptions = () => {
    // const a = [];
    // a.push({ num: this.state.noteOptions.length, value: '' });
    const a = { num: this.state.noteOptions.length, value: '' };
    this.setState(((prevState) => ({
      noteOptions: [
        ...prevState.noteOptions,
        a,
      ],
    })));
  }

  getChosenNotes = () => {
    const chosen = [];
    for (let i = 0; i < this.state.noteOptions.length; i += 1) {
      const toGet = `note-pick-id-${i}`;
      chosen.push(document.getElementById(toGet).value);
    }
    return chosen;
  }

  handleChange = () => {
    this.props.saveLessonFunc(false, true, null, false);
  };

  render() {
    console.log(this.props);
    const toRenderNoteInputs = [];
    for (let i = 0; i < this.state.noteOptions.length; i += 1) {
      toRenderNoteInputs.push(<RhythmNoteTypeInput num={this.state.noteOptions[i].num} key={this.state.noteOptions[i].num} dur={this.state.noteOptions[i].value} handleChange={this.handleChange} />);
    }
    // console.log('rerending rhythm question create');
    // const toRenderNoteInputs = this.state.noteOptions.map((noteInputBox) => {
    //   return (<RhythmNoteTypeInput num={noteInputBox.num} key={noteInputBox.num} dur={noteInputBox.value} />);
    // });
    console.log('rendering rhythm with id', this.props.pageInfo._id);
    return (
      <div className="rhythm-creation-container nlc-question-main">
        <div className="nlc-question-selects">
          <div className="select-container">
            <div className="select-title">Time signature: </div>
            <TimeSignatureSelect ts={this.state.time_signature} func={this.updateTS} handleChange={this.handleChange} />
          </div>
          <div className="select-container">
            <div className="select-title">BPM: </div>
            <BPMSelect bpm={this.state.bpm} func={this.updateBPM} handleChange={this.handleChange} />
          </div>
        </div>
        <form className="nlc-entry-inputs-flex">
          {toRenderNoteInputs}
        </form>
        <div type="button" className="nlc-add-remove-button" role="button" onClick={this.addNoteOptions}>
          <img src={addBox} alt="blue box with addition sign" />
          <div className="add-button-text">Add note</div>
        </div>
        <input type="hidden" className="id-hidden-input" value={this.props.pageInfo._id} />
      </div>

    );
  }
}

function mapStateToProps(reduxState) {
  return {
    lessonid: reduxState.lessonMaking.lessonid,
  };
}

export default connect(mapStateToProps, { makeActivityPage })(RhythmQuestionCreate);
