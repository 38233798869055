/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generateSlug } from 'random-word-slugs';
import { v4 as uuidv4 } from 'uuid';
// import Lottie from 'react-lottie';
import { ActionTypes, assignCoins, assignXP } from '../../actions/index';
import * as Socket from './socket';
import StudentAnswerQuestion from './InClassGame/Student/StudentAnswerQuestion';
import StudentJoinGame from './InClassGame/Student/StudentJoinGame';
import StudentResults from './InClassGame/Student/StudentResults';
import StudentWaitingScreen from './InClassGame/Student/StudentWaitingScreen';
import TeacherQuestion from './InClassGame/Teacher/TeacherQuestion';
import TeacherResults from './InClassGame/Teacher/TeacherResults';
import TeacherStartGame from './InClassGame/Teacher/TeacherStartGame';
import TeacherFinalResults from './InClassGame/Teacher/TeacherFinalResults';
import StudentFinalResults from './InClassGame/Student/StudentFinalResults';
import TeacherDetailedResults from './InClassGame/Teacher/TeacherDetailedResults';
import { difficulties } from '../../lib/constants';

/* const defaultOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const lightningStyle = {
  width: '50vw',
  height: 'auto',
  position: 'absolute',
}; */

const InClassGameMain = ({ unknown }) => {
  const dispatch = useDispatch();
  const answerNumber = {
    answer1: 1,
    answer2: 2,
    answer3: 3,
    answer4: 4,
  };

  // important info
  let id, type, first_name, xp, coins;
  id = useSelector((state) => state.user.id);
  type = useSelector((state) => state.user.type);
  first_name = useSelector((state) => state.user.first_name);

  if (!unknown) { // only show xp and coins for authed
    xp = useSelector((state) => state.user.stats.xp);
    coins = useSelector((state) => state.user.stats.coins);
  }

  // means unauthed (unknown)
  if (unknown) {
    type = 'student';
  }

  const [socket, setSocket] = useState('');
  const socketSetter = useRef(null);
  const [connected, setConnectedStatus] = useState(false);

  // pins
  const [newGamePin, setNewGamePin] = useState('');
  const [currentGamePin, setCurrentGamePin] = useState('');
  const [typingGamePin, setTypingGamePin] = useState('');

  // question
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
  const [allQuestions, setAllQuestions] = useState({});
  const [theCorrectAnswer, setTheCorrectAnswer] = useState(0);

  // players
  const [playerData, setPlayerData] = useState([]);
  const [numOfPlayers, setNumOfPlayers] = useState(0);
  const [playersRanked, setPlayersRanked] = useState([]);
  const [playersAnswered, setPlayersAnswered] = useState(0);

  // state of game
  const [startButtonClicked, setStartButtonClicked] = useState(false);
  const [gameDifficulty, setGameDifficulty] = useState('');
  const [playCountdown, setPlayCountdown] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [joined, setJoined] = useState(false);
  const [answeringQuestion, setAnsweringQuestion] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [resultsScreen, setResultsScreen] = useState(false);
  const [detailsScreen, setDetailsScreen] = useState(false);

  // pop up messages
  const [msg, setMsg] = useState('');
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const [error, setError] = useState('');

  // results and scores
  const [answerRatios, setAnswerRatios] = useState([]);
  const [score, setScore] = useState('');
  const [streak, setStreak] = useState(0);
  const [place, setPlace] = useState(0);
  const [behindName, setBehindName] = useState('');
  const [behindPoints, setBehindPoints] = useState('');
  const [finalScore, setFinalScore] = useState(0);
  const [finalPlace, setFinalPlace] = useState(0);
  const [teacherFinalNames, setTeacherFinalNames] = useState(['', '', '']);
  const [gaveXPAndCoins, setGaveXPAndCoins] = useState(false);

  const resetState = () => {
    setNewGamePin('');
    setCurrentGamePin('');
    setCurrentQuestion({});
    setCurrentQuestionNumber(1);
    setAllQuestions({});
    setTheCorrectAnswer(0);
    setPlayerData([]);
    setNumOfPlayers(0);
    setPlayersRanked([]);
    setPlayersAnswered(0);
    setStartButtonClicked(false);
    setGameDifficulty('');
    setPlayCountdown(false);
    setGameStarted(false);
    setJoined(false);
    setAnsweringQuestion(false);
    setWaiting(false);
    setResultsScreen(false);
    setDetailsScreen(false);
    setMsg('');
    setIsCorrectAnswer(false);
    setError('');
    setAnswerRatios([]);
    setScore('');
    setStreak(0);
    setPlace(0);
    setBehindName('');
    setBehindPoints('');
    setFinalScore(0);
    setFinalPlace(0);
    setGaveXPAndCoins(false);
    setTeacherFinalNames(['', '', '']);
  };

  const makeNewSocket = () => {
    resetState();
    if (unknown) {
      id = uuidv4();
      type = 'student';
      first_name = generateSlug(2, {
        categories: {
          adjective: ['time', 'sounds',
            'personality'],
          noun: ['education', 'food', 'sports', 'technology', 'transportation', 'science'],
        },
        format: 'camel',
      });
      dispatch({ type: ActionTypes.AUTH_USER, payload: { id, type: 'student', first_name } });
    }
    const sock = Socket.connectSocket(id, type);
    setSocket(sock);
    socketSetter.current = sock;
    if (type === 'teacher' || type === '' || type === undefined) {
      Socket.setupNewGameSocket(socketSetter.current);
    }
    if (type === 'student' && !joined && typingGamePin !== '') {
      Socket.joinGameSocket(socketSetter.current, typingGamePin, { first_name, id });
    }
  };

  // give XP and coins at end of game/when final score is updated
  useEffect(() => {
    if (!gaveXPAndCoins) {
      dispatch(assignCoins(finalScore / 10, id));
      dispatch(assignXP(finalScore, id));
      setGaveXPAndCoins(true);
    }
  }, [finalScore]);

  if (socket !== '') {
    console.log('socket exists');

    socket.on('receiveNewPin', (pin) => {
      setConnectedStatus(true);
      setNewGamePin(pin);
      setNumOfPlayers(0);
      setPlayerData([]);
    });

    socket.on('error', (e) => {
      setError(e);
    });

    const attemptStudentReconnect = () => {
      if (currentGamePin) {
        makeNewSocket();
        Socket.attemptRejoinGameStudent(socketSetter.current, currentGamePin, id);
      } else {
        console.log('unable to reconnect');
      }
    };

    const attemptTeacherReconnect = () => {

    };

    socket.on('disconnect', (e) => {
      setConnectedStatus(false);
      if (type === 'student') {
        attemptStudentReconnect();
      } else {
        attemptTeacherReconnect();
      }
    });

    socket.on('gameJoined', (res) => {
      console.log('game joined response', res);
      if (res === 'success') {
        setCurrentGamePin(typingGamePin);
        setJoined(true);
        setConnectedStatus(true);
      }
    });

    socket.on('gameDifficultySet', (res) => {
      console.log('game difficulty set response:', res);
      if (res === difficulties.EASY) {
        setGameDifficulty(difficulties.EASY);
      } else if (res === difficulties.MEDIUM) {
        setGameDifficulty(difficulties.MEDIUM);
      } else if (res === difficulties.HARD) {
        setGameDifficulty(difficulties.HARD);
      } else {
        setGameDifficulty(difficulties.EASY);
      }
    });

    socket.on('gameJoinedFailed', (res) => {
      setError(res);
    });

    socket.on('gameStartedTeacherPrepare', (questionsObj) => {
      console.log(questionsObj, 'all questions');
      setAllQuestions(questionsObj);
      setCurrentQuestion(questionsObj[0]);
      setPlayCountdown(true);
      setTimeout(() => {
        setGameStarted(true);
        setAnsweringQuestion(true);
      }, 3000);
    });

    socket.on('gameStartedStudentPrepare', (theMsg, theCurrentQuestion) => {
      console.log(theCurrentQuestion, 'game started stdent');
      setMsg(theMsg);
      setCurrentQuestion(theCurrentQuestion);
      setTimeout(() => {
        setGameStarted(true);
        setAnsweringQuestion(true);
      }, 3000);
    });

    socket.on('receivePlayerInfo', (playerInfo) => {
      console.log(playerInfo);
      const playerNum = playerInfo.numOfPlayers;
      const thePlayerData = playerInfo.players;
      setNumOfPlayers(playerNum);
      setPlayerData(thePlayerData);
    });

    socket.on('gameCreated', (res) => {
      console.log('game created response', res);
      if (res === 'success') {
        socket.emit('getNewPin');
      }
    });

    socket.on('waitingAnswer', (res) => {
      console.log('waiting answer response', res);
      if (res === 'waiting') {
        setWaiting(true);
      }
    });

    socket.on('answerCalculated', (playerId) => {
      console.log('answer calculated response: ', playerId);
      setPlayersAnswered(playersAnswered + 1);
    });

    socket.on('answerResult', (answerResultObj, theCurrentQuestion) => {
      console.log('answer result response', answerResultObj, theCurrentQuestion);
      setIsCorrectAnswer(answerResultObj.isCorrect);
      setCurrentQuestion(theCurrentQuestion);
      setAnsweringQuestion(false);
      setWaiting(false);
      setScore(answerResultObj.score);
      setStreak(answerResultObj.streak);
      setPlace(answerResultObj.place);
      setBehindName(answerResultObj.behindName);
      setBehindPoints(answerResultObj.behindPoints);
    });

    socket.on('answerResultTeacher', (correctAnswer, answerResultsArray) => {
      console.log('answer result teacher called: ', answerResultsArray);
      setAnswerRatios(answerResultsArray);
      setAnsweringQuestion(false);
      setTheCorrectAnswer(correctAnswer);
    });

    socket.on('rankResults', (playersArray) => {
      console.log('rank results called', playersArray);
      setPlayersRanked(playersArray);
    });

    socket.on('nextQuestion', (numOfAnswers, num) => {
      console.log('next question socket received: ', numOfAnswers);
      setAnsweringQuestion(true);
      setPlayersAnswered(0);
      setCurrentQuestionNumber(num);
      if (type !== 'student') {
        setCurrentQuestion(allQuestions[num - 1]);
      }
    });

    socket.on('endGameTeacher', (names) => {
      console.log('end game socket received: ', names);
      setAnsweringQuestion(false);
      setResultsScreen(true);
      setTeacherFinalNames(names);
    });

    socket.on('endGameStudent', (res) => {
      console.log('end game student socket received: ', res);
      setFinalScore(res.finalScore);
      setFinalPlace(res.finalPlace);
      setResultsScreen(true);
    });
  }

  const joinGame = () => {
    makeNewSocket();
  };

  const quitGame = () => {
    Socket.disconnectSocket(socketSetter.current);
  };

  const setupNewGame = () => {
    console.log('set up new game called');
    makeNewSocket();
  };

  const changeDifficulty = (difficulty) => {
    if (socketSetter.current && newGamePin) {
      console.log('changing difficulty', difficulty);
      Socket.changeDifficulty(socketSetter.current, newGamePin, difficulty);
    }
  };

  const startTheGame = () => {
    if (newGamePin !== '') {
      Socket.startGameSocket(socketSetter.current, newGamePin);
      setStartButtonClicked(true);
    }
  };

  const guessAnswer = (answer) => {
    console.log(currentQuestion);
    Socket.guessAnswerSocket(socketSetter.current, typingGamePin, currentQuestionNumber, answer, id);
  };

  const submitAnswerRhythm = (answer) => {
    console.log(currentQuestion);
    console.log('current question num: ', currentQuestionNumber);
    Socket.guessAnswerSocketRhythm(socketSetter.current, typingGamePin, currentQuestionNumber, answer, id);
  };

  const goToNextQuestionCallback = () => {
    Socket.nextQuestionSocket(socketSetter.current, newGamePin, currentQuestionNumber);
  };

  const endGameCallback = () => {
    setGameStarted(false);
    setResultsScreen(true);
    Socket.endGameSocket(socketSetter.current, newGamePin, currentQuestionNumber);
  };

  const renderDetails = () => {
    console.log('render details screen called');
    setDetailsScreen(true);
  };

  const stopRenderingDetails = () => {
    setDetailsScreen(false);
  };

  const resetGame = () => {
    console.log('reset game');
    setAnsweringQuestion(false);
    setJoined(false);
    setWaiting(false);
    setResultsScreen(false);
    setGameStarted(false);
    setTypingGamePin('');
    setMsg('');
    Socket.disconnectSocket(socketSetter.current);
    setCurrentGamePin('');
  };

  const endTimerCallback = (theType) => {
    if (!answeringQuestion && gameStarted && !resultsScreen) {
      console.log('already got enough people');
      return;
    }
    Socket.endQuestionFromTime(socketSetter.current, newGamePin, currentQuestionNumber, currentQuestion.info.mc.correct_answer, theType);
  };

  const renderScreen = () => {
    if (type === 'student') {
      if (!gameStarted && joined) {
        return (
          <StudentWaitingScreen
            join
            name={first_name}
          />
        );
      }
      if (!gameStarted && !resultsScreen) {
        return (
          <StudentJoinGame
            currentGamePin={currentGamePin}
            error={error}
            msg={msg}
            setTypingGamePin={setTypingGamePin}
            joinGame={joinGame}
            quitGame={quitGame}
            xp={xp}
            coins={coins}
          />
        );
      } else if (answeringQuestion && !waiting) {
        return (
          <StudentAnswerQuestion
            guessAnswer={guessAnswer}
            answerNumber={answerNumber}
            currentQuestion={currentQuestion}
            submitAnswerRhythm={submitAnswerRhythm}
            questionType={currentQuestion?.info?.activity_type}
            xp={xp}
            coins={coins}
          />
        );
      } else if (answeringQuestion && waiting) {
        return (
          <StudentWaitingScreen
            name={first_name}
          />
        );
      } else if (!resultsScreen) {
        return (
          <StudentResults
            isCorrect={isCorrectAnswer}
            score={score}
            type="IC"
            added={100}
            streak={streak}
            place={place}
            name={first_name}
            behindName={behindName}
            behindPoints={behindPoints}
          />
        );
      } else {
        return (
          <StudentFinalResults unknown={unknown}
            finalScore={finalScore}
            finalPlace={finalPlace}
            resetGame={resetGame}
          />
        );
      }
    }

    if (!gameStarted && !resultsScreen) {
      return (
        <TeacherStartGame
          msg={msg}
          newGamePin={newGamePin}
          numOfPlayers={numOfPlayers}
          changeDifficulty={changeDifficulty}
          setupNewGame={setupNewGame}
          startTheGame={startTheGame}
          playCountdown={playCountdown}
          playerData={playerData}
          startButtonClicked={startButtonClicked}
          gameDifficulty={gameDifficulty}
        />
      );
    } else if (answeringQuestion) {
      return (
        <TeacherQuestion
          currentQuestion={currentQuestion}
          allQuestions={allQuestions}
          playersAnswered={playersAnswered}
          endQuestionFromTime={endTimerCallback}
          questionType={currentQuestion?.info?.activity_type}
        />
      );
    } else if (!resultsScreen) {
      return (
        <TeacherResults
          answerRatios={answerRatios}
          numberOfQuestions={allQuestions.length}
          currentQuestionNumber={currentQuestionNumber}
          goToNextQuestionCallback={goToNextQuestionCallback}
          endGameCallback={endGameCallback}
          correctAnswer={theCorrectAnswer}
          title={currentQuestion.title}
          firstPlace={playersRanked.length !== 0 ? playersRanked[0].name : ''}
          firstPlacePoints={playersRanked.length !== 0 ? playersRanked[0].score : ''}
        />
      );
    } else if (detailsScreen) {
      return (
        <TeacherDetailedResults backToResults={stopRenderingDetails} />
      );
    } else {
      return (
        <TeacherFinalResults
          names={teacherFinalNames}
          isAThird={teacherFinalNames.length >= 3}
          renderDetails={renderDetails}
        />
      );
    }
  };

  return (
    <div className="ic-main-screen">
      {renderScreen()}
    </div>
  );
};

export default InClassGameMain;
