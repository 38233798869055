/* eslint-disable max-len */
/* eslint-disable no-useless-constructor */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
// import mainlanding from '../../../../images/landing/Background.svg';
import orangeNote from '../../../../images/landing/orangeNote.svg';
import NewNavBar from '../../cs98_components/NewNavBar';

// eslint-disable-next-line react/prefer-stateless-function
class LandingPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="landing-page-holder">
        <NewNavBar
          renderLogo
          items={[
            {
              text: 'Sign up',
              link: '/newuser',
              icon: 'fas fa-user-plus',
            },
            {
              text: 'Log in',
              link: '/login',
              icon: 'fa fa-sign-in',
            },
          ]}
          dropdownItems={[
            {
              text: 'Teacher Info',
              link: '/home/teachers',
            },
            {
              text: 'Student Info',
              link: '/home/students',
            },
            {
              text: 'About the Team',
              link: '/about',
            },
          ]}
          dropdownIcon="fas fa-book-open"
        />
        {/* Page 1 of the landing page */}
        <div className="section-1">
          <div className="landing-main">
            {/* <img className="landing-main-image" src={mainlanding} alt="landing page logo" /> */}
            <NavLink to="/newuser">
              <button type="button" className="join-warbler">JOIN WARBLER NOW</button>
            </NavLink>
          </div>
        </div>
        {/* Page  2 of the landing page */}
        <div className="landing-white-section">
          <div className="landing-music-ed-section">
            <img className="landing-music-ed-image" src={orangeNote} alt="orange music note" />
            <div className="landing-music-ed-text">
              <h2 className="landing-music-ed-title">Music education started on the right note.</h2>
              <p className="landing-music-ed-description">Warbler is a fun and engaging music education platform geared towards teaching fundamental music skills in a classroom setting.</p>
            </div>
          </div>

          <div className="line" />

          <div className="landing-fun-section">
            <div className="landing-fun-title">We&apos;re here to make learning music fun.</div>
            <div className="landing-fun-description">Teachers can harness our <span className="blue-underline">5 core activity types</span> and engaging interface to create lessons tailored to their students’ needs and skill level. Warbler utilizes technology to give students individualized feedback in a way not possible in a conventional classroom setting—and even less so in a remote setting.</div>
            <div className="landing-fun-circles-row">
              <div className="landing-fun-circle c1">
                <h3 className="landing-fun-circle-text">Interval Training</h3>
              </div>
              <div className="landing-fun-circle c2">
                <h3 className="landing-fun-circle-text">Rhythm</h3>
              </div>
              <div className="landing-fun-circle c3">
                <h3 className="landing-fun-circle-text">Simple Singing</h3>
              </div>
              <div className="landing-fun-circle c4">
                <h3 className="landing-fun-circle-text">Listening</h3>
              </div>
              <div className="landing-fun-circle c5">
                <h3 className="landing-fun-circle-text">Sight-reading</h3>
              </div>
            </div>
          </div>

          {/* Page 3 of the landing page */}
          <div className="line" />
          {/* Page 4 of Landing Page */}

          <div className="landing-cta">
            <div className="landing-cta-text">
              <div className="landing-cta-title">Want to learn more?</div>
              <div className="landing-cta-description">First, tell us who you are. (No peeking!)</div>
            </div>
            <div className="landing-cta-buttons">
              <NavLink to="/home/students">
                <button type="button" className="button green student-button">Students click here!</button>
              </NavLink>
              <NavLink to="/home/teachers">
                <button type="button" className="button blue teacher-button">Teachers click here!</button>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="landing-footer-section">
          <div className="landing-footer-text">Use Warbler in your classroom for FREE by signing up <NavLink to="/newuser"><span className="white-underlined">here.</span></NavLink></div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
