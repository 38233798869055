import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class NavBarDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownItems: this.props.dropdownItems,
      dropdownIcon: this.props.dropdownIcon,
      isOpen: false,
    };
  }

  toggleDropdown() {
    console.log('toggling dropdown');
    document.getElementById('nav-dropdown').classList.toggle('nav-dropdown-open');
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  renderDropdownItems() {
    if (this.state.isOpen) {
      const toRender = this.state.dropdownItems.map((item) => {
        return (
          <div className="nav-dropdown-item" key={item.text}>
            <NavLink
              to={item.link}
              key={item.text}
            >
              { item.text }
            </NavLink>
          </div>
        );
      });
      return (
        <div id="nav-dropdown" className="nav-dropdown-items-container nav-dropdown-open">
          {toRender}
        </div>
      );
    } else {
      return <div id="nav-dropdown" className="nav-dropdown-items-container" />;
    }
  }

  renderDropdownIcon() {
    if (this.props.dropdownIcon) {
      return (
        <i className={this.state.dropdownIcon} />
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div>
        <div className="nav-bar-item" onClick={() => this.toggleDropdown()} role="button" tabIndex={0}>
          {this.renderDropdownIcon()}
          <div className="nav-bar-item-text">Learn More</div>
        </div>
        {this.renderDropdownItems()}
      </div>
    );
  }
}

export default NavBarDropdown;
