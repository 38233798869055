import blueBirdProf from '../../../../images/profile/birds/blueBirdProf.svg';
import redBirdProf from '../../../../images/profile/birds/redBirdProf.svg';
import greenBirdProf from '../../../../images/profile/birds/greenBirdProf.svg';
import yellowBirdProf from '../../../../images/profile/birds/yellowBirdProf.svg';
import purpleBirdProf from '../../../../images/profile/birds/purpleBirdProf.svg';

import frogHat from '../../../../images/profile/items/frogHat.svg';
import beanie from '../../../../images/profile/items/beanie.svg';
import bow from '../../../../images/profile/items/bow.svg';

import grassPlatform from '../../../../images/profile/items/grassPlatform.svg';

import micAccessory from '../../../../images/profile/items/micAccessory.svg';

import levelOneIcon from '../../../../images/profile/levels/pot.svg';

export const chooseBirdImage = (color) => {
  switch (color) {
    case 'blue':
      return blueBirdProf;
    case 'red':
      return redBirdProf;
    case 'green':
      return greenBirdProf;
    case 'yellow':
      return yellowBirdProf;
    case 'purple':
      return purpleBirdProf;
    default:
      return blueBirdProf;
  }
};

export const chooseHatImage = (hat) => {
  switch (hat) {
    case 'frog':
      return frogHat;
    case 'bow':
      return bow;
    case 'beanie':
      return beanie;
    default:
      return null;
  }
};

export const choosePlatformImage = (platform) => {
  switch (platform) {
    case 'grass':
      return grassPlatform;
    default:
      return null;
  }
};

export const chooseAccessoryImage = (accessory) => {
  switch (accessory) {
    case 'mic':
      return micAccessory;
    default:
      return null;
  }
};

export const findItemImage = (itemType, item) => {
  switch (itemType) {
    case 'Top':
      return chooseHatImage(item);
    case 'Platform':
      return choosePlatformImage(item);
    case 'Accessory':
      return chooseAccessoryImage(item);
    default:
      return chooseHatImage(item);
  }
};

export const chooseLevelName = (levelNum) => {
  let name = '';
  let num = '';
  switch (levelNum) {
    case levelNum <= 5 && levelNum >= 1:
      name = 'Grave';
      break;
    case levelNum <= 10:
      name = 'Adagio';
      break;
    case levelNum <= 15:
      name = 'Andante';
      break;
    case levelNum <= 20:
      name = 'Moderato';
      break;
    case levelNum <= 25:
      name = 'Allegro';
      break;
    case levelNum <= 30:
      name = 'Vivace';
      break;
    case levelNum <= 35:
      name = 'Vivacissimo';
      break;
    case levelNum <= 40:
      name = 'Allegrissimo';
      break;
    case levelNum <= 45:
      name = 'Presto';
      break;
    case levelNum <= 50:
      name = 'Prestissimo';
      break;
    default:
      name = 'Grave';
      break;
  }

  switch (levelNum % 5) {
    case 0:
      num = 'V';
      break;
    case 1:
      num = 'I';
      break;
    case 2:
      num = 'II';
      break;
    case 3:
      num = 'III';
      break;
    case 4:
      num = 'IV';
      break;
    default:
      num = 'I';
      break;
  }
  const final = `${name} ${num}`;
  console.log({ final });
  return final;
};

export const chooseLevelIcon = (level) => {
  return levelOneIcon;
};
