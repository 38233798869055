/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
// import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserInfo } from '../../actions/index';

class HeadtoHeadLobby extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.props.getUserInfo();
    this.ws = this.props.ws;
  }

  componentDidMount() {
    // wait until the socket is ready to send joinLobby message
    this.waitForSocketConnection(this.ws, () => {
      this.props.joinLobby();
    });
  }

  waitForSocketConnection = (socket, callback) => {
    setTimeout(
      () => {
        if (socket.readyState === 1) {
          console.log('Connection is made');
          if (callback != null) {
            callback();
          }
        } else {
          console.log('wait for connection...');
          this.waitForSocketConnection(socket, callback);
        }
      }, 5,
    ); // wait 5 milisecond for the connection...
  }

  render() {
    return (
      <div className="head-to-head-lobby">
        <div className="head-to-head-flex">
          <div className="instructions-box">
            <div className="name-title">Hello, {this.props.user.first_name}!</div>
            <div className="instructions-text">Click play and we will pair you up with a random, available player!</div>
          </div>
          <div className="online-box">
            <div className="online-box-number" key={this.props.playersInLobbyCount}>{this.props.playersInLobbyCount}</div>
            <div className="online-box-title">online</div>
          </div>
          <div className="inGame-box">
            <div className="inGame-box-number" key={this.props.playersInGameCount}>{this.props.playersInGameCount}</div>
            <div className="inGame-box-title">in game</div>
          </div>
        </div>
        <div className="bird-players-flex">
          {this.props.renderBirdsInLobby()}
        </div>
        <div className="head-to-head-play-holder ">
          <div className="button-green" onClick={this.props.clickedPlay} role="button" tabIndex={0}>
            Play
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    user: reduxState.user,
  };
}

export default connect(mapStateToProps, { getUserInfo })(HeadtoHeadLobby);
